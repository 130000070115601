import events from '@skivy71/events'
import { sys } from '@skivy71/utils'

class Alert {

    static _payload(text, hide, color) {
        return Object.assign({ class: Alert._class(color) }, { text, hide })
    }

    static _class(color = 'success') {
        return `alert-${color}`
    }

    static _message(text, hide = 0, color) {
        events.$emit('alert', Alert._payload(text, hide, color))
        return hide ? sys.wait(hide) : undefined
    }

    static error(text, hide) {
        return Alert._message(text, hide, 'danger')
    }

    static message(text, hide) {
        return Alert._message(text, hide)
    }

    static warning(text, hide) {
        return Alert._message(text, hide, 'warning')
    }

}

export default Alert