<template>
    <div class="modal fade" :id="dialog.id" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-text="dialog.title"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p v-text="dialog.prompt"></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-outline-danger" data-dismiss="modal">No</button>
                    <button type="button" class="btn btn-sm btn-outline-success" v-on:click="dialog.confirm()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    import { events } from '../service'

    var { $ } = window

    export default {
        data() {
            return {
                dialog: ''
            }
        },
        created() {
            var { show } = this
            this.events = events.$watch({
                confirm: show.bind(this)
            })
            this.init()
        },
        mounted() {
            var { dialog } = this
            $(dialog.el).on('hidden.bs.modal', dialog.hidden.bind(dialog))
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
            el() {
                var { id } = this
                return `#${id}`
            },
            id() {
                return this._uid
            },
            title() {
                var { operation: op } = this
                return op ? `Confirm ${op}` : `Confirm`
            }
        },
        methods: {
            init() {
                var { _uid: id } = this
                var dialog = {
                    id,
                    _confirm(confirmed) {
                        var { _show } = this
                        events.$emit(`confirmed`, { ..._show, confirmed })
                        this._done = true
                    },
                    _done: true, 
                    _show: {},
                    confirm() {
                        this._confirm(true)
                        this.hide()
                    },
                    get el() {
                        return `#${this.id}`
                    },
                    hide() {
                        $(this.el).modal(`hide`)
                    },
                    hidden() {
                        if (!this._done)
                            this._confirm(false)
                    },
                    get prompt() {
                        return this._show.prompt || `Are you sure?`
                    },
                    show(show) {
                        if (!this._done)
                            return
                        this._done = false
                        if (typeof show == 'object')
                            this._show = { ...show }
                        $(this.el).modal(`show`)
                    },
                    get title() {
                        var { operation: op } = this._show
                        return op ? `Confirm ${op}` : `Confirm`
                    }
                }
                this.dialog = dialog
            },
            show(show) {
                this.dialog.show(show)
            }
        }
    }
</script>