<template>
    <div class="modal fade" :id="modal.id" tabindex="-1" role="dialog" :data-backdrop="modal.backdrop" :data-keyboard="modal.keyboard">
        <template v-if="modal.enable">
            <div class="modal-dialog" :class="modal.class" role="document" :style="modal.style">
                <component :is="modal.component" :data="modal.data"></component>
            </div>
        </template>
    </div>
</template>

<style scoped>
</style>

<script>
    import { Sys } from '../factory'
    import modalComponents from '../modal' 
    import { events } from '../service'

    var { $ } = window

    export default {
        get components() {
            return modalComponents
        },
        data() {
            return {
                modal: ''
            }
        },
        created() {
            this.init()
            var { modal } = this
            this.events = events.$watch({
                modal: modal.event.bind(modal)
            })
        },
        mounted() {
            var { modal } = this
            $(modal.el).on('hidden.bs.modal', modal.hidden.bind(modal))
            $(modal.el).on('shown.bs.modal', modal.shown.bind(modal))
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
        },
        methods: {
            init() {
                var { _uid: id } = this
                this.modal = {
                    _action(a) {
                        $(this.el)
                            .modal(a)
                    },
                    active: false,
                    get backdrop() {
                        return this.close ? false : `static`
                    },
                    get keyboard() {
                        return `${this.close}`
                    },
                    class: ``,
                    close: ``,
                    component: ``,
                    data: ``,
                    id,
                    get el() {
                        return `#${this.id}`
                    },
                    enable: false,
                    event(e) {
                        var { action = `show`, component, data, style = ``, close = true } = e
                        switch(action) {
                            case `show`:
                                if (Array.isArray(data) || typeof data != `object`)
                                    throw new Error(`Invalid data object, should contain at least one root property!`)
                                this.component = component
                                this.data = { ...data }
                                this.class = e.class || ``
                                this.style = style
                                this.close = close
                                this.enable = true
                                break
                            case `hide`:
                                this._action(`hide`)
                                break
                            //default:
                                //throw new Error(`Invalid modal action, ${action}!`)
                        }
                    },
                    hidden() {
                        this.enable = false
                    },
                    show() {
                        this._action(`show`)
                    },
                    shown() {
                    },
                    style: `` 
                }
            },
        },
        watch: {
            'modal.enable': {
                handler() {
                    var { modal } = this
                    if (!modal.enable)
                        return
                    this.$nextTick(() => {
                        modal.show()
                    })
                }
            }
        }
    }
</script>