<template>
    <div class="h-100">
        <component :is="view.component"></component>
    </div>    
</template>

<style scoped>

</style>

<script>
    import { lib } from '../factory'
    import { session } from '../service'
    import firmDashboard from './dashboard'
    import firmAccounts from './accounts'
    import firmAudit from './audit'
    import firmClients from './clients'
    import firmBilling from './billing'
    import firmSettings from './settings'
    import firmContacts from './contacts'

    export default {
        get components() {
            return {
                firmDashboard,
                firmAudit,
                firmClients,
                firmAccounts,
                firmBilling,
                firmSettings,
                firmContacts
            }
        },
        props: ['sidebar'],
        data() {
            return {
            }
        },
        computed: {
            view() {
                var { string } = lib
                var { sidebar, state } = this
                var { menu, views } = sidebar
                var name = views[menu.index]
                var component = `firm-${name}`
                return { component, name }
            }
        }
    }
</script>