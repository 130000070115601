import { Http } from './index'

export default (() => {

    var _data = {
        apikey: `R8UR9JAZR9UEYAQL`,
        baseURL: `https://www.alphavantage.co`
    }  
    
    class Stock {

        static _ticker(obj) {
            return Object.keys(obj)
                .reduce((ticker, key) => {
                    var prop = key.split(' ').pop()
                    ticker[prop] = obj[key]
                    return ticker
                }, {})
        }

        static _url(path, query = '') {
            var { apikey, baseURL } = _data
            query += (query ? `&apikey=${apikey}` : `apikey=${apikey}`)
            return `${baseURL}/${path}?${query}`
        }

        static search(keywords) {
            return new Promise(async (resolve, reject) => {
                var query = `function=SYMBOL_SEARCH&keywords=${keywords}`
                var url = Stock._url('query', query)
                try {
                    var { bestMatches = [] } = await Http.get({ url })
                    var list  = bestMatches
                        .map(r => Stock._ticker(r))
                    resolve(list)
                } catch(e) {
                    reject(e)
                }
            })
        }
    }

    return Stock

})()