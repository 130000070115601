<template>
    <div class="h-100">
        <template v-if="table.loading">
            <div class="h-100">
                <loading-component></loading-component>
            </div>
        </template>
        <template v-else>
            <scroll-area>
                <div class="m-3">
                    <div class="row">
                        <div class="col-6">
                            <div class="form form-inline">
                                <label class="mr-2">Date</label>
                                <template v-if="!table.imported">
                                    <date-picker :obj="table.date" prop="value" :options="table.date.options"></date-picker>
                                </template>
                                <template v-else>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm" type="text" v-model="table.date.value" readonly placholder="Date" :style="table.style('width', table.date.options.width)">
                                        <div class="input-group-append">
                                            <i class="input-group-text far fa-calendar-alt"></i>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col buttons">
                            <div class="form form-inline float-right">
                                <template v-if="!table.imported">
                                    <div class="file-upload">
                                        <input type="file" ref="upload" v-on:change="upload" accept=".csv">
                                    </div>
                                </template>
                                <template v-if="table.imported">
                                    <button type="button" class="btn btn-danger btn-sm mr-2" v-on:click="table.discard()">
                                        <i class="fas fa-times-circle"></i>
                                        <span>Discard</span>
                                    </button>
                                    <button type="button" class="btn btn-success btn-sm mr-2" v-on:click="table.save()" :disabled="table.pending">
                                        <template v-if="table.pending">
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </template>
                                        <template v-else>
                                            <i class="fas fa-check-circle"></i>
                                        </template>
                                        <span>Save</span>
                                    </button>
                                </template>
                                <button class="btn btn-sm btn-info" v-on:click="table.import()" :disabled="table.disabled(`import`)">
                                    <i class="fas fa-file-import"></i>
                                    <span class="label">Import</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row accounts">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th class="active"></th>
                                    <th>
                                        <span>Number</span>
                                    </th>
                                    <th>
                                        <span>Name</span>
                                    </th>
                                    <th>
                                        <span>Value</span>
                                    </th>
                                    <th>
                                        <span>Custodian</span>
                                    </th>
                                    <th>
                                        <span>Billed To</span>
                                    </th>
                                    <th>
                                        <span>Schedule</span>
                                    </th>
                                    <th>
                                        <i class="fas fa-redo" v-on:click="table.load()"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in table.data" :key="index">
                                    <template v-if="index % 2 == 0">
                                        <td>
                                            <i class="fas toggle" :class="table.class('caret', index)" v-on:click="table.toggle(index)"></i>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'number')"></span>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'name')"></span>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'value')"></span>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'custodian')"></span>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'billedTo')"></span>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'schedule')"></span>
                                        </td>
                                        <td>
                                            <template v-if="!table.imported">
                                                <img class="contract" src="/img/ethereum-icon.svg" v-on:click="table.open(item, 'blockchain')">
                                            </template>
                                        </td>
                                    </template>
                                    <template v-else-if="table.account(index)">
                                        <td></td>
                                        <td colspan="7">
                                            <account-detail :account="table.account(index)" :imported="table.imported"></account-detail>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </scroll-area>
        </template>
    </div>
</template>

<style scoped>
    img.contract {
        width: 22px;
        cursor: pointer;
        margin: -5px 0 0 -2px;
    }
    i.fas.fa-redo {
        cursor: pointer;
    }
    div.buttons button {
        width: 100px !important;
    }
    div.file-upload input {
        display: none;
    }
    div.date {
        margin: 5px 0 0 20px;
    }
    div.date label {
        margin-right: 10px;
    }
    div.accounts {
        margin: 10px 0;
    }
    div.accounts tbody i {
        margin: 0 10px;
    }
    i.toggle {
        cursor: pointer;
    }
    p.securities {
        margin-top: 5px;
        font-weight: bold;
        opacity: 0.5;
    }
    table.securities {
        margin: 10px 0;
    }
    th.active  {
        width: 30px;
    }
    button i {
        margin-right: 5px;
    }
</style>

<script>
    import { datePicker, loading as loadingComponent, scrollArea } from '../../component'
    import { Api, Format, lib, Sys } from '../../factory'
    import { axios, moment, numeral } from '../../npm'
    import { smartkx, skivy71 } from '../../npm.org'
    import { alert, events, firebase, session } from '../../service'

    import accountDetail from './detail'

    var { firestore } = smartkx

    export default {
        get components() {
            return {
                accountDetail,
                datePicker,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                state: '',
                table: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
            isDemo() {
                return this.state.user.demo
            } 
        },
        methods: {
            init() {
                var { string } = lib
                var state = session.get('state')
                var table = {
                    async _account(token, r) {
                        var [a] = firestore.data(
                            await firestore
                                .collection(`accounts`)
                                .where(`householdId`, `==`, state.household._id)
                                .where(`number`, `==`, r.number)
                                .where(`name`, `==`, r.name)
                                .get(token)
                        )
                        return a
                    },
                    _accounts(record) {
                        var { _contract: c } = this
                        return record.accounts
                            .map((a) => {
                                var { name, number, value } = a
                                var account = c.accounts
                                    .find(acc => acc.number == a.number)
                                var billedTo = account.billing == -1 ? `Self` : c.accounts[account.billing].number
                                var custodian = smartkx.account.custodian(a.custodian)
                                var { scheduleName: schedule } = account
                                var securities = this._securities(record, a)
                                return { billedTo, custodian, name, number, schedule, securities, value }
                            })
                    },
                    _active: -1,
                    _contract: ``,
                    _data: [],
                    _date(d, reverse) {
                        return reverse
                            ? moment(d, this.date.options.format).format(this.date.format)
                            : moment(d, this.date.format).format(this.date.options.format)
                    },
                    async _records(token) {
                        return firestore.data(
                            await firestore
                                .collection(`records`)
                                .where(`contractId`, `==`, this._contract._id)
                                .get(token)
                        )
                    },
                    _securities(r, a) {
                        return r.securities // ternary can be removed once old API records are removed!
                            ? r.securities.filter(s => s.account == a.number)
                            : a.securities
                    },
                    account(index) {
                        var active = index - 1
                        return this._active == active ? this.data[active] : ''
                    },
                    class(index) {
                        var fa = `fa-caret`
                        return this._active == index ? `${fa}-down` : `${fa}-right`
                    },
                    get data() {
                        var date = this._date(this.date.value, true)
                        var record = this._data
                            .find(r => r.date == date)
                        var accounts = record ? this._accounts(record) : []
                        return accounts
                            .reduce((l, a) => {
                                l.push(...[a, null])
                                return l
                            }, [])
                    },
                    date: {
                        format: `YYYYMMDD`,
                        options: { format: `MMM D, YYYY`, events: [], width: `130px` },
                        value: ''
                    },
                    get dates() {
                        var dates = this._data
                            .map(r => this._date(r.date))
                        this.date.options.events = [...dates]
                        return dates
                    },
                    async demo() {
                        var csv = state.household.name
                            .toLowerCase()
                            .split(` `)
                            .join(`_`)
                        try {
                            var data = await firebase.download(`csv/${csv}.csv`)
                            this.upload(null, data)
                        } catch(e) {
                            console.log(e)
                            alert.error(e.message)
                        }
                    },
                    disabled(type) {
                        switch(type) {
                            case `import`:
                                return this.imported
                                //return !this._contract || this.imported ? true : false
                            default:
                                throw new Error(`Invalid disabled type, ${type}!`)
                        }
                    },
                    discard(confirm) {
                        if (!confirm) {
                            events.$emit(`confirm`, { operation: `discard account values`, prompt: `Are you sure?`, data: [] })
                            events.$once(`confirmed`, ({ data, confirmed }) => {
                                if (!confirmed)
                                    return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                                this.discard(...data, true)
                            })
                        } else {
                            var date = this._date(this.date.value, true) 
                            var index = this._data
                                .findIndex(r => r.date == date && r.import)
                            this._data.splice(index, 1)
                            this.load()
                        }
                    },
                    display(a, key) {
                        var { _contract: c } = this
                        var account = c.accounts
                            .find(acc => acc.number == a.number)
                        switch(key) {
                            case `custodian`:
                                return smartkx.account.custodian(account[key])
                            case `value`:
                                return numeral(a[key]).format(`$0,0.00`)
                            case `schedule`:
                                return account.scheduleName
                            case `billedTo`:
                                return account.billing == -1 ? `Self` : c.accounts[account.billing].number
                            default:
                                return a[key]
                        }
                    },
                    import: () => {
                        if (!this.table._contract)
                            return alert.warning(`Sorry, this household has no active contract!`, 3e3)
                        if (this.isDemo)
                            return this.table.demo()
                        this.$refs[`upload`].click()
                    },
                    get imported() {
                        var date = this._date(this.date.value, true) 
                        return this._data
                            .find(r => r.date == date && r.import)
                    },
                    async load() {
                        if (this.loading)
                            return
                        this.loading = true
                        var token = await firestore.token(firebase)
                        var [contract] = firestore.data(
                            await firestore
                                .collection(`contracts`)
                                .where(`householdId`, `==`, state.household._id)
                                .where(`active`, `==`, true)
                                .get(token)
                        )
                        this._contract = contract
                        var data = []
                        if (contract) {
                            var records = firestore.data(
                                await firestore
                                    .collection(`records`)
                                    .where(`contractId`, `==`, contract._id)
                                    .get(token)
                            )
                            data = [...records.sort((a, b) => b.date - a.date)]
                        }
                        this._data = [...data]
                        var [date] = this.dates
                        this.date.value = date
                        this.loading = false
                        this._active = -1
                    },
                    modal: { account: '' },
                    async open(r, type) {
                        switch(type) {
                            case `blockchain`:
                                var component = `account-values`
                                var token = await firestore.token(firebase)
                                var [account, records] = await Promise.all([this._account(token, r), this._records(token)])
                                var { _contract: contract } = this
                                var data = { account, contract, records }
                                events.$emit(`modal`, { component, data, class: `modal-lg` })
                                break
                        }
                    },
                    pending: false,
                    async save(confirm) {
                        if (!confirm) {
                            events.$emit(`confirm`, { operation: `save account values`, prompt: `Are you sure?`, data: [] })
                            events.$once(`confirmed`, ({ data, confirmed }) => {
                                if (!confirmed)
                                    return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                                this.save(...data, true)
                            })
                        } else {
                            var { _id: managerId } = state.user
                            var { _id: contractId } = this._contract
                            var date = this._date(this.date.value, true)
                            var { accounts, securities } = this._data
                                .find(r => r.date == date)
                            var data = { accounts, contractId, date, managerId, securities }
                            try {
                                this.pending = true
                                var resp = await Api.post(`contract/accounts`, data) 
                                var { message, refresh } = resp.data
                                alert.message(message, 3e3)
                                await Sys.wait(1.5e3)
                                if (refresh)
                                    this.load()
                            } catch(e) {
                                console.log(e)
                                alert.error(e.status ? `${e.status}: ${e.message}` : e.message)
                            } finally {
                                await Sys.wait(0.5e3)
                                this.pending = false
                            }
                        }
                    },
                    style(attr, value) {
                        return [attr, value]
                            .join(`: `)
                    },
                    toggle(index) {
                        this._active = this._active == index ? -1 : index
                    },
                    upload(file, demoData) {
                        function onloadend(e, demoD) {
                            var { _contract } = this
                            var data = smartkx.account.data(demoD || reader.result)
                            var accounts = data.accounts
                                .filter(a => _contract.accounts.find(acc => acc.name == a.name && acc.number == a.number))
                            if (!accounts.length)
                                return alert.error(`${File} does not contain any accounts relevant to the household current contract!`)
                            if (accounts.length != _contract.accounts.length)
                                alert.warning(`${File} accounts '${accounts.length}' does not match contract accounts '${_contract.accounts.length}'!`)
                            var securities
                            for (var account of accounts) {
                                securities = this._securities(data, account)
                                if (!securities.length)
                                    return alert.error(`Account ${account.name} has no securities!`)
                                if (securities.reduce((v, s) => v += s.value, 0) != account.value)
                                    return alert.error(`Account ${account.name} value and its securities value(s) do not match!`)
                            }
                            var dates = accounts
                                .reduce((l, a) => {
                                    if (!l.find(d => d == a.date))
                                        l.push(a.date)
                                    return l
                                }, [])
                            if (dates.length > 1)
                                return alert.error(`${File} accounts should contain a single date period!`)
                            var [date] = dates
                            var record = { accounts, securities: [...data.securities], date, import: true } 
                            var index = this._data.findIndex(r => r.date == record.date)
                            if (index < 0) {
                                this._data.push(record)
                            } else {
                                this._data.splice(index, 1, record)
                            }
                            this.date.value = this._date(date)
                            this._active = -1
                        }
                        if (!demoData) {
                            var File = `File '${file.name}'`
                            var reader = new FileReader()
                            reader.onloadend = onloadend.bind(this)
                            reader.onerror = (e) => {
                                alert.error(e.message || `File upload failed!`)
                            }
                            reader.readAsText(file)
                        } else {
                            onloadend.call(this, null, demoData)
                        }
                    }
                }
                table.load()
                this.state = state
                this.table = table
            },
            upload(e) {
                var [file] = this.$refs.upload.files
                this.table
                    .upload(file)
            }
        },
        watch: {
            'state.household': {
                handler() {
                    this.table.load()
                }
            }
        }
    }
</script>