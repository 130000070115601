<template>
    <div class="h-100">
        <template v-if="table.loading">
            <loading-component></loading-component>
        </template>
        <template v-if="table.ready">
            <scroll-area>
                <div class="m-3">
                    <table class="table table-sm households">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <span>Household name</span>
                                </th>
                                <th>
                                    <span>Household ref</span>
                                </th>
                                <th>
                                    <span>Include</span>
                                </th>
                                <th>
                                    <span>Accounts (selected / total)</span>
                                </th>
                                <th>
                                    <i class="fas fa-redo" v-on:click="table.reload()"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(household, index) in table.data" :key="index">
                                <template v-if="index % 2 == 0">
                                    <td class="active">
                                        <i class="fas toggle" :class="table.class(index)" v-on:click="table.toggle(index)"></i>
                                    </td>
                                    <td>
                                        <span v-text="household.name"></span>
                                    </td>
                                    <td>
                                        <span v-text="household.ref"></span>
                                    </td>
                                    <td class="include">
                                        <input type="checkbox" v-model="household.include" v-on:change="table.include(household)">
                                    </td>
                                    <td class="accounts">
                                        <span v-text="table.accounts(household)"></span>
                                    </td>
                                    <td></td>
                                </template>
                                <template v-else-if="table.household(index)">
                                    <td></td>
                                    <td colspan="5">
                                        <contract-household :contract="contract" :household="table.household(index)"></contract-household>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </scroll-area>
        </template>
    </div>
</template>

<style scoped>
    i.fas.toggle {
        cursor: pointer;
    }
    i.fas.fa-redo {
        cursor: pointer;
    }
    table.households td.active  {
        width: 30px;
    }
    table.households td.accounts {
        width: 250px;
        text-align: right;
        padding-right: 100px;
    }
    table.households td.include {
        padding-left: 10px;
    }
    i.fas.fa-caret-down {
        margin-left: -2px;
    }
</style>

<script>
    import { loading as loadingComponent, scrollArea } from '../../../../component'
    import { lib } from '../../../../factory'
    import { Household } from '../../../../model'
    import { firebase, session } from '../../../../service'

    import contractHousehold from './household'

    export default {
        get components() {
            return {
                contractHousehold,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                table: '',
                state: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
            contract() {
                var { state } = this
                var { company, contracts, household } = state
                var { _id: householdId } = household
                var contract = state.contracts
                    .find(c => c.householdId == householdId)
                if (!contract) {
                    contract = { householdId }
                    state.contracts.push(contract)
                }
                if (!contract.hasOwnProperty(`households`))
                    this.$set(contract, `households`, [])
                return contract
            }
        },
        methods: {
            init() {
                var { string } = lib
                this.state = session.get('state')
                var { contract, state } = this
                var table = {
                    _active: -1,
                    accounts(h) {
                        var { accounts } = h
                        var selected = accounts
                            .filter(a => a.select)
                        return `${selected.length} / ${accounts.length}`
                    },
                    class(index) {
                        var fa = `fa-caret`
                        return this._active == index ? `${fa}-down` : `${fa}-right`
                    },
                    data: [],
                    household(index) {
                        var active = index - 1
                        return this._active == active ? this.data[active] : '' 
                    },
                    include(h) {
                        h.accounts
                            .forEach((a) => {
                                a.select = h.include 
                            })
                    },
                    async load(force) {
                        if (this.loading)
                            return console.log(`already loading...`)
                        if (contract.households.length && !force) {
                            var index = contract.households
                                .findIndex(h => h.name == state.household.name)
                            this.data = [...contract.households.slice(index, index + 2)] // hack to hide multi household
                            this.ready = true
                            return
                        }
                        this.loading = true
                        this.data.length = 0
                        var data = session.get(`data`)
                        var accounts, active, household
                        var [schedule] = contract.schedules
                        var households = (await Promise.all(data.households.map(h => Household.accounts(h))))
                            //.filter(h => h.name == state.household.name) // single household
                            .map((accounts, i) => {
                                var h = data.households[i]
                                var { name, ref } = h
                                var active = h._id == state.household._id
                                var household = { active, name, ref }
                                household.include = active
                                household.accounts = accounts
                                    .map((a) => {
                                        a.select = household.include
                                        a.scheduleName = schedule.name
                                        a.billTo = `Self`
                                        return a
                                    })
                                return household
                            })
                        this.loading = false
                        contract.households = households
                            .filter(h => h.accounts.length)
                            .sort((a, b) => b.include - a.include)
                            .reduce((arr, h) => {
                                arr.push(...[h, null])
                                return arr
                            }, [])
                        if (!contract.households.length)
                            return console.log(`No households with accounts????`)
                        this.load()
                    },
                    loading: false,
                    ready: false,
                    reload() {
                        this.load(true)
                    },
                    toggle(index) {
                        this._active = this._active == index ? -1 : index
                    }
                }
                table.load()
                this.table = table
            }
        },
        watch: {
            'state.household': {
                handler() {
                    var { table } = this
                    table.ready = false
                    this.$nextTick(() => {
                        table.load()
                    })
                }
            }
        }
    }
</script>