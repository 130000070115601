<template>
    <div class="h-100">
        <h5 class="bg-light p-3 border-bottom">Invoices</h5>
        <div class="mb-2" style="height: 80vh">
            <div class="my-2 mx-3 h-100">
                <div class="row pt-2">
                    <div class="col-6">
                        <div class="form form-inline">
                            <label class="mr-2">Status</label>
                            <select class="form-control form-control-sm" v-model="table.status" v-on:change="table.change('status')">
                                <option v-for="(status, i) in invoice.status" :key="i">
                                    <span v-text="status"></span>
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col buttons">
                        <div class="form form-inline float-right">
                            <!--
                            <template v-if="!table.imported">
                                <div class="file-upload">
                                    <input type="file" ref="upload" v-on:change="upload" accept=".csv">
                                </div>
                            </template>
                            <template v-if="table.imported">
                                <button type="button" class="btn btn-danger btn-sm mr-2" v-on:click="table.discard()">
                                    <i class="fas fa-times-circle"></i>
                                    <span>Discard</span>
                                </button>
                                <button type="button" class="btn btn-success btn-sm mr-2" v-on:click="table.save()" :disabled="table.pending">
                                    <template v-if="table.pending">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-check-circle"></i>
                                    </template>
                                    <span>Save</span>
                                </button>
                            </template>
                            -->
                            <template v-if="table.exported">
                                <button class="btn btn-sm btn-success mr-2" v-on:click="table.submit()" :disabled="table.pending">
                                    <template v-if="table.pending">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-check-circle"></i>
                                    </template>
                                    <span>Submit</span>
                                </button>
                            </template>
                            <button class="btn btn-sm btn-info" v-on:click="table.export()" :disabled="table.disabled(`export`)">
                                <template v-if="table.pending">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </template>
                                <template v-else>
                                    <i class="fas fa-file-download"></i>
                                </template>
                                <span>Export</span>
                            </button>
                        </div>
                    </div>
                </div>
                <template v-if="table.loading">
                    <div class="h-100">
                        <loading-component></loading-component>
                    </div>
                </template>
                <template v-else>
                    <scroll-area>
                        <div class="row mt-3">
                            <div class="col">
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th class="active"></th>
                                            <th>
                                                <span>Household</span>
                                            </th>
                                            <th>
                                                <span>Reference</span>
                                            </th>
                                            <th>
                                                <span>Period</span>
                                            </th>
                                            <th>
                                                <span>Total</span>
                                            </th>
                                            <th>
                                                <span>Created</span>
                                            </th>
                                            <th>
                                                <span>Updated</span>
                                            </th>
                                            <th>
                                                <span>Include</span>
                                            </th>
                                            <th>
                                                <i class="fas fa-redo" v-on:click="table.load()"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in table.items" :key="index">
                                            <template v-if="index % 2 == 0">
                                                <td>
                                                    <i class="fas toggle" :class="table.class(index)" v-on:click="table.toggle(index)"></i>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'household')"></span>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'ref')"></span>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'period')"></span>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'total')"></span>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'created')"></span>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'updated')"></span>
                                                </td>
                                                <td>
                                                    <input type="checkbox" v-model="item.export" v-on:change="table.change('export', item)">
                                                </td>
                                                <td></td>
                                            </template>
                                            <template v-else-if="table.invoice(index)">
                                                <td></td>
                                                <td colspan="8">
                                                    <invoice-details :invoice="table.invoice(index)"></invoice-details>
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </scroll-area>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    td.docs img:last-child {
        margin-left: 25px;
    }
    i.fas.fa-redo {
        cursor: pointer;
    }
    i.remove {
        cursor: pointer;
    }
    i.remove:hover {
        color: red;
    }
    div.col-10 {
        text-align: right;
    }
    /*
    button {
        margin: 4px 0;
    }
    */
    i.click {
        cursor: pointer;
    }
    img.contract {
        width: 24px;
        cursor: pointer;
        margin-top: -5px;
    }
    /*
    button {
        margin-top: -5px;
    }
    button i {
        padding: 0 5px;
    }
    */
    i.fas.toggle {
        cursor: pointer;
    }
    th.active  {
        width: 30px;
    }
    th.name {
        width: 25%;
    }
    th.summary {
        width: 55%;
    }
    i.fas.fa-caret-down {
        margin-left: -2px;
    }
    div.file-upload input {
        display: none;
    }
    div.buttons button {
        width: 100px !important;
    }
    button i {
        margin-right: 5px;
    }
</style>

<script>
    import { loading as loadingComponent, scrollArea } from '../../component'
    import { Api, lib, Sys, Tab } from '../../factory'
    import { Household } from '../../model'
    import { axios, moment, numeral } from '../../npm'
    import { smartkx, skivy71 } from '../../npm.org'
    import { alert, events, firebase, session } from '../../service'

    import invoiceDetails from './details'

    var { firestore } = smartkx
    var { utils } = skivy71

    export default {
        get components() {
            return {
                invoiceDetails,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                table: '',
                state: ''
            }
        },
        created() {
            //var { firmClients } = this
            //this.events = { firmClients: firmClients.bind(this) }
            //events.$watch(this.events)
            this.init()
        },
        destroyed() {
            //events.$unwatch(this.events)
        },
        computed: {
            invoice() {
                var { status } = smartkx.invoice
                return { status }
            }
        },
        methods: {
            download(csv) {
                var el = document.createElement(`a`)
                el.setAttribute(`href`, `data:text/plain;charset=utf-8,` + encodeURIComponent(csv.data))
                el.setAttribute(`download`, csv.name)
                el.style.display = `none`
                document.body.appendChild(el)
                el.click()
                document.body.removeChild(el)
            },
            init() {
                var { string } = lib
                var state = session.get('state')
                var table = {
                    _active: -1,
                    async _contract(invoice, token) {
                        var [c] = firestore.data(
                            await firestore
                                .collection(`contracts`)
                                .doc(invoice.contractId)
                                .get(token)
                        )
                        return c
                    },
                    _data: { households: [], invoices: [] }, 
                    _date(d) {
                        var day = {
                            now: new Date(Date.now()).getDate(),
                            record: new Date(d).getDate() 
                        }
                        return day.now == day.record ? moment(d).format(this._timeFormat) : moment(d).format(this._dateFormat)
                    },
                    _timeFormat: `HH:mm:ss`,
                    _dateFormat: `MMM D, YYYY`,
                    async _households(token) {
                        return firestore.data(
                            await firestore
                                .collection(`households`)
                                .where(`companyId`, `==`, state.company._id)
                                .get(token)
                        )
                    },
                    async _invoices(token) {
                        var status = smartkx.invoice.status
                            .findIndex(s => s == this.status)
                        return firestore.data(
                            await firestore
                                .collection(`invoices`)
                                .where(`companyId`, `==`, state.company._id)
                                .where(`status`, `==`, status)
                                .get(token)
                        )
                    },
                    change(type, item) {
                        switch(type) {
                            case `status`:
                                this.exported = false
                                this.load()
                                break
                            case `export`:
                                this.exported = false
                                break
                            default:
                                throw new Error(`Invalid change type, ${type}!`)
                        }
                    },
                    class(index) {
                        var fa = `fa-caret`
                        return this._active == index ? `${fa}-down` : `${fa}-right`
                    },
                    disabled(type) {
                        switch(type) {
                            case `export`:
                                return this.status.toLowerCase() == `saved` ? false : true
                            default:
                                return false
                        }
                    },
                    discard() {
                        this.imported = false
                        this.load()
                    },
                    display(item, key) {
                        var { string } = lib
                        switch(key) {
                            case `household`:
                                var household = this._data.households
                                    .find(h => h._id == item.householdId)
                                return household.name
                            case `ref`:
                                return item.reference || `-`
                            case `period`:
                                var { period } = item
                                var p = `${period.start} - ${period.end} (${period.days} days)`
                                return p
                            case `total`:
                                var v = item.accounts
                                    .reduce((v, a) => v += a.fee, 0)
                                return numeral(v)
                                    .divide(1e4)
                                    .format(`$0,0.00`)
                            case `created`:
                            case `updated`:
                                return this._date(item[`_${key}`])
                            default:
                                return item[key]
                        }
                    },
                    export: async () => {
                        var { table } = this
                        if (table.pending)
                            return
                        table.pending = true
                        try {
                            var token = await firestore.token(firebase)
                            var { _data: d } = table
                            var invoices = d.invoices
                                .filter(i => i != null && i.export)
                            var { user } = session.get(`state`)
                            var { _id: managerId } = user
                            var data = { invoices, managerId }
                            var resp = await Api.post(`client/billing`, data)
                            var { csv } = resp.data
                            alert.message(`Successfully generated ${csv.name}`, 3e3)
                            await Sys.wait(1e3)
                            this.download(csv)
                            await Sys.wait(1e3)
                            table.exported = true
                        } catch(e) {
                            console.log(e)
                            alert.error(e.message)
                        } finally {
                            table.pending = false
                        }
                    },
                    exported: false,
                    get imported() {
                        return this._data.invoices
                            .find(i => i.import)
                    },
                    invoice(index) {
                        var active = index - 1
                        var log = this._active == active ? this.items[active] : ''
                        return log 
                    },
                    item: ``,
                    get items() {
                        return this._data.invoices
                            .reduce((list, invoice) => {
                                list.push(...[invoice, null])
                                return list
                            }, [])
                    },
                    async load() {
                        if (this.loading)
                            return console.log(`already loading`)
                        this.loading = true
                        var token = await firestore.token(firebase)
                        var [h, i] = await Promise.all([this._households(token), this._invoices(token)])
                        var invoices = i
                            .sort((a, b) => new Date(b._created).getTime() - new Date(a._created).getTime())
                            .map((i) => {
                                i.export = true
                                return i
                            })
                        this._data.invoices = [...invoices]
                        this._data.households = [...h]
                        this.loading = false
                    },
                    loading: false,
                    pending: false,
                    reset() {
                        this._active = -1
                        this.item = ``
                        return this
                    },
                    status: `Saved`,
                    async submit(confirm) {
                        if (!confirm) {
                            events.$emit(`confirm`, { operation: `invoice(s) submission`, prompt: `Are you really, really sure???` })
                            events.$once(`confirmed`, ({ data, confirmed }) => {
                                if (!confirmed)
                                    return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                                this.submit(true)
                            })
                        } else {
                            console.log(`really submit?`)
                            if (this.pending)
                                return
                            var { _id: managerId } = state.user
                            var invoices = this._data.invoices
                                .filter(i => i.export)
                            var data = { invoices, managerId }
                            try {
                                var resp = await Api.post(`invoice/submit`, data)
                                var { message } = resp.data
                                alert.message(message, 3e3)
                            } catch(e) {
                                console.log(e.message)
                                alert.error(e.message)
                            } finally {
                                await Sys.wait(1e3)
                                this.pending = false
                                this.exported = false
                                this.load()
                            }
                        }
                    },
                    toggle(index) {
                        this._active = this._active == index ? -1 : index
                    },
                    upload(file) {
                        var File = `File '${file.name}'`
                        var reader = new FileReader()
                        reader.onloadend = (e) => {
                            var data = smartkx.household.data(reader.result)
                            var clients = data.clients
                                .map((c) => {
                                    c.import = true
                                    var a = data.addresses
                                        .find(a => a.client == c.ref)
                                    if (a)
                                        c.address = { ...a }
                                    return c
                                })
                            this._data = [...clients]
                            this._active = -1
                        }
                        reader.onerror = (e) => {
                            alert.error(e.message || `File upload failed!`)
                        }
                        reader.readAsText(file)
                    }
                }
                table.load()
                this.state = state
                this.table = table
            },
            upload(e) {
                var [file] = this.$refs.upload.files
                this.table
                    .upload(file)
            }
        },
        watch: {
            /*
            'state.household': {
                handler() {
                    this.table
                        .reset()
                        .load()
                }
            }
            */
        }
    }
</script>