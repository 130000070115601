<template>
    <div class="root">
        <template v-if="option.src">
            <video :id="id" width="320" height="240" controls :src="option.src"></video>
        </template>
    </div>
</template>

<style scoped>
    div.root {
        position: fixed;
        margin-top: -1000px
    }
</style>

<script>
    import { events } from '../service'

    export default {
        data() {
            return {
                option: ``
            }
        },
        created() {
            this.init()
            var { video } = this
            this.events = { video: video.bind(this) }
            events.$watch(this.events)
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
            id() {
                return this._uid
            }
        },
        methods: {
            closed() {
                var { closed, option, loaded } = this
                option.src = ``
                option.type = ``
                var el = document.getElementById(this.id)
                el.removeEventListener(`loadedmetadata`, loaded.bind(this))
                el.removeEventListener(`leavepictureinpicture`, closed.bind(this))
            },
            init() {
                this.option = { src: ``, type: `` }
            },
            loaded() {
                var el = document.getElementById(this.id)
                el.requestPictureInPicture()
                el.play()
            },
            video(e) {
                var { src, type } = e
                var { closed, option, loaded } = this
                option.src = src
                option.type = type
                this.$nextTick(() => {
                    var el = document.getElementById(this.id)
                    el.addEventListener('loadedmetadata', loaded.bind(this))
                    el.addEventListener('leavepictureinpicture', closed.bind(this))
                })
            }
        }
    }
</script>