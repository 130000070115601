<template>
    <div class="h-100">
        <h5 class="bg-light p-3 border-bottom">Billing</h5>
        <div class="h-100 m-3">
            <div class="row">
                <div class="col-2">
                    <div v-for="(o, i) in ['2019', '2018', '2017']" class="mb-3">
                        <strong>{{o}}</strong>
                        <div class="btn-group btn-group-sm">
                            <a class="btn btn-outline-secondary" :class="{ 'active': i === 0 }" href="#">Q1</a>
                            <a class="btn btn-outline-secondary" href="#">Q2</a>
                            <a class="btn btn-outline-secondary" href="#">Q3</a>
                            <a class="btn btn-outline-secondary" href="#">Q4</a>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <table class="table border-bottom">
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="" value=""></th>
                                <th>Client</th>
                                <th>Invoice Name</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o, i) in json.households">
                                <td><input type="checkbox" name="" value=""></td>
                                <td><strong>{{o.name}}</strong></td>
                                <td>{{o.invoice}}</td>
                                <td><a href="#">View Invoice</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-primary float-right">Download CSV</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    import json from './data.json'
    
    import { session } from '../../service'

    export default {
        get components() {
            return {}
        },
        data() {        
            return {
                state: '',
                json: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
            mock() {
                var { mock } = this.state
                return mock
            }
        },        
        methods: {
            init() {
                this.json = json
                this.state = session.get(`state`)
            }
        }
    }
</script>