<template>
    <div class="h-100">
        <alert-component></alert-component>
        <confirm-component></confirm-component>
        <modal-component></modal-component>
        <video-component></video-component>
        <template v-if="view.ready">
            <component :is="view.component"></component>
        </template>
        <template v-else>
            <div class="h-100 bg-light">
                <loading-component :loading="loading"></loading-component>
            </div>
        </template>
    </div>
</template>

<style scoped>

</style>

<script>
    import { alert as alertComponent, confirm as confirmComponent, loading as loadingComponent, modal as modalComponent, video as videoComponent } from '../component'
    import { Sys } from '../factory'
    import { home as homeView, login as loginView } from './index'
    import { smartkx } from '../npm.org'
    import { alert, auth, events, firebase, session } from '../service'

    var { firestore } = smartkx

    export default {
        get components() {
            return {
                alertComponent,
                confirmComponent,
                modalComponent,
                homeView,
                loadingComponent,
                loginView,
                videoComponent
            }
        },
        data() {
            return {
                loading: '',
                view: ''
            }
        },
        created() {
            this.init()
        },
        mounted() {
            this.mount()
        },
        methods: {
            async authState(auth) {
                var { view } = this
                var state = session.get('state')
                if (!view.init)
                    view.init = true
                switch(auth) {
                    case null:
                        state.user = ''
                        if (view.ready)
                            this.logout()
                        break
                    default:
                        var { uid: authId } = auth
                        var [user] = firestore.data(
                            await firestore
                                .collection(`managers`)
                                .where(`authId`, `==`, authId)
                                .get(await firestore.token(firebase))
                        )
                        if (!user)
                            throw new Error(`No user for authId, ${authId}`)
                        state.user = user
                        if (view.ready)
                            this.login()
                        break
                }
            },
            async init() {
                var state = session.get('state')
                var { authState } = this
                auth.state(authState.bind(this))
                this.loading = {
                    class: `fa fa-sync fa-spin`,
                    style: `font-size: 200px; opacity: 0.3`
                }
                this.view = {
                    _index: 0,
                    _list: ['login', 'home'],
                    get component() {
                        var { _index, _list } = this
                        var view = _list[_index]
                        return `${view}-view`
                    },
                    init: false,
                    ready: false,
                    select(view) {
                        console.log('view select', view)
                        var index = this._list.findIndex(v => v == view)
                        if (index < 0)
                            throw new Error(`Invalid view, ${view}!`)
                        this._index = index
                    } 
                }
            },
            async login() {
                await Sys.wait(100)
                await alert.message(`Ok, you are logged in!`, 1500)
                var state = session.get(`state`)
                var { user } = state
                if (user.role && user.role == `admin`) {
                    var component = `admin-login`
                    var data = { user: state.user }
                    events.$emit(`modal`, { component, data, class: `modal-lg`, close: false })
                    events.$once(`admin`, (e) => {
                        console.log(`admin event...`)
                        var { demo } = e
                        if (demo.user)
                            state.user = demo.user
                        events.$emit(`modal`, { action: `hide`})
                        this.view.select(`home`)
                    })
                } else {
                    this.view.select('home')
                }
            },
            async logout() {
                await Sys.wait(100)
                this.view.select('login')
                var state = session.get('state')
                var keys = ['company', 'household', 'user']
                keys.forEach((key) => {
                    if (state.hasOwnProperty(key))
                        state[key] = null
                })
                await alert.warning(`Ok, you are logged out!`, 1500)
            },
            async mount() {
                var { view } = this
                await Sys.wait(1500)
                var state = session.get('state')
                view.ready = true
                view.select(state.user ? 'home' : 'login')
            }
        }
    }
</script>