<template>
    <div class="h-100">
        <template v-if="billing.loading">
            <div class="h-100">
                <loading-component></loading-component>
            </div>
        </template>
        <template v-else>
            <div class="h-100 scrollbar" data-simplebar>
                <div class="my-3 mx-4">
                    <div class="row">
                        <div class="col-3">
                            <div class="form-group">
                                <label for="period">Reference</label>
                                <input class="form-control form-control-sm" placeholder="Reference" v-model="billing.reference" :disabled="billing.state > 0">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="interval">Interval</label>
                                <select class="form-control form-control-sm" id="interval" v-model="billing.interval" :disabled="billing.state > 0">
                                    <option v-for="(interval, index) in billing.intervals" :key="index">
                                        <span v-text="interval"></span>
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <template v-if="billing.interval == 'Weekly'">
                                    <label for="interval-day-of-week">Day</label>
                                    <select class="form-control form-control-sm" id="interval-day-of-week" v-model="billing.dayOfWeek" :disabled="billing.state > 0">
                                        <option v-for="(dayOfWeek, index) in billing.daysOfWeek" :key="index">
                                            <span v-text="dayOfWeek"></span>
                                        </option>
                                    </select>
                                </template>
                                <template v-if="billing.interval == 'Monthly'">
                                    <label for="interval-day">Day</label>
                                    <select class="form-control form-control-sm" id="interval-day" v-model="billing.day" :disabled="billing.state > 0">
                                        <option v-for="(day, index) in billing.days" :key="index">
                                            <span v-text="day"></span>
                                        </option>
                                    </select>
                                </template>
                                <template v-if="billing.interval == 'Quarterly' || billing.interval == 'Yearly'">
                                    <label for="interval-month">Month</label>
                                    <select class="form-control form-control-sm" id="interval-month" v-model="billing.month" :disabled="billing.state > 0">
                                        <option v-for="(month, index) in billing.months" :key="index">
                                            <span v-text="month"></span>
                                        </option>
                                    </select>
                                </template>
                            </div>
                        </div>
                        <div class="col-2">
                            <template v-if="billing.interval == 'Quarterly' || billing.interval == 'Yearly'">
                                <label for="interval-day">Day</label>
                                <select class="form-control form-control-sm" id="interval-day" v-model="billing.day" :disabled="billing.state > 0">
                                    <option v-for="(day, index) in billing.days" :key="index">
                                        <span v-text="day"></span>
                                    </option>
                                </select>
                            </template>
                            <!--
                            <template v-if="billing.interval == 'Year'">
                                <label for="interval-day-month">Day</label>
                                <select class="form-control form-control-sm" id="interval-day-month" v-model="billing.day" v-on:change="billing.change('day')" :disabled="billing.state > 0">
                                    <option v-for="(days, index) in billing.days" :key="index">
                                        <span v-text="days"></span>
                                    </option>
                                </select>
                            </template>
                            -->
                        </div>
                        <div class="col-2">
                            <!--
                            <div class="form-group">
                                <label for="period">Fees</label>
                                <input class="form-control form-control-sm" :value="billing.fees" readonly>
                            </div>
                            -->
                        </div>
                        <!--
                        <div class="col-2">
                            <div class="form-group">
                                <label for="date-end">Recurring</label>
                                <select class="form-control form-control-sm" id="recurr" v-model="select.recurr" v-on:change="select.change('recurr')">
                                    <option v-for="(option, index) in select.recurring" :key="index">
                                        <span v-text="option"></span>
                                    </option>
                                </select>
                            </div>
                        </div>
                        -->
                    </div>
                    <div class="row">
                        <div class="col">
                            <p>Contracts</p>
                            <div id="accordion">
                                <div v-for="(contract, index) in billing.contracts" :key="index" class="card">
                                    <div class="card-header" :id="contract._id">
                                        <table class="contract-account">
                                            <tr>
                                                <td>
                                                    <h5 class="mb-0">
                                                        <button class="btn btn-link" data-toggle="collapse" :data-target="`#collapse-${contract._id}`" aria-expanded="true" aria-controls="collapseOne">
                                                            <span v-text="contract._id"></span>
                                                        </button>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <span v-text="billing.last(contract)"></span>
                                                </td>
                                                <td>
                                                    <span>Include: </span>
                                                    <input type="checkbox" v-model="contract.bill" :disabled="billing.state > 0">
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div :id="`collapse-${contract._id}`" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div class="card-body">
                                            <table class="table table-sm table-borderless">
                                                <tr>
                                                    <th v-for="(key, index) in accountKeys" :key="index">
                                                        <span v-text="key.label"></span>
                                                    </th>
                                                </tr>
                                                <tr v-for="(account, i1) in contract.accounts" :key="i1">
                                                    <td v-for="(key, i2) in accountKeys" :key="i2">
                                                        <template v-if="key.value == 'billing'">
                                                            <span v-text="billedTo(contract, account)"></span>
                                                        </template>
                                                        <template v-else>
                                                            <span v-text="account[key.value]"></span>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
	</div>
</template>

<style scoped>
    table.contract-account {
        width: 100%;
    }
    table.contract-account td:first-child {
        width: 275px;
    }
    table.contract-account td:last-child {
        text-align: right;
    }
    table.contract-account td:last-child input {
        vertical-align: inherit;
        margin-left: 10px;
    }
    div.modal-body > div {
        margin: 5px 10px;
    }
    nav a.dropdown-item {
        cursor: pointer;
    }
    div.modal-content {
        min-height: 85vh;
    }
    div.modal-header {
        margin: 0 3px;
    }
    div.modal-footer table {
        width: 100%;
    }
    div.modal-footer table td:not(:nth-child(2)) {
        width: 50px;
    }
    div.modal-footer table td:nth-child(2) {
        text-align: center;
    }
    div.view {
        margin: 10px;
    }
    div.reveal {
        position: absolute;
        top: 50%;
        left: -10px;
    }
    div.modal-footer p {
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        opacity: 0.6;
    }
</style>

<script>
    import moment from 'moment'
    import numeral from 'numeral'
    import { loading as loadingComponent } from '../../component'
    import { Contract, lib, Style, Sys, Tab } from '../../factory'
    import { Household } from '../../model'
    import { firebase, session } from '../../service'

    import Simplebar from 'simplebar'
    import 'simplebar/dist/simplebar.css'

    var { $ } = window

    export default {
        get components() {
            return {
                loadingComponent
            }
        },
        data() {
            return {
                accountKeys: '',
                billing: '',
                state: '',
                status: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
        },
        methods: {
            billedTo(contract, account) {
                var { accounts } = contract
                var { billing: index } = account
                return index == -1 ? `Self` : accounts[index].number 
            },
            /*
            async calculate() {
                var { billing } = this
                var { startDate, endDate, householdContracts: contracts } = billing
                var [start, end] = [startDate, endDate]
                    .map(d => moment(d, `MMM DD, YYYY`).format(`YYYYMMDD`))
                var map = new Map()
                var events
                var fees = 0
                try {
                    for (var contract of contracts) {
                        if (!contract.bill) {
                            console.log('skipping', contract._id)
                            continue
                        }
                        fees += Array.from([...await Contract.events(contract, 'valueUpdate')]
                            .reduce((map, event) => {
                                var e = map.get(event.data.date)
                                return !e || event.block > e.block ? map.set(event.data.date, event) : map
                            }, new Map())
                            .values())
                            .filter(e => +e.data.date >= start && +e.data.date <= end)
                            .reduce((t, e) => t += +e.data.feeTotal, 0)
                    }
                    billing.fees = fees / 1e4
                } catch(e) {
                    console.log(e)
                }
            },
            fees(v) {
                return numeral(v)
                    .format(`$0.00`)
            },
            */
            init() {
                var { string } = lib
                this.status = { text: '', color: '' }
                this.accountKeys = ['number', 'name', 'billing']
                    .map((value) => {
                        var label = value == 'billing' ? 'Billed To' : string.capitalise(value)
                        return { label, value }
                    })
                this.state = session.get('state')
                this.billing = {
                    get months() {
                        return new Array(12)
                            .fill(0)
                            .map((k, i) => moment(i + 1, 'M').format('MMMM'))
                    },
                    month: 'January',
                    get daysOfWeek() {
                        return new Array(6)
                            .fill(0)
                            .map((k, i) => moment(i, 'd').format('dddd'))
                    },
                    dayOfWeek: 'Monday',
                    get intervals() {
                        return ['Weekly', 'Monthly', 'Quarterly', 'Yearly']
                    },
                    interval: 'Quarterly',
                    get days() {
                        return new Array(28)
                            .fill(0)
                            .map((k, i) => moment(i + 1, 'D').format('Do'))
                    },
                    day: '1st',
                    state: 0,
                    _error: (e) => {
                        this.message(e, true)
                    },
                    _status: (bill) => {
                        if (!bill)
                            return this.message()
                        if (bill.state == 2)
                            return this.message(`Done!`)
                        var { contracts, interval, reference: ref, summary } = bill
                        var c = contracts.filter(c => c.bill == true).length
                        this.message(`Saving, ref: ${ref}, summary: ${summary}...`)
                    },
                    get disabled() {
                        var { contracts, reference } = this
                        return contracts.find(c => c.bill == true) && reference ? false : true
                    },
                    get header() {
                        var { reference } = this
                        return reference ? `New Billing Cycle - ${reference}` : `New Billing Cycle`
                    },
                    loading: false,
                    reference: '',
                    //householdContracts: [],
                    contract({ _id }) {
                        return `Id: ${_id}`
                    },
                    last(contract) {
                        var { lastBilled: last = 'Never' } = contract
                        return `Last Billed: ${last}`
                    },
                    contracts: [],
                    async save() {
                        var { contracts, reference } = this
                        if (!contracts.find(c => c.bill == true))
                            return this._error(`Cannot create a billing cycle with no contracts!`)
                        if (!reference)
                            return this._error('Cannot create a billing cycle without a reference!')
                        this._status(this)
                        this.state = 1
                        await Sys.wait(3000)
                        this.state = 2
                        this._status(this)
                        await Sys.wait(2000)
                        this._status()
                    },
                    get summary() {
                        var { day, month, dayOfWeek, interval } = this
                        switch(interval.toLowerCase()) {
                            case 'weekly':
                                return `every ${dayOfWeek}`
                            case 'monthly':
                                return `every month, on the ${day}`
                            case 'quarterly':
                                return `every quarter, starting on ${month} ${day}`
                            case 'yearly':
                                return `every year, on ${month} ${day}`
                        }
                    }
                }
                this.load()
            },
            /*
            interpolate(list) {
                var format = `YYYYMMDD`
                var difference = (a, b) => moment(b, format).diff(moment(a, format))
                var carry = (d) => +moment(d, format).add(1, 'days').format(format)
                return list
                    .reduce((arr, item, i) => {
                        if (arr.length) {
                            var n = 0
                            while(difference(arr[arr.length - 1], item) > 86400e3) {
                                arr.push(carry(arr[arr.length - 1]))
                                n++
                                if (n > 3)
                                    throw 'oops'
                            }
                        }
                        arr.push(item)
                        return arr
                    }, [])
            },
            */
            async load() {
                try {
                    var { billing, state } = this
                    if (billing.loading)
                        return
                    billing.loading = true
                    var { household } = state
                    var contracts = [...await Household.contracts(household)]
                        .map(c => {
                            c.bill = false
                            return c
                        })
                    billing.contracts = [...contracts]
                    await Sys.wait(1e3)
                    billing.loading = false
                    //var contracts = [...await Household.contracts(household)]
                    /*
                    var query
                    for (var contract of contracts) {
                        var { _id: contractId } = contract
                        query = firebase.db
                            .collection('records')
                            .where('contractId', '==', contractId)
                        contract.records = firebase.docs(await query.get())
                            .sort((a, b) => +a.date - b.date)
                        contract.bill = false
                    }
                    */
                    /*
                    var _dates = contracts
                        .reduce((arr, c) => {
                            c.records.forEach((r) => {
                                if (arr.find(d => d == r.date))
                                    return
                                arr.push(+r.date)
                            })
                            return arr
                        }, [])
                        .sort((a, b) => a - b)
                    var dates = this.interpolate(_dates)
                        .map(date => moment(date, `YYYYMMDD`).format(`MMM DD, YYYY`))
                    billing.dates = [...dates]
                    billing.startDate = dates[0]
                    billing.endDate = dates[dates.length - 1]
                    */
                    //billing.householdContracts = [...contracts]
                    billing.ready = true
                    //this.calculate()
                } catch(e) {
                    console.log(e)
                }
            },
            async message(text = '', error = false) {
                var style = error ? Style.color('red') : Style.color('#2dbc4e')
                this.status = { text, style }
                if (!error)
                    return
                await Sys.wait(2500)
                this.message()
            },
            async save() {
            }
        },
        watch: {
            'billing.loading': {
                handler() {
                    if (this.billing.loading)
                        return
                    this.$nextTick(() => {
                        var el = document.getElementsByClassName(`scrollbar`)[0]
                        if (!el || this.scrollbar)
                            return
                        this.scrollbar = new Simplebar(el)
                    })
                }
            },
            'state.household': {
                handler() {
//                    this.billing.endDate = ''
                    this.load()
                }
            }
        }
    }
</script>