<template>
    <div class="h-100">
        <template v-if="table.loading">
            <loading-component></loading-component>
        </template>
        <template v-else>
            <scroll-area>
                <div class="m-3">
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th class="active"></th>
                                <th>
                                    <span>Date</span>
                                </th>
                                <th>
                                    <span>Reference</span>
                                </th>
                                <th>
                                    <span>Type</span>
                                </th>
                                <th>
                                    <span>Id</span>
                                </th>
                                <th>
                                    <span>Period</span>
                                </th>
                                <th>
                                    <span>Accounts</span>
                                </th>
                                <th>
                                    <span>Values</span>
                                </th>
                                <th>
                                    <span>Document</span>
                                </th>
                                <th>
                                    <span>Status</span>
                                </th>
                                <th>
                                    <i class="fas fa-redo" v-on:click="table.load()"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in table.items" :key="index">
                                <template v-if="index % 2 == 0">
                                    <td>
                                        <i class="fas toggle" :class="table.class(index)" v-on:click="table.toggle(index)"></i>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'date')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'reference')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'type')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'id')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'period')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'accounts')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'values')"></span>
                                    </td>
                                    <td>
                                        <img class="pdf" src="/img/pdf-icon.svg" v-on:click="table.open(item, 'pdf')">
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'status')"></span>
                                    </td>
                                    <td>
                                        <i class="far fa-trash-alt remove" v-on:click="table.remove(item, index)"></i>
                                    </td>
                                </template>
                                <template v-else-if="table.invoice(index)">
                                    <td></td>
                                    <td colspan="8">
                                        <invoice-details :invoice="table.invoice(index)"></invoice-details>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </scroll-area>
        </template>
    </div>
</template>

<style scoped>
    i.fas.fa-redo {
        cursor: pointer;
    }
    i.remove {
        cursor: pointer;
    }
    i.remove:hover {
        color: red;
    }
    img.pdf {
        width: 18px;
        cursor: pointer;
        margin-top: -5px;
    }
    div.label {
        padding-top: 2px;
    }
    button.btn-outline-danger {
        margin-left: 10px;
    }
    i.fas.toggle {
        cursor: pointer;
    }
    th.active  {
        width: 30px;
    }
    th.name {
        width: 25%;
    }
    th.summary {
        width: 55%;
    }
    i.fas.fa-caret-down {
        margin-left: -2px;
    }
</style>

<script>
    import { loading as loadingComponent, scrollArea } from '../../../component'
    import { Api, Format, lib, Invoice, Sys, Tab } from '../../../factory'
    import { moment, numeral } from '../../../npm'
    import { skivy71, smartkx } from '../../../npm.org'
    import { alert, events, firebase, session } from '../../../service'

    import invoiceDetails from './details'

    var { firestore } = smartkx
    var { utils } = skivy71

    export default {
        get components() {
            return {
                invoiceDetails,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                table: '',
                state: ''
            }
        },
        created() {
            var { invoiceEvent } = this
            this.events = { invoice: invoiceEvent.bind(this) }
            events.$watch(this.events)
            this.init()
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
        },
        methods: {
            invoiceEvent(e) {
                var [type] = Object.keys(e)
                switch(type) {
                    case `deploy`:
                    case `initialize`:
                    case `refresh`:
                        this.table.load()
                        break
                }
            },
            init() {
                var { string } = lib
                var state = session.get(`state`)
                this.state = state
                var table = {
                    _active: -1,
                    _data: [],
                    _date: (d) => moment(d, `L`).format(`MMM DD, YYYY`),
                    class(index) {
                        var fa = `fa-caret`
                        return this._active == index ? `${fa}-down` : `${fa}-right`
                    },
                    display(item, key) {
                        switch(key) {
                            case `accounts`:
                                return item.contract.accounts.length
                            case `id`:
                                return `...${item._id.slice(-6)}`
                            case `date`:
                                return this._date(item[key])
                            case `period`:
                                var start = this._date(item[key].start)
                                var end = this._date(item[key].end)
                                var { days } = item[key]
                                return `${start} - ${end} [${days} days]`
                            case `values`:
                                return item.period.values
                            case `reference`:
                                return item[key] || `None`
                            case `status`:
                                return smartkx.invoice.status[item[key]]
                            case `type`:
                                return utils.string.capitalise(item[key]) 
                            default:
                                return item[key] 
                        }
                    },
                    invoice(index) {
                        var active = index - 1
                        return this._active == active ? this.items[active] : ''
                    },
                    get items() {
                        return this._data
                            .reduce((arr, item) => {
                                arr.push(...[item, null])
                                return arr
                        }, [])
                    },
                    async load() {
                        if (this.loading)
                            return console.log(`already loading!`)
                        console.log('loading invoices...')
                        this.loading = true
                        var { household } = state
                        var docs = firestore.data(
                            await firestore
                                .collection(`invoices`)
                                .where(`householdId`, `==`, household._id)
                                .get(await firestore.token(firebase))
                        )
                        /*
                        var docs = firebase.docs(
                            await firebase.db
                                .collection(`invoices`)
                                .where(`householdId`, `==`, household._id)
                                .get()
                        )
                        */
                        async function getContract(invoice) {
                            var [contract] = firestore.data(
                                await firestore
                                    .collection(`contracts`)
                                    .doc(invoice.contractId)
                                    .get(await firestore.token(firebase))
                            )
                            /*
                            var [contract] = firebase.docs(
                                await firebase.db
                                    .collection(`contracts`)
                                    .where(firebase.documentId(), `==`, invoice.contractId)
                                    .get()
                            )
                            */
                            return contract
                        }
                        var contracts = await Promise.all(docs.map(invoice => getContract(invoice)))
                        var invoices = docs
                            .map(d => {
                                d.contract = contracts
                                    .find(c => c._id == d.contractId)
                                return d
                            })
                        this._active = -1
                        this._data = [...invoices]
                        this.loading = false
                    },
                    async open(invoice, key) {
                        switch(key) {
                            case `pdf`:
                                Invoice.open(invoice)
                                break
                            default:
                                throw new Error(`Invalid open key, ${key}!`)
                        }
                    },
                    async remove(invoice, idx, confirm) {
                        try {
                            if (invoice.status > 1 && !state.user.demo)
                                return alert.warning(`This invoice has been submitted and cannot be removed!`, 3e3)
                            if (!confirm) {
                                events.$emit(`confirm`, { operation: `invoice deletion`, prompt: `Are you really, really sure???`, data: [invoice, idx] })
                                events.$once(`confirmed`, ({ data, confirmed }) => {
                                    if (!confirmed)
                                        return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                                    this.remove(...data, true)
                                })
                            } else {
                                var { _id: managerId } = state.user
                                var { _id: invoiceId } = invoice
                                var data = { invoiceId, managerId }
                                var resp = await Api.post(`invoice/delete`, data)
                                alert.message(`Invoice has been deleted!`, 3e3)
                                await Sys.wait(1.5e3)
                                this.load()
                            }
                        } catch(e) {
                            console.log(e)
                            console.log(e.message)
                            alert.error(e.message)
                        }
                    },
                    toggle(index) {
                        this._active = this._active == index ? -1 : index
                    }
                }
                table.load()
                this.table = table
                this.state = state
            }
        },
        watch: {
            /*
            'table.loading': {
                handler() {
                    if (this.table.loading)
                        return
                    var el = document.getElementsByClassName(`scrollbar`)[0]
                    if (!el || this.scrollbar)
                        return
                    this.scrollbar = new Simplebar(el)
                }
            },
            */
            'state.household': {
                handler() {
                    this.$nextTick(() => {
                        this.table.load()
                    })
                }
            }
        }
    }
</script>