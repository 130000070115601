<template>
    <div class="h-100">
        <h5 class="bg-light p-3 border-bottom">Clients</h5>
        <div class="mb-2" style="height: 80vh">
            <template v-if="table.loading">
                <div class="h-100">
                    <loading-component></loading-component>
                </div>
            </template>
            <template v-else>
                <scroll-area>
                    <div class="my-2 mx-3">
                        <div class="row">
                            <div class="col buttons">
                                <div class="form form-inline float-right">
                                    <template v-if="!table.imported">
                                        <div class="file-upload">
                                            <input type="file" ref="upload" v-on:change="upload" accept=".csv">
                                        </div>
                                    </template>
                                    <template v-if="table.imported">
                                        <button type="button" class="btn btn-danger btn-sm mr-2" v-on:click="table.discard()">
                                            <i class="fas fa-times-circle"></i>
                                            <span>Discard</span>
                                        </button>
                                        <button type="button" class="btn btn-success btn-sm mr-2" v-on:click="table.save()" :disabled="table.pending">
                                            <template v-if="table.pending">
                                                <i class="fa fa-spinner fa-spin"></i>
                                            </template>
                                            <template v-else>
                                                <i class="fas fa-check-circle"></i>
                                            </template>
                                            <span>Save</span>
                                        </button>
                                    </template>
                                    <button class="btn btn-sm btn-info" v-on:click="table.import()" :disabled="table.disabled(`import`)">
                                        <i class="fas fa-file-import"></i>
                                        <span>Import</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th class="active"></th>
                                            <th>
                                                <span>Name</span>
                                            </th>
                                            <th>
                                                <span>Email</span>
                                            </th>
                                            <th>
                                                <span>Reference</span>
                                            </th>
                                            <th>
                                                <span>Created</span>
                                            </th>
                                            <th>
                                                <span>Updated</span>
                                            </th>
                                            <th>
                                                <i class="fas fa-redo" v-on:click="table.load()"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in table.items" :key="index">
                                            <template v-if="index % 2 == 0">
                                                <td>
                                                    <i class="fas toggle" :class="table.class(index)" v-on:click="table.toggle(index)"></i>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'name')"></span>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'email')"></span>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'ref')"></span>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'created')"></span>
                                                </td>
                                                <td>
                                                    <span v-text="table.display(item, 'updated')"></span>
                                                </td>
                                                <td>
                                                    <i class="far fa-trash-alt remove" v-on:click="table.remove(item, index)"></i>
                                                </td>
                                            </template>
                                            <template v-else-if="table.client(index)">
                                                <td></td>
                                                <td colspan="6">
                                                    <client-details :client="table.client(index)"></client-details>
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </scroll-area>
            </template>
        </div>
    </div>
</template>

<style scoped>
    td.docs img:last-child {
        margin-left: 25px;
    }
    i.fas.fa-redo {
        cursor: pointer;
    }
    i.remove {
        cursor: pointer;
    }
    i.remove:hover {
        color: red;
    }
    div.col-10 {
        text-align: right;
    }
    /*
    button {
        margin: 4px 0;
    }
    */
    i.click {
        cursor: pointer;
    }
    img.contract {
        width: 24px;
        cursor: pointer;
        margin-top: -5px;
    }
    /*
    button {
        margin-top: -5px;
    }
    button i {
        padding: 0 5px;
    }
    */
    i.fas.toggle {
        cursor: pointer;
    }
    th.active  {
        width: 30px;
    }
    th.name {
        width: 25%;
    }
    th.summary {
        width: 55%;
    }
    i.fas.fa-caret-down {
        margin-left: -2px;
    }
    div.file-upload input {
        display: none;
    }
    div.buttons button {
        width: 100px !important;
    }
    button i {
        margin-right: 5px;
    }
</style>

<script>
    import { loading as loadingComponent, scrollArea } from '../../component'
    import { Api, lib, Tab } from '../../factory'
    import { Household } from '../../model'
    import { axios, moment } from '../../npm'
    import { smartkx } from '../../npm.org'
    import { alert, events, firebase, session } from '../../service'

    import clientDetails from './details'

    var { firestore } = smartkx

    export default {
        get components() {
            return {
                clientDetails,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                table: '',
                state: ''
            }
        },
        created() {
            var { firmClients } = this
            this.events = { firmClients: firmClients.bind(this) }
            events.$watch(this.events)
            this.init()
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
        },
        methods: {
            firmClients(e) {
                var [type] = Object.keys(e)
                switch(type) {
                    case `saved`:
                        this.table.load()
                        break
                }
            },
            init() {
                var { string } = lib
                var state = session.get('state')
                var table = {
                    _active: -1,
                    _data: [], 
                    _date(d) {
                        var day = {
                            now: new Date(Date.now()).getDate(),
                            record: new Date(d).getDate() 
                        }
                        return day.now == day.record ? moment(d).format(this._timeFormat) : moment(d).format(this._dateFormat)
                    },
                    _timeFormat: `HH:mm:ss`,
                    _dateFormat: `MMM D, YYYY`,
                    class(index) {
                        var fa = `fa-caret`
                        return this._active == index ? `${fa}-down` : `${fa}-right`
                    },
                    client(index) {
                        var active = index - 1
                        var log = this._active == active ? this.items[active] : ''
                        return log 
                    },
                    disabled() {
                        return false
                    },
                    discard() {
                        this.imported = false
                        this.load()
                    },
                    display(item, key) {
                        var { string } = lib
                        switch(key) {
                            case `created`:
                            case `updated`:
                                return this._date(item[`_${key}`])
                            default:
                                return item[key]
                        }
                    },
                    import: () => {
                        this.$refs[`upload`].click()
                    },
                    get imported() {
                        return this._data.find(c => c.import)
                    },
                    item: ``,
                    get items() {
                        return this._data
                            .reduce((list, log) => {
                                list.push(...[log, null])
                                return list
                            }, [])
                    },
                    async load() {
                        if (this.loading)
                            return
                        this.loading = true
                        var data = firestore.data(
                            await firestore
                                .collection(`households`)
                                .where(`companyId`, `==`, state.company._id)
                                .get(await firestore.token(firebase))
                        )
                        var clients = data
                            .sort((a, b) => new Date(b._created).getTime() - new Date(a._created).getTime())
                        this._data = [...clients]
                        this.loading = false
                    },
                    loading: false,
                    pending: false,
                    async remove(client, i, confirm) {
                        try {
                            var { _data: clients } = this
                            var index = clients
                                .findIndex(c => c._id == client._id)
                            if (index < 0)
                                throw new Error(`Unable to find index for contract, ${c._id}!`)
                            var c = clients[index]
                            /*
                            if (c.status > 2)
                                return alert.warning(`This contract has been signed and cannot be removed!`, 3e3)
                            */
                            if (!confirm) {
                                events.$emit(`confirm`, { operation: `client deletion`, prompt: `Are you really, really sure???`, data: [client, i] })
                                events.$once(`confirmed`, ({ data, confirmed }) => {
                                    if (!confirmed)
                                        return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                                    this.remove(...data, true)
                                })
                            } else {
                                return alert.warning(`Not a good idea to remove clients at this stage!!!!`)
                                /*
                                var { _id: managerId } = state.user
                                var { _id: contractId } = contract
                                var data = { contractId, managerId }
                                try {
                                    var resp = await Api.post(`contract/delete`, data)
                                    alert.message(`Contract has been deleted!`, 3e3)
                                    await Sys.wait(1.5e3)
                                    this.load()
                                    console.log(resp.data)
                                    contracts.splice(index, 1)
                                } catch(e) {
                                    console.log(e)
                                    console.log(e.message)
                                    alert.error(e.message)
                                }
                                */
                            }
                        } catch(e) {
                            alert.error(e.message)
                        }
                    },
                    reset() {
                        this._active = -1
                        this.item = ``
                        return this
                    },
                    async save() {
                        console.log(`save???`)
                        var { _id: managerId } = state.user
                        if (this.pending)
                            return
                        var { _data: clients } = this
                        this.pending = true
                        var data = { managerId, clients }
                        try {
                            var resp = await Api.post(`client/import`, data)
                            console.log(resp.data)
                            var { message } = resp.data
                            alert.message(message, 3e3)
                        } catch(e) {
                            console.log(e.message)
                            alert.error(e.message)
                        } finally {
                            this.pending = false
                            this.load()
                        }
                    },
                    toggle(index) {
                        console.log(`toggle`, index)
                        this._active = this._active == index ? -1 : index
                        console.log(this._active)
                    },
                    upload(file) {
                        var File = `File '${file.name}'`
                        var reader = new FileReader()
                        reader.onloadend = (e) => {
                            var data = smartkx.household.data(reader.result)
                            var clients = data.clients
                                .map((c) => {
                                    c.import = true
                                    var a = data.addresses
                                        .find(a => a.client == c.ref)
                                    if (a)
                                        c.address = { ...a }
                                    return c
                                })
                            this._data = [...clients]
                            this._active = -1
                        }
                        reader.onerror = (e) => {
                            alert.error(e.message || `File upload failed!`)
                        }
                        reader.readAsText(file)
                    }
                }
                table.load()
                this.state = state
                this.table = table
            },
            upload(e) {
                var [file] = this.$refs.upload.files
                this.table
                    .upload(file)
            }
        },
        watch: {
            /*
            'state.household': {
                handler() {
                    this.table
                        .reset()
                        .load()
                }
            }
            */
        }
    }
</script>