<template>
    <nav class="nav nav-tabs">
        <li v-for="(item, index) in list" :key="index" class="nav-item">
            <a class="nav-link" :class="item.class" href="#" v-on:click="select(index)">
                <span v-text="item.label"></span>
            </a>
        </li>
    </nav>
</template>

<style scoped>

</style>

<script>
    import { lib } from '../factory'

    export default {
        props: ['tabs'],
        data() {
            return {
            }
        },
        created() {
            this.init()
        },
        computed: {
            list() {
                var { string } = lib
                var { index, labels, list } = this.tabs
                return list
                    .map((name, idx) => {
                        var c = idx == index ? 'active' : ''
                        var label = labels[name] || string.capitalise(name)
                        return { class: c, label, name }
                    })
            }
        },
        methods: {
            init() {
                var { tabs } = this
                if (!tabs.hasOwnProperty('index'))
                    this.$set(tabs, 'index', 0)
            },
            select(index) {
                var { tabs } = this
                tabs.index = index
            }
        }
    }
</script>