<template>
    <scroll-area>
        <div class="m-3">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>
                            <span>Household</span>
                        </th>
                        <th>
                            <span>Account Name</span>
                        </th>
                        <th>
                            <span>Account Number</span>
                        </th>
                        <th>
                            <span>Bill To</span>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="a in table.accounts" :key="a.number">
                        <td>
                            <span v-text="a.household"></span>
                        </td>
                        <td>
                            <span v-text="a.name"></span>
                        </td>
                        <td>
                            <span v-text="a.number"></span>
                        </td>
                        <td>
                            <select class="form-control form-control-sm" v-model="a.billTo" v-on:change="table.change(`billTo`, a)">
                                <option v-for="billTo in table.billing(a)" :key="billTo">
                                    <span v-text="billTo"></span>
                                </option>
                            </select>
                        </td>
                        <td>
                            <button class="btn btn-sm btn-outline-success" v-on:click="table.billTo(a)">
                                <span>Bill To</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </scroll-area>
</template>

<style scoped>
    div.schedule, div.accounts {
        padding: 10px 10px 0 10px;
    }
    hr {
        margin-top: 0;
        opacity: 0.6;
    }
    p.none {
        text-align: center;
    }
</style>

<script>
    import { scrollArea } from '../../../component'
    import { Contract, Format, lib } from '../../../factory'
    import { events, session } from '../../../service'
    import { moment, numeral } from '../../../npm'

    export default {
        get components() {
            return {
                scrollArea
            }
        },
        data() {
            return {
                state: '',
                table: ''
            }
        },
        created() {
            this.init()
        },
        mounted() {
        },
        computed: {
            contract() {
                var { state } = this
                var { company, contracts, household } = state
                var { _id: householdId } = household
                var contract = state.contracts
                    .find(c => c.householdId == householdId)
                if (!contract)
                    throw new Error(`Unable to find contract in state, should already be there!`)
                return contract
            }
        },
        methods: {
            init() {
                this.state = session.get(`state`)
                var { contract } = this
                this.table = {
                    accounts: [],
                    billing(account) {
                        return this.accounts
                            .map(a => a.number == account.number ? `Self` : a.number)
                    },
                    billTo(account) {
                        this.accounts
                            .forEach((a) => {
                                a.billTo = a.number == account.number ? `Self` : account.number
                                this.change(`billTo`, a)
                            })
                    },
                    change(type, account) {
                        switch(type) {
                            case `billTo`:
                                var a = contract.households
                                    .filter(h => h != null)
                                    .find(h => h.accounts.find(a => a.number == account.number))
                                    .accounts
                                    .find(a => a.number == account.number)
                                a.billTo = account.billTo
                                break;
                            default:
                        }
                    } 
                }
                this.load()
            },
            load() {
                var { contract, table } = this
                var data = session.get(`data`)
                var accounts = contract.households
                    .filter(h => h != null)
                    .reduce((arr, h) => {
                        var household = data.households
                            .find(household => household.ref == h.ref)
                        h.accounts
                            .filter(a => a.select)
                            .forEach((a) => {
                                if (!a.billTo)
                                    a.billTo = `Self`
                                var { name, number, billTo } = a
                                var account = { household: household.name, name, number, billTo }
                                arr.push(account)
                            })
                        return arr
                    }, [])
                table.accounts = [...accounts]
            }
        }
    }
</script>