export default (() => {

    class Style {

        static color(c) {
            return `color: ${c}`
        }
    }

    return Style

})()