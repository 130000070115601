<template>
    <div class="h-100">
        <h5 class="bg-light p-3 border-bottom">
            <span>Accounts</span>
            <i class="fab fa-youtube video" v-on:click="nav.video()"></i>
        </h5>
        <div class="mb-2" style="height: 80vh">
            <accounts-list></accounts-list>
            <!--
            <div class="tab-list mx-3 pt-3">
                <ul class="nav nav-tabs">
                    <li class="nav-item" v-for="(tab, index) in nav.tabs" :key="index">
                        <a class="nav-link" v-on:click="nav.select(index)" :class="nav.class(index)" :style="nav.style(index)">
                            <span v-text="tab"></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-components pt-2">
                <component :is="nav.component"></component>
            </div>
            -->
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    import moment from 'moment'
    import accountsList from './list'
    import { lib } from '../../factory'
    import { Household } from '../../model'
    import { alert, events, firebase, session } from '../../service'

    export default {
        get components() {
            return {
                accountsList
            }
        },
        data() {
            return {
                nav: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
        },
        methods: {
            init() {
                var { string } = lib
                var nav = {
                    _components: ['accounts-list'],
                    _index: 0,
                    get component() {
                        return this._components[this._index]
                    },
                    class(index) {
                        return index == this._index ? 'active' : '' 
                    },
                    select(index) {
                        this._index = index
                    },
                    style(index) {
                        var [cursor, color] = this._index == index ? ['default', 'inherit'] : ['pointer', '#007bff']
                        return [`cursor: ${cursor}`, `color: ${color}`]
                            .join(';')
                    },
                    get tabs() {
                        return this._components
                            .map((c) => {
                                switch(c) {
                                    case `accounts-list`:
                                        return `Accounts`
                                    default:
                                        return c.split(`-`).map(s => string.capitalise(s)).join(` `)
                                }
                            })
                    },
                    async video() {
                        try {
                            var src = await firebase.storageURL(`video/accounts.mp4`)
                            events.$emit('video', { src, type: `video/mp4` })
                        } catch(e) {
                            console.log(e)
                            alert.error(e.message)
                        }
                    }
                }
                this.nav = nav
            }
        }
    }
</script>