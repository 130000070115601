import { Tab } from '../factory'
import { alert, firebase } from '../service'

class Invoice {

    static async open(invoice) {
        try {
            var tab = Tab.open()
            tab.document.open()
            tab.document.write(await firebase.download(invoice.document))
            tab.document.close()
        } catch(e) {
            console.log(e)
            alert.error(e.message)
        }
    }

}

export default Invoice