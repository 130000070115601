<template>
    <scroll-area>
        <div class="m-3">
            <template v-if="table.ready && table.data.length">
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th class="active"></th>
                            <th class="name">
                                <span>Name</span>
                            </th>
                            <th>
                                <span>Minimum</span>
                            </th>
                            <th class="summary">
                                <span>Summary</span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(schedule, index) in table.data" :key="index">
                            <template v-if="index % 2 == 0">
                                <td>
                                    <i class="fas toggle" :class="table.class(index)" v-on:click="table.toggle(index)"></i>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" v-model="schedule.name" v-on:change="table.change(`name`, schedule)">
                                </td>
                                <td>
                                    <div class="input-group input-group-sm mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">$</div>
                                        </div>
                                        <input class="form-control form-control-sm" v-model="schedule.minimum" placeholder="minimum" v-on:focus="table.focus(schedule, 'minimum')" v-on:blur="table.blur(schedule, 'minimum')">
                                    </div>
                                    <small class="form-text text-muted">Minimum annual fee</small>
                                </td>
                                <td>
                                    <span v-text="schedule.summary"></span>
                                </td>
                                <td>
                                    <i class="far fa-trash-alt remove" v-on:click="table.remove(index)"></i>
                                </td>
                            </template>
                            <template v-else-if="table.schedule(index)">
                                <td></td>
                                <td colspan="5">
                                    <contract-schedule :schedule="table.schedule(index)"></contract-schedule>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-if="!table.expanded">
                <template v-if="select.schedules.length">
                    <table class="schedule-select">
                        <tr>
                            <td>
                                <span>Schedule</span>
                            </td>
                            <td>
                                <select class="form-control form-control-sm" v-model="select.schedule">
                                    <option v-for="schedule in select.schedules" :key="schedule.name">
                                        <span v-text="schedule.name"></span>
                                    </option>
                                </select>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-outline-success" v-on:click="table.add(select.schedule)">
                                    <span>Add</span>
                                </button>
                            </td>
                        </tr>
                    </table>
                </template>
                <template v-else>
                    <button class="btn btn-sm btn-outline-primary" v-on:click="table.add()">
                        <span>Add</span>
                    </button>
                </template>
            </template>
        </div>
    </scroll-area>
</template>

<style scoped>
    small.text-muted {
        margin-top: -0.5em;
    }
    table.schedule-select td:first-child {
        vertical-align: middle;
    }
    table.schedule-select td {
        padding: 0 5px;
    }
    i.remove {
        cursor: pointer;
    }
    i.remove:hover {
        color: red;
    }
    div.label {
        padding-top: 2px;
    }
    button.btn-outline-danger {
        margin-left: 10px;
    }
    i.fas.toggle {
        cursor: pointer;
    }
    th.active  {
        width: 30px;
    }
    th.name {
        width: 25%;
    }
    th.summary {
        width: 55%;
    }
    i.fas.fa-caret-down {
        margin-left: -2px;
    }
</style>

<script>
    import { scrollArea } from '../../../../component'
    import { Format, lib } from '../../../../factory'
    import contractSchedule from './schedule'
    import { alert, session } from '../../../../service'
    import { numeral } from '../../../../npm'

    export default {
        get components() {
            return {
                contractSchedule,
                scrollArea
            }
        },
        data() {
            return {
                select: '',
                table: ''
            }
        },
        created() {
            this.init()
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            init() {
                var { string } = lib
                var state = session.get('state')
                var initContract = (contract, schedules = []) => {
                    this.$set(contract, `schedules`, schedules)
                }
                var select = {
                    schedule: ``,
                    get schedules() {
                        return state.company.schedules
                            //.map(s => s.name)
                    }
                }
                select.schedule = select.schedules[0].name
                this.select = select
                var table = {
                    _active: -1,
                    _schedule(s) {
                        var { name, minimum } = s
                        var breaks = s.breaks.slice()
                        var rates = s.rates.slice()
                        return {
                            name,
                            breaks,
                            rates,
                            minimum,
                            get summary() {
                                var { breaks, rates, minimum } = this
                                var currency = (v) => numeral(v).format('0[.]0a').toUpperCase()
                                var range = (b, i) => i < breaks.length - 1 ? `$${currency(b)} - ${currency(breaks[i + 1])}` : `$${currency(b)} +`
                                var rate = (i) => `${numeral(rates[i]).format('0.[00]')}%` 
                                return breaks
                                    .map((b, i) => `${range(b, i)} @ ${rate(i)}`)
                                    .join(`, `)
                            }
                        }
                    },
                    add(schedule) {
                        if (schedule) {
                            console.log(`adding from list?`)
                            console.log(s)
                            var s = state.company.schedules
                                .find(s => s.name == schedule)
                            this.contract.schedules
                                .push(this._schedule(s))
                            return
                        }
                        var { schedules } = this.contract
                        var name = schedules.length ? String.fromCharCode(schedules.length + 64) : 'Default'
                        var breaks = [0]
                        var rates = [numeral(1).format('0.00')]
                        var minimum = numeral(0).format(`0,0`)
                        var schedule = { name, breaks, rates, minimum }
                        schedules.push(this._schedule(schedule))
                    },
                    blur(o, field) {
                        switch(field) {
                            case 'minimum':
                                o[field] = numeral(o[field]).format(`0,0`)
                                break
                            default:
                                throw new Error(`Invalid table focus field, ${field}!`)
                        }
                    },
                    change(type, s) {
                        switch(type) {
                            case `name`:
                                var { households = [], schedules } = this.contract
                                households
                                    .filter(h => h != null)
                                    .forEach((h) => {
                                        h.accounts
                                            .forEach((a) => {
                                                if (!schedules.find(s => s.name == a.scheduleName))
                                                    a.scheduleName = schedules[0].name
                                            })
                                    })
                                break;
                            default:
                        }
                    },
                    class(index) {
                        var fa = `fa-caret`
                        return this._active == index ? `${fa}-down` : `${fa}-right`
                    },
                    get contract() {
                        var { company, contracts, household } = state
                        var { _id: householdId } = household
                        var contract = state.contracts
                            .find(c => c.householdId == householdId)
                        if (!contract) {
                            contract = { householdId }
                            state.contracts.push(contract)
                        }
                        if (!contract.hasOwnProperty(`schedules`))
                            initContract(contract, [])
                        /* {
                            var schedules = company.schedules
                                .map(s => this._schedule(s))
                            initContract(contract, schedules)
                        } */
                        return contract
                    }, 
                    get data() {
                        var accounts = state.household.accounts || []
                        return this.contract.schedules
                            .reduce((list, s) => {
                                s.accounts = accounts
                                    .filter(a => a.schedule == s.name)
                                    .length
                                list.push(...[s, null])
                                return list
                            }, [])
                    },
                    disabled(index) {
                        return this.data.length <= 2
                    },
                    get expanded() {
                        return this._active >= 0
                    },
                    focus(o, field) {
                        switch(field) {
                            case `minimum`:
                                o[field] = numeral(o[field]).value()
                                break
                            default:
                                throw new Error(`Invalid table focus field, ${field}!`)
                        }
                    },
                    init() {
                        if (!this.contract.schedules.length)
                            this.add(select.schedule)
                        return this
                    },
                    label(field) {
                        return field == `active` || field == `remove` ? `` : string.capitalise(field)
                    },
                    ready: true,
                    remove(idx) {
                        var { contract } = this
                        var index = idx / 2
                        var schedule = contract.schedules[index]
                        console.log('removing', schedule.name)
                        if (contract.households && contract.households.filter(h => h != null).find(h => h.accounts.find(a => a.select && a.scheduleName == schedule.name)))
                            return alert.error(`This schedule is referenced in one or more contract account(s) and cannot be removed!`)
                        //if (!index && contract.schedules.length == 1)
                          //  return alert.error(`Contract must have at least 1 schedule!`) 
                        contract.schedules
                            .splice(index, 1)
                    },
                    schedule(index) {
                        var active = index - 1
                        var schedule = this._active == active ? this.data[active] : ''
                        return schedule 
                    },
                    toggle(index) {
                        this._active = this._active == index ? -1 : index
                    }
                }
                this.table = table.init()
                this.state = state
            }
        },
        watch: {
            'state.household': {
                handler() {
                    var { table } = this
                    table.ready = false
                    this.$nextTick(() => {
                        table.ready = true
                    })
                }
            }
        }
    }
</script>