<template>
    <div class="root">
        <div class="list-group list-group-flush border-top border-bottom">
            <a v-for="(item, index) in menu.items" :key="index"
                class="list-group-item list-group-item-action"
                :class="item.class"
                :style="item.style"
                v-on:click="menu.select(index)">
                    <span v-text="item.label"></span>
            </a>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import { lib } from '../factory'
    import { events } from '../service'

    export default {
        props: ['sidebar'],
        data() {
            return {
            }
        },
        created() {
            var { householdMenu } = this
            this.events = {}
            this.events[`household-menu`] = householdMenu.bind(this)
            events.$watch(this.events)
            this.init()
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
            menu() {
                var { menu } = this.sidebar
                return menu
            }
        },
        methods: {
            init() {
                var { string } = lib
                var { sidebar } = this
                var menu = {
                    _index: 0,
                    get index() {
                        var { _index } = this
                        return _index
                    },
                    get items() {
                        var { _index } = this
                        return sidebar.views
                            .map((view, index) => {
                                var c = index == _index ? `active` : `bg-light`
                                var label = string.capitalise(view)
                                var style = index == _index ? `color: white` : `color: inherit; cursor: pointer` 
                                return { class: c, label, view, style }
                            })
                    },
                    select (index) {
                        this._index = index
                    }
                }
                this.$set(sidebar, 'menu', menu)
            },
            householdMenu(e) {
                var { sidebar } = this
                var [view] = Object.keys(e)
                var data = e[view]
                var index = sidebar.views
                    .findIndex(v => v == view)
                if (index < 0)
                    throw new Error(`Unable to find view, ${view}!`)
                sidebar.menu.select(index)
                this.$nextTick(() => {
                    events.$emit(`household-audit`, data)
                })
            }
        }   
    }
</script>