<template>
    <div class="input-group">
        <input class="form-control form-control-sm" type="text" :id="id" readonly placholder="Date" ref="date" :style="style">
        <div class="input-group-append">
            <i class="input-group-text far fa-calendar-alt" v-on:click="focus"></i>
        </div>
    </div>
</template>

<style scoped>
    div input {
        background-color: transparent !important;
    }
    div i {
        cursor: pointer;
    }
</style>

<script>
    import { moment, Pikaday } from '../npm'
    import { alert } from '../service'
    import '../../../node_modules/pikaday/css/pikaday.css'

    export default {
        props: ['obj', 'prop', 'options'],
        data() {
            return {
                picker: ''
            }
        },
        computed: {
            dateOptions() {
                var { options = {} } = this
                var { format = `MMM D, YYYY` } = options
                var ignore = [`format`]
                return Object.keys(options)
                    .filter(key => ignore.indexOf(key) < 0)
                    .reduce((o, key) => {
                        switch(key) {
                            case `minDate`:
                            case `maxDate`:
                                o[key] = moment(options[key], format)
                                    .toDate() 
                                break;
                            case `events`:
                                o[key] = options[key]
                                    .map(d => moment(d, format).format(`ddd MMM D YYYY`))
                                break
                            case `disable`:
                                break
                            default:
                                o[key] = Array.isArray(options[key]) ? [...options[key]] : (typeof options[key] == `object` ? { ...options[key] } : options[key])
                        }
                        return o
                    }, { format })
            },
            id() {
                return this._uid
            },
            style() {
                var { options = {} } = this 
                var { width = `130px` } = options
                return `width: ${width}`
            }
        },
        created() {
        },
        mounted() {
            var { id, dateOptions, select } = this
            var field = document.getElementById(id)
            var picker = new Pikaday({ field, onSelect: select.bind(this), ...dateOptions })
            var date = this.obj[this.prop] || moment().format(dateOptions.format)
            picker.setDate(date)
            this.picker = picker
        },
        destroyed() {
            this.picker.destroy()
        },
        methods: {
            focus() {
                this.$refs['date'].click()
            },
            select(e) {
                var { dateOptions, prop, options } = this
                if (typeof options == `object` && options.disable)
                    return alert.warning(`Date selection is disabled!`)
                var date = moment(e).format(dateOptions.format)
                this.obj[prop] = date
            }
        },
        watch: {
            'obj': {
                handler() {
                    var { obj, prop } = this
                    var date = this.obj[this.prop]
                    this.picker.setDate(date)
                },
                deep: true
            },
            'options': {
                handler() {
                    console.log('options updated?')
                    console.log(this.options)
                },
                deep: true
            }
        }
    }
</script>