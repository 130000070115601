import { lib, Model } from '../factory'
import { smartkx } from '../npm.org'
import { blockchain, firebase } from '../service'

var { firestore } = smartkx

var Quarter = { contractAddress: '', id: '' }

var required = ['companyId']

var defaults = {
	email() {
		return ''
	},
	reference() {
		return ''
	},
	name() {
		return ''
	},
	contracts() {
		return []
	},
	managers() {
		return []
	}
}

class Household extends Model {

	constructor(data) {
		data.Quarter = Quarter
		super({ data, defaults, required })
	}

	calculate(year, quarter) {
		return new Promise((resolve, reject) => {
			var contract = blockchain.contract('calc', address)
			contract.calculate(year, quarter, options, (err, result) => {
				if (err)
					return reject(err)
				resolve(Household.parse({ calculate: result }))
			})
		})
	}

	contract() {
		var { contracts } = this
		return contracts
			.find(c => c.active)
	}

	static company(company) {
		var { string } = lib
		return company ? company.name
			.split(' ')
			.map(s => s == 'llc' ? 'LLC' : string.capitalise(s))
			.join(' ') : ''
	}

	static contract(contract, schedule, quarters) {
		contract.schedule = schedule
		contract.quarters = quarters
		contract.start = quarters.length ? quarters[0].id : null
		contract.end = null
		return contract
	}

	static async accounts(household) {
		return firestore.data(
			await firestore
				.collection(`accounts`)
				.where(`householdId`, `==`, household._id)
				.get(await firestore.token(firebase))
		)
		/*
		return new Promise(async (resolve, reject) => {
			try {
				var accounts = firebase.docs(
					await firebase.db
						.collection(`accounts`)
						.where(`householdId`, `==`, household._id)
						.get()
				)
				resolve(accounts)
			} catch(e) {
				reject(e)
			}
		})
		*/
	}

	static async contracts(household) {
		return firestore.data(
			await firestore
				.collection(`contracts`)
				.where(`householdId`, `==`, household._id)
				.get(await firestore.token(firebase))
		)
		/*
		return new Promise(async (resolve, reject) => {
			try {
				var contracts = firebase.docs(
					await firebase.db
						.collection(`contracts`)
						.where(`householdId`, `==`, household._id)
						.get()
				)
				resolve(contracts)
			} catch(e) {
				reject(e)
			}
		})
		*/
	}

}

export default Household