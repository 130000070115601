<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Smart Contract Values</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>
                <em class="font-italic" v-text="account.title"></em>
            <p>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label class="label">Start</label>
                        <date-picker :obj="account.date" prop="start" :options="account.date.options"></date-picker>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label>End</label>
                        <date-picker :obj="account.date" prop="end" :options="account.date.options"></date-picker>
                    </div>
                </div>
                <div class="col-3">
                    <label>Inclusive</label>
                    <div>
                        <select class="form-control form-control-sm" v-model="account.date.inclusive">
                            <option>Yes</option>
                            <option>No</option>
                        </select>
                    </div>
                </div>
                <div class="col-3">
                    <label class="d-block">Query</label>
                    <button class="btn btn-sm btn-success" v-on:click="account.load()" :disabled="account.pending">
                        <template v-if="account.loading">
                            <i class="fa fa-spinner fa-spin"></i>
                        </template>
                        <template v-else>
                            <i class="fas fa-play-circle"></i>
                        </template>
                        <span>Execute</span>
                    </button>
                </div>
            </div>
            <template v-if="account.loaded">
                <em class="font-italic">Values</em>
                <template v-if="account.values.length">
                    <hr class="mt-2 mb-1">
                    <div class="values">
                        <scroll-area>
                            <table class="table table-sm table-borderless">
                                <thead>
                                    <tr>
                                        <th>
                                            <span>Date</span>
                                        </th>
                                        <th>
                                            <span>Value</span>
                                        </th>
                                        <th>
                                            <span>Average</span>
                                        </th>
                                        <th>
                                            <span>Transaction</span>
                                        </th>
                                        <!--
                                        <th>
                                            <span>Created</span>
                                        </th>
                                        -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in account.values" :key="item.date">
                                        <td>
                                            <span v-text="item.date"></span>
                                        </td>
                                        <td>
                                            <span v-text="item.value"></span>
                                        </td>
                                        <td>
                                            <span v-text="item.average"></span>
                                        </td>
                                        <td>
                                            <a href="#" v-on:click="account.open(item, 'hash')">
                                                <span v-text="account.display(item, 'hash')"></span>
                                            </a>
                                        </td>
                                        <!--
                                        <td>
                                            <span v-text="item.created"></span>
                                        </td>
                                        -->
                                    </tr>
                                </tbody>
                            </table>
                        </scroll-area>
                    </div>
                </template>
                <template v-else>
                    <p class="message">None found!</p>
                </template>
            </template>
        </div>
    </div>
</template>

<style scoped>
    p.message {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        opacity: 0.3;
    }
</style>

<script>
    import { datePicker, loading as loadingComponent, scrollArea } from '../../component'
    import { Api, Contract, Sys, Tab } from '../../factory'
    import { moment, numeral } from '../../npm'
    import { smartkx } from '../../npm.org'
    import { firebase, session } from '../../service'

    export default {
        props: ['data'],
        get components() {
            return {
                datePicker,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                account: ``
            }
        },
        mounted() {
        },
        created() {
            this.init()
        },
        destroyed() {
        },
        computed: {
        },
        methods: {
            init() {
                var state = session.get(`state`)
                var { data: props } = this
                var account = {
                    _date(d, reverse) {
                        return reverse
                            ? moment(d, this.date.options.format).format(this.date.format)
                            : moment(d, this.date.format).format(this.date.options.format)
                    },
                    date: {
                        start: ``,
                        end: ``,
                        format: `YYYYMMDD`,
                        options: {
                            format: `MMM D, YYYY`,
                            minDate: ``,
                            maxDate: ``
                        },
                        inclusive: `Yes`
                    },
                    display(item, key) {
                        var v = item[key]
                        switch(key) {
                            case `hash`:
                                var len = 10
                                return `${v.slice(0, len)}...${v.slice(-len)}`
                            default:
                                throw new Error(`Invalid display key, ${key}!`)
                        }
                    },
                    init() {
                        var records = props.records
                            .sort((a, b) => moment(b.date, this.date.format).format(`x`) - moment(a.date, this.date.format).format(`x`))
                        var { date: maxDate } = records[records.length -1]
                        var { date: minDate } = records[0]
                        this.date.options.maxDate = this._date(maxDate) 
                        this.date.options.minDate = this._date(minDate)
                        return this
                    },
                    async load() {
                        if (this.loading)
                            return
                        this.loading = true
                        this.pending = true
                        var [start, end] = [`start`, `end`]
                            .map(key => this._date(this.date[key], true))
                        var inclusive = this.date.inclusive.toLowerCase() == `yes`
                        var period  = { start, end, inclusive }
                        var { account, contract } = props
                        var { _id: contractId } = contract
                        var { _id: managerId } = state.user
                        var { data } = await Api.post(`account/values`, { contractId, managerId, account, period })
                        var { average } = data
                        var values = data.values
                            .map((value, i) => {
                                var d = data.dates[i]
                                var date = this._date(d)
                                var record = props.records
                                    .find(r => r.date == d)
                                var { _created, txHash: hash } = record
                                var created = moment(_created).format(this.date.options.format)
                                return { average, created, date, hash, value }
                            })
                            .sort((a, b) => this._date(b.date, true) - this._date(a.date, true))
                        this.values = [...values]
                        if (!this.loaded)
                            this.loaded = true
                        this.loading = false
                        await Sys.wait(0.5e3)
                        this.pending = false
                    },
                    loaded: false,
                    loading: false,
                    get name() {
                        return props.account.name
                    },
                    get number() {
                        return props.account.number
                    },
                    open(v) {
                        var { contract } = props
                        Tab.open(smartkx.etherscan.transactionURL(v.hash, contract.network))
                    },
                    pending: false,
                    get ready() {
                        return this._records.length
                    },
                    get title() {
                        var { name, number } = this
                        return `Account: ${number} / ${name}`
                    },
                    values: []
                }
                this.account = account.init()
            }
        },
        watch: {
        }
    }
</script>