<template>
    <div class="h-100">
        <h5 class="bg-light p-3 border-bottom">Accounts</h5>
        <div class="mb-2" style="height: 80vh">
            <div class="my-2 mx-3 h-100">
                <div class="row pt-2">
                    <div class="col-6">
                        <template v-if="table.households.length">
                            <div class="form form-inline">
                                <label class="mr-2">Household</label>
                                <select class="form-control form-control-sm" v-model="table.household" v-on:change="table.select('household')" ref="household">
                                    <option v-for="household in table.households" :key="household.ref">
                                        <span v-text="household.name"></span>
                                    </option>
                                </select>
                            </div>
                        </template>
                    </div>
                    <div class="col buttons">
                        <div class="form form-inline float-right">
                            <template v-if="!table.imported">
                                <div class="file-upload">
                                    <input type="file" ref="upload" v-on:change="upload" accept=".csv">
                                </div>
                            </template>
                            <template v-if="table.imported">
                                <button type="button" class="btn btn-danger btn-sm mr-2" v-on:click="table.discard()">
                                    <i class="fas fa-times-circle"></i>
                                    <span>Discard</span>
                                </button>
                                <button type="button" class="btn btn-success btn-sm mr-2" v-on:click="table.save()" :disabled="table.pending">
                                    <template v-if="table.pending">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-check-circle"></i>
                                    </template>
                                    <span>Save</span>
                                </button>
                            </template>
                            <button class="btn btn-sm btn-info" v-on:click="table.import()" :disabled="table.disabled(`import`)">
                                <i class="fas fa-file-import"></i>
                                <span>Import</span>
                            </button>
                        </div>
                    </div>
                </div>
                <template v-if="table.loading || !table.households.length">
                    <div class="h-100">
                        <loading-component></loading-component>
                    </div>
                </template>
                <template v-else>
                    <scroll-area>
                        <div class="row accounts mt-3">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th class="active"></th>
                                        <th>
                                            <span>Number</span>
                                        </th>
                                        <th>
                                            <span>Name</span>
                                        </th>
                                        <th>
                                            <span>Custodian</span>
                                        </th>
                                        <th>
                                            <span>Created</span>
                                        </th>
                                        <th>
                                            <span>Updated</span>
                                        </th>
                                        <th>
                                            <span>Import</span>
                                        </th>
                                        <th>
                                            <i class="fas fa-redo" v-on:click="table.load()"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in table.items" :key="index">
                                        <template v-if="index % 2 == 0">
                                            <td>
                                                <i class="fas toggle" :class="table.class('caret', index)" v-on:click="table.toggle(index)"></i>
                                            </td>
                                            <td>
                                                <span v-text="table.display(item, 'number')"></span>
                                            </td>
                                            <td>
                                                <span v-text="table.display(item, 'name')"></span>
                                            </td>
                                            <td>
                                                <span v-text="table.display(item, 'custodian')"></span>
                                            </td>
                                            <td>
                                                <span v-text="table.display(item, 'created')"></span>
                                            </td>
                                            <td>
                                                <span v-text="table.display(item, 'updated')"></span>
                                            </td>
                                            <td>
                                                <i class="far" :class="item.import ? `fa-check-square` : `fa-square`">
                                            </td>
                                            <td>
                                                <i class="far fa-trash-alt remove" v-on:click="table.remove(item, index)"></i>
                                            </td>
                                        </template>
                                        <template v-else-if="table.account(index)">
                                            <td></td>
                                            <td colspan="7">
                                                <account-detail :account="table.account(index)" :imported="table.imported"></account-detail>
                                            </td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </scroll-area>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    img.contract {
        width: 22px;
        cursor: pointer;
        margin: -5px 0 0 -2px;
    }
    i.fas.fa-redo {
        cursor: pointer;
    }
    div.buttons button {
        width: 100px !important;
    }
    div.file-upload input {
        display: none;
    }
    div.date {
        margin: 5px 0 0 20px;
    }
    div.date label {
        margin-right: 10px;
    }
    div.accounts {
        margin: 10px 0;
    }
    /*
    div.accounts tbody i {
        margin: 0 10px;
    }
    */
    i.toggle {
        cursor: pointer;
    }
    p.securities {
        margin-top: 5px;
        font-weight: bold;
        opacity: 0.5;
    }
    table.securities {
        margin: 10px 0;
    }
    th.active  {
        width: 30px;
    }
    button i {
        margin-right: 5px;
    }
    i.remove {
        cursor: pointer;
    }
    i.remove:hover {
        color: red;
    }
</style>

<script>
    import { loading as loadingComponent, scrollArea } from '../../component'
    import { Api, Format, lib, Sys } from '../../factory'
    import { axios, moment, numeral } from '../../npm'
    import { smartkx, skivy71 } from '../../npm.org'
    import { alert, events, firebase, session } from '../../service'

    import accountDetail from './detail'

    var { firestore } = smartkx

    export default {
        get components() {
            return {
                accountDetail,
//                datePicker,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                state: '',
                select: '',
                table: ''
            }
        },
        created() {
            var { firmAccounts } = this
            this.events = { firmAccounts: firmAccounts.bind(this) }
            events.$watch(this.events)
            this.init()
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
        },
        methods: {
            firmAccounts(e) {
                var [type] = Object.keys(e)
                switch(type) {
                    case `refresh`:
                        this.table.load()
                        break
                }
            },
            init() {
                var { string } = lib
                var state = session.get('state')
                var table = {
                    _active: -1,
                    _contract: ``,
                    _data: { households: [], accounts: [] },
                    _date(d, reverse) {
                        return reverse
                            ? moment(d, this.date.options.format).format(this.date.format)
                            : moment(d, this.date.format).format(this.date.options.format)
                    },
                    async _records(token) {
                        return firestore.data(
                            await firestore
                                .collection(`records`)
                                .where(`contractId`, `==`, this._contract._id)
                                .get(token)
                        )
                    },
                    _securities(r, a) {
                        return r.securities // ternary can be removed once old API records are removed!
                            ? r.securities.filter(s => s.account == a.number)
                            : a.securities
                    },
                    account(index) {
                        var active = index - 1
                        return this._active == active ? this.items[active] : ''
                    },
                    class(index) {
                        var fa = `fa-caret`
                        return this._active == index ? `${fa}-down` : `${fa}-right`
                    },
                    /*
                    get data() {
                        var date = this._date(this.date.value, true)
                        var record = this._data
                            .find(r => r.date == date)
                        var accounts = record ? this._accounts(record) : []
                        return accounts
                            .reduce((l, a) => {
                                l.push(...[a, null])
                                return l
                            }, [])
                    },
                    */
                    date: {
                        format: `YYYYMMDD`,
                        options: { format: `MMM D, YYYY`, events: [], width: `130px` },
                        value: ''
                    },
                    get dates() {
                        var dates = this._data
                            .map(r => this._date(r.date))
                        this.date.options.events = [...dates]
                        return dates
                    },
                    disabled(type) {
                        switch(type) {
                            case `import`:
                                return this.imported
                                //return !this._contract || this.imported ? true : false
                            default:
                                throw new Error(`Invalid disabled type, ${type}!`)
                        }
                    },
                    discard(confirm) {
                        if (!confirm) {
                            events.$emit(`confirm`, { operation: `discard account values`, prompt: `Are you sure?`, data: [] })
                            events.$once(`confirmed`, ({ data, confirmed }) => {
                                if (!confirmed)
                                    return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                                this.discard(...data, true)
                            })
                        } else {
                            this.load()
                        }
                    },
                    display(account, key) {
                        switch(key) {
                            case `custodian`:
                                return smartkx.account.custodian(account[key])
                            case `created`:
                            case `updated`:
                                var key = `_${key}`
                                return account[key] ? this._date(account[key]) : `-`
                            default:
                                return account[key]
                        }
                    },
                    import: () => {
                        this.$refs[`upload`].click()
                    },
                    get imported() {
                        return this.accounts
                            .find(r => r.import)
                    },
                    household: '',
                    households: [],
                    accounts: [],
                    loading: ``,
                    select() {
                        if (!this.household)
                            this.household = this.households.length ? this.households[0].name : ``
                        if (!this.imported)
                            this.load()
                    },
                    get _household() {
                        return this.households
                            .find(h => h.name == this.household)
                    },
                    async init() {
                        var households = firestore.data(
                            await firestore
                                .collection(`households`)
                                .where(`companyId`, `==`, state.company._id)
                                .get(await firestore.token(firebase))
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                        this.households = [...households]
                        this.select()
                    },
                    get items() {
                        var { _household, accounts } = this
                        return accounts
                            .filter(a => a.householdId == _household._id)
                            .reduce((list, acc) => {
                                list.push(...[acc, null])
                                return list
                            }, [])
                    },
                    async load() {
                        if (this.loading)
                            return console.log(`already loading...`)
                        this.loading = true
                        this.accounts.length = 0
                        var token = await firestore.token(firebase)
                        var accounts = firestore.data(
                            await firestore
                                .collection(`accounts`)
                                .where(`householdId`, `==`, this._household._id)
                                .get(token)
                        )
                        .map((a) => {
                            a.household = this._household.ref
                            a.import = false
                            return a
                        })
                        this.accounts = [...accounts]
                        this.loading = false
                    },
                    modal: { account: '' },
                    async open(r, type) {
                        switch(type) {
                            case `blockchain`:
                                var component = `account-values`
                                var token = await firestore.token(firebase)
                                var [account, records] = await Promise.all([this._account(token, r), this._records(token)])
                                var { _contract: contract } = this
                                var data = { account, contract, records }
                                events.$emit(`modal`, { component, data, class: `modal-lg` })
                                break
                        }
                    },
                    pending: false,
                    async remove(account, i, confirm) {
                        try {
                            var { _data: clients } = this
                            if (!confirm) {
                                events.$emit(`confirm`, { operation: `account deletion`, prompt: `Are you really, really sure???`, data: [account, i] })
                                events.$once(`confirmed`, ({ data, confirmed }) => {
                                    if (!confirmed)
                                        return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                                    this.remove(...data, true)
                                })
                            } else {
                                var { _id: managerId } = state.user
                                var { _id: accountId } = account
                                var data = { accountId, managerId }
                                try {
                                    var resp = await Api.post(`account/delete`, data)
                                    alert.message(`Account has been deleted!`, 3e3)
                                    await Sys.wait(1.5e3)
                                    this.load()
                                } catch(e) {
                                    console.log(e)
                                    console.log(e.message)
                                    alert.error(e.message)
                                }
                            }
                        } catch(e) {
                            alert.error(e.message)
                        }
                    },
                    async save(confirm) {
                        if (!confirm) {
                            events.$emit(`confirm`, { operation: `save account values`, prompt: `Are you sure?`, data: [] })
                            events.$once(`confirmed`, ({ data, confirmed }) => {
                                if (!confirmed)
                                    return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                                this.save(...data, true)
                            })
                        } else {
                            var accounts = this.accounts
                                .filter(a => a.import)
                            var { _id: managerId } = state.user
                            var data = { accounts, managerId }
                            try {
                                this.pending = true
                                var resp = await Api.post(`client/accounts`, data)
                                var { message, refresh } = resp.data
                                alert.message(message, 3e3)
                                await Sys.wait(1.5e3)
                                if (refresh)
                                    this.load()
                            } catch(e) {
                                console.log(e)
                                alert.error(e.status ? `${e.status}: ${e.message}` : e.message)
                            } finally {
                                await Sys.wait(0.5e3)
                                this.pending = false
                            }
                        }
                    },
                    style(attr, value) {
                        return [attr, value]
                            .join(`: `)
                    },
                    toggle(index) {
                        console.log(`toggle`, index)
                        this._active = this._active == index ? -1 : index
                        console.log(this._active)
                    },
                    upload(file) {
                        var File = `File '${file.name}'`
                        //var { _id: householdId } = this._household
                        var reader = new FileReader()
                        reader.onloadend = (e) => {
                            var data = smartkx.account.data(reader.result)
                            var records = data.accounts
                                .map(a => ({ ...a, import: true }))
                            console.log({ records })
                            var h, i, a
                            for (var r of records) {
                                h = this.households
                                    .find(h => h.ref == r.household)
                                if (!h)
                                    continue
                                a = { ...r, householdId: h._id }
                                i = this.accounts
                                    .findIndex(a => a.household == r.household && a.name == r.name)
                                if (i < 0) {
                                    this.accounts
                                        .push(a)
                                } else {
                                    this.accounts
                                        .splice(i, 1, a)
                                }
                            }
                            var account = this.accounts
                                .find(a => a.import)
                            if (account) {
                                this.household = this.households
                                    .find(h => h.ref == account.household)
                                    .name
                            }
                            this._active = -1
                        }
                        reader.onerror = (e) => {
                            alert.error(e.message || `File upload failed!`)
                        }
                        reader.readAsText(file)
                    }
                }
                table.init()
                this.state = state
                this.table = table
            },
            upload(e) {
                var [file] = this.$refs.upload.files
                this.table
                    .upload(file)
            }
        },
        watch: {
        }
    }
</script>