<template>
    <div class="h-100">
        <component :is="view.component"></component>
    </div>    
</template>

<style scoped>

</style>

<script>
    import { lib } from '../factory'
    import { session } from '../service'
    import clientAudit from './audit'
    import clientBilling from './billing'
    import clientAccounts from './accounts'
    import clientContracts from './contracts'
    import clientDashboard from './dashboard'

    export default {
        get components() {
            return {
                clientAccounts,
                clientAudit,
                clientBilling,
                clientContracts,
                clientDashboard
            }
        },
        props: ['sidebar'],
        data() {
            return {
            }
        },
        created() {
        },
        destroyed() {
        },
        computed: {
            view() {
                var { string } = lib
                var { sidebar, state } = this
                var { menu, views } = sidebar
                var name = views[menu.index]
                var component = `client` + string.capitalise(name) 
                return { component, name }
            }
        },
        methods: {
        }
    }
</script>