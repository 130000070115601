import Vue from 'vue'
import { firebase, session } from './service'
import { main } from './view'
import config from '../config'

(() => {

    firebase.init(config.firebase)

    session.set('config', config)
    session.set('state', {})
    session.set('data', {})
    session.set('blockchain', {})
    session.set('files', new Map())

    new Vue({
        render: (h) => h(main)
    }).$mount('#app')

})()