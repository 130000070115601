import { lib } from '../factory'

export default (() => {

    var join = `\n`

    class Contract {

        static _account(accounts, index) {
            var { billing, custodian, name, number, scheduleName: schedule, type } = accounts[index]
            var billedTo = billing > -1 ? accounts[billing].number : `This Account` 
            return `
                <tr>
                    <td>
                        <span>${number}</span>
                    </td>
                    <td>
                        <span>${name}</span>
                    </td>
                    <td>
                        <span>${custodian}</span>
                    </td>
                    <td>
                        <span>${type}</span>
                    </td>
                    <td>
                        <span>${schedule}</span>
                    </td>
                    <td>
                        <span>${billedTo}</span>
                    </td>
                </tr>
            `
        }

        static _caveat(accounts, caveat) {
            var { string } = lib
            var { accountId, identifier, scheduleName: schedule, type: _type } = caveat
            var type = string.capitalise(_type)
            var { number: account } = accounts
                .find(a => a._id == accountId)
            return `
                <tr>
                    <td>
                        <span>${account}</span>
                    </td>
                    <td>
                        <span>${type}</span>
                    </td>
                    <td>
                        <span>${identifier.length ? identifier : `Pending`}</span>
                    </td>
                    <td>
                        <span>${schedule}</span>
                    </td>
                </tr>
            `
        }

        static _caveats({ contract }) {
            var set = new Set()
            set.add(`
                <br>
                <p>Account Billing Exceptions</p>
                <br>
                <table class="caveats">
                    <thead>
                        <th>
                            <span>Account</span>
                        </th>
                        <th>
                            <span>Type</span>
                        </th>
                        <th>
                            <span>Identifier</span>
                        </th>
                        <th>
                            <span>Schedule</span>
                        </th>
                    </thead>
                    <tbody>
                    </tbody>
            `)
            var { accounts, caveats } = contract
            caveats
                .forEach(c => set.add(Contract._caveat(accounts, c)))
            set.add(`
                    </tbody>
                </table>
            `)
            return [...set]
                .join(join)
        }

        static _accounts({ contract }) {
            var set = new Set()
            set.add(`
                <br>
                <p>Accounts included in under Advisor’s Management:</p>
                <br>
                <table class="accounts">
                    <thead>
                        <tr>
                            <th>
                                <span>Number</span>
                            </th>
                            <th>
                                <span>Name</span>
                            </th>
                            <th>
                                <span>Custodian</span>
                            </th>
                            <th>
                                <span>Type</span>
                            </th>
                            <th>
                                <span>Schedule</span>
                            </th>
                            <th>
                                <span>Billed To</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
            `)
            var { accounts } = contract
            accounts
                .forEach((a, i) => set.add(Contract._account(accounts, i)))
            set.add(`
                    </tbody>
                </table>
            `)
            return [...set]
                .join(join)
        }

        static _fees() {
            return `
                <br>
                <p class="fees-calc">Fee levels apply to the amount of assets in each tier level. Accounts will be totaled, the fee will be calculated off of the tiered fee schedule and then the fee debited proportionately debited against the accounts.</p>
                <p>Fees will be payable quarterly in advance and will be based on the market value of Client’s portfolio at the end of the preceding quarter at one-fourth of the above annual rate. For investments initially made during a quarter, the fees will be payable upon execution of the Agreement and will be pro-rated for such quarter.</p>
            `
        }

        static _ref({ date, ref }) {
            return `
                <br>
                <p class="contract-ref">Document ref: ${ref}</p>
                <br>
                <p class="contract-ref">Document date: ${date}</p>
            `
            /*
                <br>
                <p class="contract-ref">Contract ref: ______________________</p>
            `
            */
        }

        static _scheduleItem(start, end, rate) {
            var range = end ? `$${start} to $${end}` : `Over $${start}`
            return `
                <tr>
                    <td>
                        <span>${range}</span>
                    </td>
                    <td>
                        <span>${rate}%</span>
                    </td>
                </tr>
            `
        }

        static _schedule({ breaks, name, rates }) {
            var set = new Set()
            set.add(`
                <br>
                <p><strong>Schedule</strong> - ${name}</p>
                <table class="schedule">
                    <thead>
                        <tr>
                            <th>
                                <span>Assets Managed</span>
                            </th>
                            <th>
                                <span>Annual Fee</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
            `)
            breaks
                .forEach((b, i) => {
                    var start = i ? b : 0
                    var end = i < breaks.length - 1 ? breaks[i + 1] : ''
                    var rate = rates[i]
                    set.add(Contract._scheduleItem(start, end, rate))
            })
            set.add(`
                    </tbody>
                </table>
            `)
            return [...set]
                .join(join)
            /*
             } = contract
        if (household) {
            html.push(`
                <br>
                <p>
                    <b>Note: </b><span>The following accounts will be included in the '${household.name}' household</span>
                </p>
            `)
            */
        }

        static _schedules({ contract }) {
            var set = contract.schedules
                .reduce((set, s) => set.add(Contract._schedule(s)), new Set())
            return [...set]
                .join(join)
        }

        static _page(section) {
            switch(section) {
                case 'start':
                    return `
                        <div class="page">
                            <div class="margin">
                                <h3>New Contract</h3>
                                <br>
                                <p>In consideration of the services to be rendered by Advisor, Client agrees to pay Advisor a fee as follows: -</p>
                    `
                case 'end':
                    return `
                            </div>
                        </div>
                    `
            }
            throw new Error(`Invalid section, ${section}!`)
        }

        static _signatureSection(signer, index) {
            var html = [`
                <div class="signature">
                    <div>
                        <span
                            class="field"
                            data-optional="false"
                            data-name="Please sign here"
                            data-signee="${index + 1}"
                            data-type="signature"
                            data-options=""
                        >_______________________</span>
                    </div>
                    <span>${signer.name}</span>
                </div>
            `]
            if (index == 0) {
                html.push(`
                    <div class="signature">
                        <span>By: Sally Compliance, CCO</span>
                    </div>
               `)
            }
            return html.join(join)
        }

        static _signature({ signers }) {
            return [...signers.reduce((set, signer, index) => set.add(Contract._signatureSection(signer, index)), new Set())]
                .join(join)
        }

        static _style() {
            return `
                <style>
                    div.margin {
                        margin: 30px;
                    }
                    h3, h4 {
                        text-decoration: underline;
                        font-family: Arial, Helvetica, sans-serif;
                        text-align: center;
                    }
                    span, p {
                        font-family: Arial, Helvetica, sans-serif;
                    }
                    table.schedule {
                        padding: 0;
                    }
                    table th, td {
                        text-align: left;
                        height: 30px;
                    }
                    table {
                        width: 100%;
                    }
                    table th:nth-child(1) {
                        width: 150px;
                    }
                    table th:nth-child(2) {
                        width: 300px;
                    }
                    p.fees-calc {
                        font-style: italic;
                    }
                    p.contract-ref {
                        font-size: 12px;
                    }
                    div.signature {
                        margin-top: 35px;
                    }
                    div.signature span {
                        font-size: 13px;
                    }
                </style>
            `
        }

        static _trim(code) {
            return code
                .split('\n')
                .filter(line => line.length)
                .join(join)
        }

        static html(data) {
//            return contractPdf.html({ contract, date, ref, signers, company, household })
            //return `<html></html>`
            var html = [
                `<html>`,
                `<head>`,
                Contract._style(),
                `</head>`,
                `<body>`,
                Contract._page('start'),
                Contract._schedules(data),
                Contract._accounts(data),
                Contract._caveats(data),
                //Contract._accounts(contract, summary),
                //Contract._fees(contract, summary),
                Contract._signature(data),
                Contract._ref(data),
                Contract._page('end'),
                `</body>`,
                `</html>`
            ]
            return html
                //.map(code => Contract._trim(code))
                .join(join)
        }

        /*
        static html(data) {
            console.log('creating pdf html...')
            console.log(data)
        }
        */
    }

    return Contract

})()