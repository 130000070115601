<template>
    <div class="h-100 m-3">
        <div class="row tab-list">
            <div class="col px-5" v-for="(button, index) in nav.buttons" :key="index">
                <button class="btn btn-sm btn-block" :class="nav.class(index)" v-on:click="nav.click(index)" :disabled="nav.disabled(index)">
                    <span v-text="button"></span>
                </button>
            </div>
        </div>
        <div class="tab-components pb-3">
            <component :is="nav.component"></component>
        </div>
    </div>
</template>

<script>
    import { lib } from '../../../factory'
    import contractSchedules from './schedules'
    import contractAccounts from './accounts'
    import contractBilling from './billing'
    import contractExceptions from './exceptions'
    import contractSignees from './signees'
    import contractSummary from './summary'
    import { session } from '../../../service'

    export default {
        get components() {
            return {
                contractSchedules,
                contractAccounts,
                contractBilling,
                contractExceptions,
                contractSignees,
                contractSummary
            }
        },
        data() {
            return {
                nav: '',
                state: ''
            }
        },
        created() {
            console.log('new contact init')
            this.init()
        },
        computed: {
            contract() {
                var { state } = this
                var { company, contracts, household } = state
                var { _id: householdId } = household
                return state.contracts
                    .find(c => c.householdId == householdId)
            }
        },
        methods: {
            init() {
                var { string } = lib
                this.state = session.get('state')
                this.nav = {
                    _index: 0,
                    _components: [
                        `contract-schedules`,
                        `contract-accounts`,
                        `contract-billing`,
                        //`contract-exceptions`,
                        `contract-signees`,
                        `contract-summary`
                    ],
                    class(index) {
                        var col = `primary`
                        return this._index == index ? `btn-${col}` : `btn-outline-${col}`
                    },
                    get component() {
                        return this._components[this._index]
                    },
                    get buttons() {
                        return this._components
                            .map(c => string.capitalise(c.split(`-`).pop()))
                    },
                    click(index) {
                        this._index = index
                    },
                    disabled: (index) => {
                        switch(index) {
                            case 0:
                                return false
                            case 1:
                                return this.contract && this.contract.schedules && this.contract.schedules.length ? false : true
                            case 5:
                                return this.contract && this.contract.signees && this.contract.signees.length ? false : true
                            default:
                                return this.contract && this.contract.hasOwnProperty(`households`) ? false : true 
                        }
                    }
                }
            }
        },
        watch: {
            'state.household': {
                handler() {
                    this.nav.click(0)
                }
            }
        }
    }
</script>

<style scoped>
    a {
        padding: 5px 10px;
        cursor: pointer;
        margin: 0 50px;
        background-color: #f8f9fa;
    }
    a.active {
        border-bottom: 2px solid #007bff;
        color: inherit;
        cursor: text;
    }

</style>