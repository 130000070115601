import moment from 'moment'
import numeral from 'numeral'

export default (() => {

	var locale = {
		code: 'en-us',
		options: {
			abbreviations: {
				million: 'M',
				billion: 'B',
				trillion: 'T'
			},
			currency: {
				symbol: '$'
			},
			delimiters: {
				thousands: ',',
				decimal: '.'
			}
		},
		init: false
	}

	class Format {

		static _init() {
			if (locale.init)
				return console.log('already init')
			numeral.register('locale', locale.code, locale.options)
			numeral.switch(locale.code)
			locale.init = true
			console.log({ locale })
		}

		/*
		static currency(v) {
			Format._init()
			return numeral(v).format(`0.00a`)
		}
		*/

		static _numeral(a) {
			return numeral(a / 1e6)
		}

		static currency(val, sym) {
			var num = typeof val == 'string' ? parseFloat(val) : val
			if (isNaN(num))
				num = 0
			var [ pound, pence ] = num.toFixed(2).split('.')
			var currency = Format.number(pound) + '.' + pence
			return sym ? `${sym}${currency}` : currency
		}

		static date(d) {
			var _in = `YYYYMMDD`
			var _out = `MMM DD, YYYY`
			return typeof d == 'number' ? moment(d, _in).format(_out) : moment(d, _out).format(_in)
		}

		static number(val, fixed = 0) {
			var v = fixed ? (+val / (10 ** fixed)) : +val
			var str = fixed ? v.toFixed(fixed) : v.toString()
			var [a, b] = str.split('.')
			var number = []
			var part = ''
			a.split('').reverse().forEach((char) => {
				part = char + part
				if (part.length == 3) {
					number.push(part)
					part = ''
				}
			})
			if (part.length)
				number.push(part)
			if (!number.length)
				number.push('0')
			var num = number.reverse().join(',')
			return b ? `${num}.${b}` : num
		}

		static percent(v, fixed = 0) {
			return fixed ? v.toFixed(fixed) : v
		}

		static BPS(a) {
			var fmt = `0.00%`
			return Format._numeral(a).format(fmt)

		}

		static Y_Q(a) {
			var str = String(a)
			return `${str.slice(0, 4)} Q${str.slice(4)}`
		}

		static YMD(a) {
			var fmt = `YYYYMMMDD`
			return moment(a, `YYYYMMDD`).format(fmt)
		}

		static _shortUSD(a) {
			return Format._numeral(a).format(`$a0`)
		} 

		static USD(a) {
			return typeof a == 'boolean' && a == true ? Format._shortUSD : Format._numeral(a).format(`00`)
		}

	}

	return Format

})()