<template>
    <scroll-area>
        <div class="m-3">
            <div class="row">
                <div class="col-1">
                    <strong>Household:</strong>
                </div>
                <div class="col">
                    <span v-text="table.household"></span>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-1">
                    <strong>Date:</strong>
                </div>
                <div class="col-2">
                    <date-picker :obj="contract" prop="date" :options="contract.dateOptions"></date-picker>
                </div>
                <div class="col-6"></div>
                <div class="col-2 text-right">
                    <div class="btn-group btn-group-sm" role="group">
                        <button :disabled="disabled.preview" type="button" class="btn btn-primary" v-on:click="preview">Preview</button>
                        <button :disabled="disabled.save" type="button" class="btn btn-success px-3" v-on:click="save">Save</button>
                    </div>
                </div>
            </div>
            <div class="schedule" v-for="s in contract.schedules" :key="s.name">
                <template v-if="table.accounts(s).length">
                    <br>
                    <div class="row">
                        <div class="col-1">
                            <strong>Schedule:</strong>
                        </div>
                        <div class="col">
                            <span v-text="s.name"></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-6">
                            <table class="table table-sm table-borderless">
                                <thead>
                                    <tr>
                                        <th>
                                            <span>Range</span>
                                        </th>
                                        <th></th>
                                        <th>
                                            <span>Rate</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(b, index) in s.breaks" :key="index">
                                        <td>
                                            <span v-text="table.range(s, index)"></span>
                                        </td>
                                        <td>@</td>
                                        <td>
                                            <span v-text="table.rate(s, index)"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <template v-if="table.minimum(s)">
                                <p class="note" v-text="table.minimum(s)"></p>
                            </template>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col">
                            <table class="table table-sm table-borderless">
                                <thead>
                                    <tr>
                                        <th>
                                            <span>Account Name</span>
                                        </th>
                                        <th>
                                            <span>Account Number</span>
                                        </th>
                                        <th>
                                            <span>Household</span>
                                        </th>
                                        <th>
                                            <span>Bill To</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="a in table.accounts(s)" :key="a.number">
                                        <td>
                                            <span v-text="a.name"></span>
                                        </td>
                                        <td>
                                            <span v-text="a.number"></span>
                                        </td>
                                        <td>
                                            <span v-text="table.Household(a.household)"></span>
                                        </td>
                                        <td>
                                            <span v-text="a.billTo"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </div>
            <br>
            <div class="row">
                <div class="col-1">
                    <strong>Signees:</strong>
                </div>
                <div class="col">
                    <span>Electronic</span>
                    <input class="form-check-input ml-3 mt-2" type="checkbox" v-model="contract.esign">
                </div>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col">
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th>
                                    <span>Name</span>
                                </th>
                                <th>
                                    <span>Email</span>
                                </th>
                                <th>
                                    <span>Role</span>
                                </th>
                                <th>
                                    <span>Order</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(s, i) in table.signees" :key="i">
                                <td>
                                    <span v-text="s.name"></span>
                                </td>
                                <td>
                                    <span v-text="s.email"></span>
                                </td>
                                <td>
                                    <span v-text="s.role"></span>
                                </td>
                                <td>
                                    <span v-text="table.order(i)"><span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </scroll-area>
</template>

<style scoped>
    div.row > div.col-1 {
        margin-right: 10px;
    }
    div.schedule table {
        margin: 10px 0 0 -5px;
    }
    hr {
        margin-top: 0;
        opacity: 0.6;
    }
    p.none {
        text-align: center;
    }
    p.note {
        margin: 10px 0 0 0;
        font-style: italic;
    }
</style>

<script>
    import { datePicker, scrollArea } from '../../../component'
    import { Api, Contract, Format, lib, Sys, Tab } from '../../../factory'
    import { alert, events, firebase, session } from '../../../service'
    import { moment, numeral } from '../../../npm'
    import { skivy71 } from '../../../npm.org'

    export default {
        get components() {
            return {
                datePicker,
                scrollArea
            }
        },
        data() {
            return {
                state: '',
                table: ''
            }
        },
        created() {
            this.init()
        },
        mounted() {
        },
        computed: {
            contract() {
                var { state } = this
                var { company, contracts, household } = state
                var { _id: householdId } = household
                var contract = state.contracts
                    .find(c => c.householdId == householdId)
                if (!contract)
                    throw new Error(`Unable to find contract in state, should already be there!`)
                return contract
            },
            disabled() {
                var { contract, table } = this
                var save = !contract.preview.done
                var preview = !table.accounts.length || !contract.schedules.length || contract.preview.pending 
                return { preview, save }
            }
        },
        methods: {
            accounts() {
                var { contract, table } = this
                return contract.schedules
                    .reduce((a, s) => {
                        a.push(...table.accounts(s))
                        return a
                    }, [])
            },
            display(c, key) {
                switch(key) {
                    case `date`:
                        return moment(c[key], c.dateFormat).format(`MMM DD, YYYY`)
                    default:
                        throw new Error(`Invalid display key, ${key}!`)
                }
            },
            init() {
                var state = session.get(`state`)
                var dateFormat = `MMM D, YYYY`
                this.state = state
                var { contract } = this
                if (!contract.hasOwnProperty(`date`)) {
                    this.contract.dateFormat = dateFormat
                    this.contract.dateOptions = {
                        get format() {
                            return dateFormat
                        },
                        minDate: ``
                    }
                    this.$set(contract, `date`, moment().format(dateFormat))
                }
                if (!contract.hasOwnProperty(`preview`))
                    this.$set(contract, `preview`, { pending: false, done: false })
                if (!contract.hasOwnProperty(`esign`))
                    this.$set(contract, `esign`, true)
                contract.preview = { pending: false, done: false }
                this.table = {
                    accounts(s) {
                        return contract.households
                            .filter(h => h != null)
                            .reduce((arr, h) => {
                                var { name: household } = h
                                h.accounts
                                    .forEach((a) => {
                                        if (!a.select || a.scheduleName != s.name)
                                            return
                                        var { name, number, billTo, scheduleName } = a
                                        arr.push({ household, name, number, billTo, scheduleName })
                                    })
                                return arr
                            }, [])
                    },
                    order(i) {
                        return skivy71.utils.number.ordinal(i + 1)
                    },
                    Household(name) {
                        return name
//                        return name == this.household ? `This household` : name
                    },
                    get household() {
                        return state.household.name
                    },
                    minimum(s) {
                        return numeral(s.minimum).value()
                            ? `Note: The above schedule is subject to a minimum 'annual' fee of $${s.minimum}.`
                            : ``
                    },
                    range(s, i) {
                        var { breaks } = s
                        var start = breaks[i]
                        var end = i < breaks.length - 1 ? breaks[i + 1] : ` on`
                        return `$${start} - ${end}`
                    },
                    rate(s, i) {
                        var rate = s.rates[i]
                        return `${rate}%`
                    },
                    get signees() {
                        return contract.signees
                    }  
                }
            },
            async preview() {
                var { contract, state, table } = this
                if (contract.preview.pending)
                    return
                contract.preview.pending = true
                var { company, household, user } = state
                var accounts = this.accounts()
                var data = { accounts, contract, company, household, user }
                var success
                try {
                    var resp = await Api.post(`html/contract`, data)
                    var tab = Tab.open()
                    if (!tab || !tab.document)
                        throw new Error(`Failed to create new window!`)
                    contract.document = resp.data.render 
                    tab.document.open()
                    tab.document.write(contract.document)
                    tab.document.close()
                    success = true
                    await Sys.wait(1500)
                    events.$emit(`summary`, { previewed: true })
                } catch(e) {
                    console.log(e)
                } finally {
                    await Sys.wait(1500)
                    contract.preview.pending = false
                    if (success)
                        contract.preview.done = true
                }
            },
            async save() {
                console.log('save')
                var { company, household, user } = this.state
                var { _id: managerId } = user
                var type = `master`
                var accounts = this.accounts()
                var contract = Object.keys(this.contract)
                    .reduce((o, key) => {
                        switch(key) {
                            case `date`:
                            case `dateFormat`:
                            case `document`:
                            case `esign`:
                            case `householdId`:
                                o[key] = this.contract[key]
                                break
                            case `schedules`:
                                o[key] = [...this.contract[key].map(s => Contract.sschedule(s))]
                                break
                            case `signees`:
                                o[key] = [...this.contract[key]]
                                break
                        }
                        return o
                    }, { accounts, type, managerId })
                //var config = session.get(`config`)
                //var url = `${config.api.url}/contract/save`
                try {
                    var resp = await Api.post(`contract/save`, contract)
//                    skivy71.http.post(url, contract)
                    var { message } = resp.data
                    alert.message(message, 3e3)
                    await Sys.wait(1.5e3)
                    events.$emit(`summary`, { saved: true })
                } catch(e) {
                    console.log(e)
                    console.log(e.message)
                    alert.error(e.message)
                }
            }
        }
    }
</script>