<template>
    <div class="card">
        <div class="card-header">
            <span>Invoice</span>
        </div>
        <div class="card-body">
            <template v-if="dashboard.invoice">
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th>
                                <span>Date</span>
                            </th>
                            <th>
                                <span>Reference</span>
                            </th>
                            <th>
                                <span>Status</span>
                            </th>
                            <th>
                                <span>Document</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span v-text="invoice.date"></span>
                            </td>
                            <td>
                                <span v-text="invoice.ref"></span>
                            </td>
                            <td>
                                <span v-text="invoice.status"></span>
                            </td>
                            <td class="docs">
                                <img class="pdf" src="/img/pdf-icon.svg" v-on:click="open">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <em>None found</em>
            </template>
        </div>
    </div>
</template>

<style scoped>
    td.docs img:last-child {
        margin-left: 25px;
    }
</style>

<script>
    import { lib, Invoice, Sys, Tab } from '../../factory'
    import { alert, session, firebase } from '../../service'
    import { axios, moment, numeral } from '../../npm'
    import { smartkx } from '../../npm.org'

    export default {
        props: ['dashboard'],
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
            invoice() {
                var { string } = lib
                var { dashboard } = this
                var { invoice: i } = dashboard
                var keys = [`date`, `ref`, `status`]
                return keys
                    .reduce((o, key) => {
                        switch(key) {
                            case `date`:
                                o[key] = moment(i[key], `MM/DD/YYYY`)
                                    .format(dashboard.date.format)
                                break
                            case `ref`:
                                o[key] = dashboard.document.ref(i)
                                break
                            case `status`:
                                o[key] = smartkx.invoice.status[i.status]
                                break
                            default:
                                throw new Error(`Unexpected key, ${key}!`)
                        }
                        return o
                    }, {})
            }
        },
        methods: {
            open() {
                var { invoice: i } = this.dashboard
                Invoice.open(i)
            }
        }
    }
</script>