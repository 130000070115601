<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Admin login</h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Demo users</label>
                        <select class="form-control form-control-sm" v-model="view.user">
                            <option v-for="user in view.users" :key="user._id">
                                <span v-text="user.name"></span>
                            </option>
                        </select>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <button class="btn btn-info btn-sm btn-block mb-2" v-on:click="view.demo()">Continue as you</button>
                        </div>
                        <div class="col">
                            <button class="btn btn-primary btn-sm btn-block mb-2" v-on:click="view.demo(true)">Login as demo user</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import { Api } from '../factory'
    import { smartkx } from '../npm.org'
    import { alert, events, firebase, session } from '../service'

    var { firestore } = smartkx

    export default {
        data() {
            return {
                view: ``
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                var state = session.get(`state`)
                var view = {
                    async _load() {
                        var users = firestore.data(
                            await firestore
                                .collection(`managers`)
                                .where(`demo`, `==`, true)
                                .get(await firestore.token(firebase))
                        )
                        this.user = users.length ? users[0].name : ``
                        this.users = [...users]
                    },
                    demo(demo) {
                        var user = demo
                            ? this.users.find(o => o.name == this.user)
                            : ``
                        events.$emit(`admin`, { demo: { user } })
                    },
                    get disabled() {
                        return false
                    },
                    init() {
                        this._load()
                        return this
                    },
                    user: ``,
                    users: ``
                }
                this.view = view.init()
            }
        }
    }
</script>