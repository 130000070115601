<template>
    <table class="mt-3 table table-sm table-borderless">
        <tbody>
            <tr v-for="(action, index) in table.actions" :key="index">
                <td>
                    <button :disabled="table.disabled(action)" class="btn btn-sm btn-block" :class="table.class(action)" v-on:click="table.action(action)">
                        <i :class="table.class(action, 'glyph')"></i>
                        <span class="label" v-text="action.label"></span>
                    </button>
                </td>
                <td>
                    <span class="desc" v-text="action.description"></span>
                </td>
                <!--
                <td>
                    <template v-if="!index && isDemo">
                        <button class="btn btn-primary btn-sm" style="width: 60px" v-on:click="table.help()">Help</button>
                    </template>
                </td>
                -->
            </tr>
        </tbody>
    </table>
</template>

<style scoped>
    table.table {
        line-height: 3;
    }
    table td:first-child {
        width: 130px;
    }
    table td:nth-child(2) {
        padding-left: 20px;
    }
    table td {
        vertical-align: inherit;
    }
    button {
        text-align: left;
        padding-left: 15px;
    }
    button i {
        width: 15px;
    }
    button i.fas.fa-pen-fancy {
        color: white;
    }
    span.label {
        padding-left: 10px;
        color: white;
    }
    span.desc {
        opacity: 0.7;
    }
</style>

<script>
    import { Api, Contract, Format, lib, Sys } from '../../../factory'
    import { numeral } from '../../../npm'
    import { alert, events, firebase, session } from '../../../service'

    export default {
        props: [`contract`],
		data() {
			return {
                table: '',
                state: ``
			}
        },
        created() {
            this.init()
        },
		computed: {
            isDemo() {
                return true
                return this.state.user.demo
            }
		},
		methods: {
            init() {
                var { string } = lib
                this.state = session.get(`state`)
                this.table = {
                    _action: async (action) => {
                        var { contract } = this
                        var { _id: contractId } = contract
                        var [config, state] = session.get(`config`, `state`)
                        var { _id: managerId } = state.user
                        var message
                        var emit = {}
                        var ready, init
                        this.table.pending = true
                        try {
                            switch(action) {
                                case `audit`:
                                    return events.$emit(`household-menu`, { audit: { contract }})
                                case `query`:
                                    this.table._pending = action
                                    var status = await Contract.status(contract)
                                    this.table._pending = ``
                                    if (!status.version)
                                        return this.table._warn(`Contract is not live, please wait and retry in a moment!`)
                                    for (var key of [`schedules`, `accounts`]) {
                                        if (contract[key].length != status[key]) {
                                            init = key.slice(0, -1) + `(s)`
                                            break
                                        }
                                    }
                                    var { version, schedules, accounts } = status
                                    message = `Contract version: ${version}, schedules: ${schedules}, accounts: ${accounts}`
                                    if (init) {
                                        message += `, not ready! Please initialize ${init}!`
                                        this.table.init = init
                                        if (contract.status == 5) {
                                            await firebase.db
                                                .collection(`contracts`)
                                                .doc(contract._id)
                                                .update({ status: contract.status - 1 })
                                            events.$emit(`contract`, { refresh: true })
                                        }
                                        return this.table._warn(message)
                                    }
                                    alert.message(message, 3e3)
                                    if (contract.status < 5) {
                                        await firebase.db
                                            .collection(`contracts`)
                                            .doc(contract._id)
                                            .update({ status: contract.status + 1 })
                                        await Sys.wait(1.5e3)
                                        events.$emit(`contract`, { refresh: true })
                                        await Sys.wait(1.5e3)
                                    } else {
                                        await Sys.wait(3e3)
                                        this.table.pending = false
                                    }
                                    break
                                default:
                                    if (action == `initialize` && !this.table.init)
                                        return alert.warning(`Please query the smart contract status first!`)
                                    this.table._pending = action
                                    var data = { contractId, managerId }
                                    var resp = await Api.post(`contract/${action}`, data)
                                    this.table._pending = ``
                                    var { message, refresh } = resp.data
                                    alert.message(message, 3e3)
                                    await Sys.wait(1.5e3)
                                    this.table.pending = false
                                    this.table.init = ``
                                    if (!refresh)
                                        return
                                    await Sys.wait(1.5e3)
                                    events.$emit(`contract`, { refresh })
                            }
                        } catch(e) {
                            console.log(e)
                            this.table._error(e.message)
                            this.table._pending = ``
                            this.table.init = ``
                        }
                    },
                    _actions: {
                        _init: ``,
                        audit: `View the contract audit logs`,
                        upload: `Upload the manually signed contract`,
                        sign: `Submit the contract for digital signing`,
                        deploy: `Deploy the smart contract`,
                        /*
                        query: `Query the smart contract state`,
                        get initialize() {
                            return  `Initialize the smart contract ${this._init}`
                        },
                        */
                        terminate: `Terminate contract`
                    },
                    _error(text, pending = false) {
                        alert.error(text)
                        this.pending = pending
                    },
                    _pending: ``,
                    _warn(text, pending = false) {
                        alert.warning(text)
                        this.pending = pending
                    },
                    action(action, confirm) {
                        var { name } = action
                        var operation, method
                        switch(name) {
                            case `upload`:
                                alert.warning(`Signed contract upload is disabled!`, 3e3)
                                break
                            case `sign`:
                                operation = `contract submission`
                                break
                            case `deploy`:
                                operation = `contract deployment`
                                break
                            case `initialize`:
                                operation = `contract ${this.init} initialization`
                                break
                            case `terminate`:
                                operation = `contract termination`
                                break
                            case `audit`:
                            case `query`:
                                return this._action(name)
                        }
                        if (!operation)
                            return console.log('nothing to do!')
                        if (confirm)
                            return this._action(name)
                        events.$emit(`confirm`, { operation, data: [action] })
                        events.$once(`confirmed`, ({ data, confirmed }) => {
                            if (!confirmed)
                                return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                            this.action(...data, true)
                        })
                    },
                    get actions() {
                        var { _actions } = this
                        return Object.keys(_actions)
                            .filter(key => key == `initialize` ? _actions._init : key[0] != `_`)
                            .map((name) => {
                                var label = string.capitalise(name)
                                var description = _actions[name]
                                return { description, label, name }
                            })
                    },
                    class(action, glyph) {
                        var { name } = action
                        var glyphicon = (action, icon) => this._pending == action ? `fa fa-spinner fa-spin` : icon
                        switch(name) {
                            case `audit`:
                                return glyph ? glyphicon(name, `fas fa-history`) : `btn-dark`
                            case `upload`:
                                return glyph ? glyphicon(name, `fas fa-upload`) : `btn-info`
                            case `sign`:
                                return glyph ? glyphicon(name, `fas fa-pen-fancy`) : `btn-warning`
                            case `deploy`:
                                return glyph ? glyphicon(name, `fas fa-rocket`) : `btn-primary`
                            case `initialize`:
                                return glyph ? glyphicon(name, `far fa-play-circle`) : `btn-success`
                            case `query`:
                                return glyph ? glyphicon(name, `fas fa-question-circle`) : `btn-secondary`
                            case `terminate`:
                                return glyph ? glyphicon(name, `far fa-stop-circle`) : `btn-danger`
                            default:
                                throw new Error(`Invalid action, ${name}!`)
                        }
                    },
                    disabled: ({ name }) => {
                        var { status } = this.contract
                        switch(name) {
                            case `audit`:
                                return false
                            case `upload`:
                                return status > 1 || this.table.pending
                            case `sign`:
                                return status > 1 || this.table.pending
                            case `deploy`:
                                //return false
                                return status != 3 || this.table.pending
                            case `initialize`:
                            case `query`:
                            case `terminate`:
                                return status < 4 || this.table.pending
                            default:
                                throw new Error(`Invalid disabled action, ${name}!`)
                        }
                    },
                    help() {
                        events.$emit(`modal`, { component: `contract-help`, data: {}, class: `modal-lg` })
                    },
                    get init() {
                        return this._actions._init
                    },
                    set init(v) {
                        this._actions._init = v
                    },
                    pending: false
                }
            }
		}
    }
</script>