<template>
	<div :id="id" class="modal fade" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
                <div class="modal-header bg-light">
					<h5 class="modal-title" v-text="billing.header"></h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
                </div>
                <div class="modal-body">
                    <template v-if="billing.ready">
                        <div class="row">
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="bill-type">Type</label>
                                    <select class="form-control form-control-sm" id="date-start" v-model="billing.type" v-on:change="billing.change('type')" :disabled="billing.state > 0">
                                        <option v-for="(type, index) in billing.types" :key="index">
                                            <span v-text="type"></span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="period">Reference</label>
                                    <input class="form-control form-control-sm" placeholder="Reference" v-model="billing.reference" :disabled="billing.state > 0">
                                </div>
                            </div>
                            <template v-if="billing.type=='Advance'">
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="date-values">Account Values</label>
                                        <select class="form-control form-control-sm" id="date-values" v-model="billing.valuesDate" v-on:change="billing.change('values')" :disabled="billing.state > 0">
                                            <option v-for="(date, index) in billing.valuesDates" :key="index">
                                                <span v-text="date"></span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="date-end">Period End</label>
                                        <select class="form-control form-control-sm" id="date-end" v-model="billing.endDate" v-on:change="billing.change('end')" :disabled="billing.state > 0">
                                            <option v-for="(date, index) in billing.endDates" :key="index">
                                                <span v-text="date"></span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="date-start">Period Start</label>
                                        <select class="form-control form-control-sm" id="date-start" v-model="billing.startDate" v-on:change="billing.change('start')" :disabled="billing.state > 0">
                                            <option v-for="(date, index) in billing.startDates" :key="index">
                                                <span v-text="date"></span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="date-end">Period End</label>
                                        <select class="form-control form-control-sm" id="date-end" v-model="billing.endDate" v-on:change="billing.change('end')" :disabled="billing.state > 0">
                                            <option v-for="(date, index) in billing.endDates" :key="index">
                                                <span v-text="date"></span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </template>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="date-inclusive">Inclusive</label>
                                    <select class="form-control form-control-sm" id="date-inclusive" v-model="billing.inclusive" v-on:change="billing.change('inclusive')" :disabled="billing.state > 0">
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="period">Period</label>
                                    <input class="form-control form-control-sm" :value="billing.period" readonly>
                                </div>
                            </div>
                            <!--
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="period">Fees</label>
                                    <input class="form-control form-control-sm" :value="currency(billing.feeTotal)" readonly>
                                </div>
                            </div>
                            -->
                            <!--
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="date-end">Recurring</label>
                                    <select class="form-control form-control-sm" id="recurr" v-model="select.recurr" v-on:change="select.change('recurr')">
                                        <option v-for="(option, index) in select.recurring" :key="index">
                                            <span v-text="option"></span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                            -->
                        </div>
                        <div class="row">
                            <div class="col">
                                <h4>Current Contract</h4>
                                <div id="accordion">
                                    <div v-for="(contract, index) in billing.contracts" :key="index" class="card">
                                        <div class="card-header" :id="contract._id">
                                            <table class="contract-account">
                                                <tr>
                                                    <td>
                                                        <h5 class="mb-0">
                                                            <button class="btn btn-link" data-toggle="collapse" :data-target="`#collapse-${contract._id}`" aria-expanded="true" aria-controls="collapseOne">
                                                                <span v-text="contract._id"></span>
                                                            </button>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <span v-text="billing.last(contract)"></span>
                                                    </td>
                                                    <td class="preview">
                                                        <template v-if="contract.loading">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </template>
                                                        <template v-else-if="contract.preview">
                                                            <button :disabled="contract.previewPending" class="btn btn-sm btn-primary" v-on:click="billing.preview(contract)">Preview</button>
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <span>Include: </span>
                                                        <input type="checkbox" v-model="contract.bill" v-on:change="billing.change('bill', index)" :disabled="billing.state > 0">
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div :id="`collapse-${contract._id}`" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                                <table class="table table-sm table-borderless">
                                                    <tr>
                                                        <th v-for="(key, index) in accounts.keys" :key="index">
                                                            <span v-text="key.label"></span>
                                                        </th>
                                                    </tr>
                                                    <tr v-for="(account, i1) in contract.accounts" :key="i1">
                                                        <td v-for="(key, i2) in accounts.keys" :key="i2">
                                                            <span v-text="display(account, key)"></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td :colspan="accounts.keys.length">
                                                            <hr>
                                                        </td>
                                                    </tr>
                                                    <tr class="total">
                                                        <td></td>
                                                        <td>
                                                            <span>Accounts Total</span>
                                                        </td>
                                                        <td>
                                                            <span v-text="total(contract, 'accountsTotal')"></span>
                                                        </td>
                                                        <td>
                                                            <span>Fees Total</span>
                                                        </td>
                                                        <td>
                                                            <span v-text="total(contract, 'feesTotal')"></span>
                                                        </td>
                                                    </tr>
                                                    <template v-if="billing.owing(contract)">
                                                        <tr class="total">
                                                            <td colspan="3"></td>
                                                            <td>
                                                                <span>Fees Minimum</span>
                                                            </td>
                                                            <td>
                                                                <span v-text="total(contract, 'feesMinimum')"></span>
                                                            </td>
                                                        </tr>
                                                        <tr class="total">
                                                            <td colspan="3"></td>
                                                            <td>
                                                                <span>Fees Shortfall</span>
                                                            </td>
                                                            <td>
                                                                <span v-text="total(contract, 'feesShortfall')"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="7" class="fees-owing-note">
                                                                <span>* - fee includes a percentage of the fees shortfall</span>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <p class="loading">Loading...</p>
                    </template>
                </div>
                <div class="modal-footer">
                    <table>
                        <tr>
                            <td></td>
                            <td>
                                <p v-text="status.text" :style="status.style"></p>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-outline-success" :disabled="billing.state > 0" v-on:click="billing.save()">Save</button>
                            </td>
                        </tr>
                    </table>
                </div>
			</div>
		</div>
	</div>
</template>

<style scoped>
    td.preview i {
        opacity: 0.5;
    }
    td.fees-owing-note {
        text-align: right;
    }
    table tr.total td:nth-child(even) {
        text-align: right;
    }
    table tr.total td:nth-child(even) span {
        margin-right: 20px;
        font-weight: bold;
    }
    table.contract-account {
        width: 100%;
    }
    table.contract-account td:first-child {
        width: 275px;
    }
    table.contract-account td:last-child {
        width: 100px;
        text-align: right;
    }
    table.contract-account td:last-child input {
        vertical-align: inherit;
        margin-left: 10px;
    }
    table.contract-account td.preview {
        text-align: right;
    }
    div.modal-body > div {
        margin: 5px 10px;
    }
    nav a.dropdown-item {
        cursor: pointer;
    }
    div.modal-content {
        min-height: 85vh;
    }
    div.modal-header {
        margin: 0 3px;
    }
    div.modal-footer table {
        width: 100%;
    }
    div.modal-footer table td:not(:nth-child(2)) {
        width: 50px;
    }
    div.modal-footer table td:nth-child(2) {
        text-align: center;
    }
    div.view {
        margin: 10px;
    }
    div.reveal {
        position: absolute;
        top: 50%;
        left: -10px;
    }
    div.modal-footer p {
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        opacity: 0.6;
    }
</style>

<script>
    import axios from 'axios'
    import moment from 'moment'
    import numeral from 'numeral'
    import utils from '@skivy71/utils'
    import { Contract, Format, Interpolate, lib, Style, Sys, Tab } from '../../factory'
    import { Household } from '../../model'
    import { firebase, session } from '../../service'

    var { $ } = window

    export default {
        get components() {
            return {
            }
        },
        props: ['modal'],
        data() {
            return {
                accounts: '',
                accountKeys: '',
                billing: '',
                state: '',
                status: ''
            }
        },
        created() {
            this.init()
        },
        mounted() {
            var { el, modal } = this
			$(el).on('hidden.bs.modal', () => {
                modal.active = false
            })
            this.show(true)
        },
        computed: {
            el() {
                var { id } = this
                return `#${id}`
            },
            id() {
                var { _uid } = this
                return _uid
            }
        },
        methods: {
            total(contract, key) {
                var format = `$0,0.00`
                var total
                switch(key) {
                    case 'accountsTotal':
                        total = contract.schedules
                            .reduce((t, s) => t += s.total.splits, 0)
                        return numeral(total)
                            .divide(Contract.scale())
                            .format(format)
                    case 'feesTotal':
                        total = contract.schedules
                            .reduce((t, s) =>  t += s.total.fees, 0)
                        return numeral(total)
                            .divide(Contract.scale('fees'))
                            .format(format)
                    case 'feesMinimum':
                    case 'feesShortfall':
                        total = contract.schedules
                            .reduce((t, s) => t += s[key], 0)
                        return numeral(total)
                            .divide(Contract.scale('fees'))
                            .format(format)
                    default:
                        throw new Error(`Invalid total key, ${key}!`)
                }
            },
            display(account, key) {
                var { string } = lib
                var v = account[key.value]
                switch(key.value) {
                    case 'owing':
                        return account[key.value] ? `*` : ''
                    case 'value':
                    case 'fee':
                        return numeral(v)
                            .divide(Contract.scale(key.value))
                            .format(`$0,0.00`)
                    case 'name':
                        return string.truncate(v, 35)
                    default:
                        return v
                }
            },
            billedTo(contract, account) {
                var { accounts } = contract
                var { billing: index } = account
                return index == -1 ? `Self` : accounts[index].number 
            },
            async calculate() {
                var { billing } = this
                var { startDate, endDate, householdContracts: contracts, period, type, valuesDate } = billing
                var [start, end] = (type == 'Advance' ? [valuesDate, endDate] : [startDate, endDate])
                    .map(d => Format.date(d))
                var fees
                var events, values, account, hash, schedule, accounts, files, calc
                try {
                    var c = 0
                    for (var contract of contracts) {
                        c++
                        if (!contract.bill) {
                            contract.accounts
                                .forEach((a) => {
                                    a.fee = 0
                                    a.value = 0
                                })
                            contract.schedules
                                .forEach((s) => {
                                    s.fees.length = 0
                                    s.splits.length = 0
                                    s.total = { fees: 0, splits: 0 }
                                    s.feesMinimum = 0
                                    s.feesShortfall = 0
                                })
                            contract.preview = false
                            contract.previewPending = false
                            contract.loading = false
                        } else {
                            contract.loading = true
                            switch(billing.type) {
                                case 'Arrears':
                                    values = await Contract.values(contract, start, end, billing.inclusive == 'Yes')
                                    contract.accounts
                                        .forEach((account) => {
                                            var { average } = values
                                                .find(v => v.account == (account.hash || Contract.hash(contract, account)))
                                            account.value = +(average / Contract.scale()).toFixed()
                                        })
                                    break
                                case 'Advance':
                                    values = await Contract.values(contract, start)
                                    contract.accounts
                                        .forEach((account) => {
                                            var { value } = values
                                                .find(v => v.account == (account.hash ? account.hash : Contract.hash(contract, account)))
                                            account.value = value
                                        })
                                    break
                                default:
                                    throw new Error(`Invalid billing type, ${billing.type}!`)
                            }
                            for (var s of contract.schedules) {
                                schedule = await Contract.schedule(contract, s)
                                calc = await Contract.calc(schedule)
                                accounts = contract.accounts
                                    .filter(a => a.scheduleName == s.name)
                                fees = await Contract.fees(accounts, schedule, period, calc)
                                var [accountsTotal, feesTotal, feesMinimum, feesShortfall] = fees.totals
                                console.log(fees)
                                s.splits = [...fees.accountsSplits]
                                s.fees = [...fees.feesSplits]
                                s.total = { fees: feesTotal, splits: accountsTotal }
                                s.feesMinimum = feesMinimum
                                s.feesShortfall = feesShortfall
                                accounts
                                    .forEach((a, i) => {
                                        a.owing = fees.feesOwing[i]
                                        a.fee = fees.feesAccounts[i] + a.owing
                                    })
                            }
                            contract.loading = false
                            billing.preview(contract, true)
                        }
                    }
                } catch(e) {
                    console.log(e)
                }
            },
            currency(v) {
                return numeral(v)
                    .format(`$0,0.00`)
            },
            init() {
                var { string } = lib
                this.status = { text: '', color: '' }
                this.accounts = {
                    _keys: { number: '', name: '', value: 'Balance', scheduleName: 'Schedule', fee: '', owing: '.', billedTo: 'Billed To' },
                    get keys() {
                        var { _keys } = this
                        return Object.keys(_keys)
                            .map((value) => {
                                var label = _keys[value] ? (_keys[value] == '.' ? '' : _keys[value]) : string.capitalise(value)
                                return { label, value }
                            })
                    }
                }
                this.state = session.get('state')
                var { user: manager, company: firm } = this.state
                var billing = {
                    inclusive: 'Yes',
                    type: '',
                    get types() {
                        return [`Arrears`, `Advance`]
                    },
                    manager,
                    firm,
                    state: 0,
                    _error: (e) => {
                        this.message(e, true)
                    },
                    _status: (bill, id) => {
                        if (!bill)
                            return this.message()
                        if (bill.state == 2)
                            return this.message(`Done, ref: ${id}!`)
                        var { startDate: start, endDate: end, feeTotal, reference: ref } = bill
                        var fees = this.currency(feeTotal)
                        this.message(`Saving bill, ref: ${ref}, period: ${start} - ${end}, fees: ${fees}...`)
                    },
                    get disabled() {
                        var { feeTotal, reference } = this
                        return feeTotal > 0 && reference ? false : true
                    },
                    get header() {
                        var format = `MMM DD, YYYY`
                        var { reference, type, valuesDate, startDate, endDate } = this
                        var period
                        switch(type) {
                            case 'Advance':
                                var start = moment(valuesDate, format).add(1, 'day').format(format)
                                period = `${start} to ${endDate}`
                                break
                            default:
                                period = `${startDate} to ${endDate}`
                        }
                        var header = `New Bill`
                        if (endDate)
                            header += ` - Period: ${period}`
                        if (endDate && reference)
                            header += `, Reference: ${reference}`
                        return header
                    },
                    ready: false,
                    /*
                    fees: [],
                    get feeTotal() {
                        var fees = this.fees
                            .reduce((t, o) => t += +o.feeTotal, 0)
                        return +(fees / 1e4).toFixed(2)
                    },
                    */
                    reference: '',
                    householdContracts: [],
                    change: (type, index) => {
                        var { billing } = this
                        if (type == 'type') {
                            switch(billing.type) {
                                case 'Arrears':
                                    billing.startDate = billing.startDates[0]
                                    break
                                default:
                                    billing.startDate = billing.startDates[billing.startDates.length - 1]
                            }
                            billing.endDate = billing.endDates[billing.endDates.length - 1]
                        }
                        if (type == 'bill') {
                            var contract = billing.householdContracts[index]
                            if (!contract.bill)
                                contract.preview = false
                        }
                        this.calculate()
                    },
                    contract({ _id }) {
                        return `Id: ${_id}`
                    },
                    last(contract) {
                        var { lastBilled: last = 'Never' } = contract
                        return `Last Billed: ${last}`
                    },
                    get contracts() {
                        var [start, end] = [this.startDate, this.endDate]
                            .map(d => Format.date(d)) 
                        var c = this.householdContracts
                            .filter((c) => {
                                var records = c.records
                                    .filter(r => r.date >= start && r.date <= end)
                                return records.length > 0
                            })
                        return c
                    },
                    dates: [],
                    startDate: '',
                    get valuesDates() {
                        return this.dates
                    },
                    valuesDate: '',
                    get startDates() {
                        return this.dates
                    },
                    endDate: '',
                    get endDates() {
                        switch(this.type) {
                            case 'Arrears':
                                var index = this.dates.findIndex(d => d == this.startDate)
                                return this.dates.slice(index)
                            default:
                                var format = `MMM DD, YYYY`
                                var dates = [this.startDate]
                                var date
                                while(dates.length < 180) {
                                    date = dates[dates.length - 1]
                                    dates.push(moment(date, format).add(1, 'day').format(format))
                                }
                                return dates
                        }
                    },
                    get period() {
                        var format = `MMM DD, YYYY`
                        var start = this.type == 'Advance'
                            ? moment(this.valuesDate, format).add(1, 'day')
                            : moment(this.endDate, format)
                        var days = +(moment(this.endDate, format).diff(start) / 86400e3).toFixed()
                        if (this.inclusive == 'Yes')
                            days++
                        var suffix = days == 1 ? `day` : `days`
                        return `${days} ${suffix}`
                    },
                    async save() {
                        var { feeTotal, fees, reference, startDate, endDate, manager } = this
                        if (!feeTotal)
                            return this._error(`Cannot generate a bill with no fees!`)
                        if (!reference)
                            return this._error('Cannot generate a bill with no reference!')
                        var { _id: managerId } = manager
                        var created = new Date().toISOString()
                        var { id } = await firebase.db
                            .collection('invoices')
                            .add({ reference, startDate, endDate, fees, feeTotal, managerId, created })
                        this._status(this)
                        this.state = 1
                        await Sys.wait(3000)
                        this.state = 2
                        this._status(this, id)
                        await Sys.wait(2000)
                        this._status()
                    },
                    async preview(contract, dummy) {
                        if (contract.previewPending)
                            return
                        contract.previewPending = true
                        contract.preview = true
                        console.log({ contract })
                        var { inclusive, manager, reference, type } = this
                        var { _id: managerId } = manager
                        var format = { out: `MM/DD/YYYY`, in: `MMM DD, YYYY` }
                        var start = type == 'Advance'
                            ? moment(this.valuesDate, format.in).add(1, 'days')
                            : moment(this.startDate, format.in)
                        var invoice = {
                            date: moment().format(format.out),
                            reference,
                            period: {
                                start: start.format(format.out),
                                end: moment(this.endDate, format.in).format(format.out),
                                days: this.period.split(' ').shift(),
                                inclusive,
                                values: type == 'Advance' ? this.valuesDate : ''
                            },
                            managerId,
                            type
                        }
                        console.log({ invoice })
                        var [config, state] = session.get('config', 'state')
                        var { functions } = config.firebase
                        var url = `${functions}/api/html/invoice`
                        var data = { contract, invoice }
                        var resp = await axios.post(url, data)
                        contract.previewPending = false
                        if (dummy)
                            return console.log('dummy call success')
                        var tab = Tab.open()
                        if (!tab || !tab.document)
                            throw new Error(`Failed to create new window!`)
                        tab.document.open()
                        tab.document.write(resp.data.render)
                        tab.document.close()
                    },
                    owing(contract) {
                        return contract.accounts
                            .find(a => a.owing)
                    }
                }
                billing.type = firm.invoice.type ? string.capitalise(firm.invoice.type) : billing.types[0]
                this.billing = billing
                this.load()
            },
            async load() {
                try {
                    var { billing, state } = this
                    var { household, company: firm } = state
                    console.log({ household }) 
                    var contracts = [...await Household.contracts(household)]
                    console.log({ contracts })
                    var query
                    for (var contract of contracts) {
                        var { _id: contractId } = contract
                        query = firebase.db
                            .collection('records')
                            .where('contractId', '==', contractId)
                        contract.records = firebase.docs(await query.get())
                            .sort((a, b) => +a.date - b.date)
                        contract.bill = false
                        contract.preview = false
                        contract.accounts
                            .forEach((a) => {
                                a.value = 0
                                a.fee = 0
                                a.billedTo = a.billing < 0 ? `Self` : contract.accounts[a.billing].number
                                a.owing = 0
                            })
                        contract.schedules
                            .forEach((s) => {
                                s.fees = [],
                                s.splits = [],
                                s.total = { fees: 0, splits: 0 }
                                s.feesMinimum = 0
                                s.feesShortfall = 0
                            })
                        contract.preview = false
                        contract.previewPending = false
                        contract.loading = false
                    }
                    var dates = contracts
                        .reduce((arr, c) => {
                            c.records.forEach((r) => {
                                if (arr.find(d => d == r.date))
                                    return
                                arr.push(+r.date)
                            })
                            return arr
                        }, [])
                        .sort((a, b) => a - b)
                        .map(d => Format.date(d))
                    billing.dates = [...dates]
                    billing.startDate = dates[0]
                    billing.valuesDate = dates[dates.length - 1]
                    var format = `MMM DD, YYYY`
                    if (!billing.endDate) {
                        if (firm.invoice.type == 'advance') {
                            console.log('firm does it in advance...')
                            var add
                            switch(firm.invoice.period) {
                                case 'quarter':
                                    add = [3, 'months']
                                    break
                                default:
                                    throw new Error(`Invalid firm invoice period, ${firm.invoice.period}!`)
                            }
                            console.log({ add })
                            billing.endDate = moment(billing.valuesDate, format).add(...add).format(format)
                        } else {
                            console.log('using last')
                            billing.endDate = dates[dates.length - 1]
                        }
                    } else {
                        console.log('got a billing end date?')
                    }
                    billing.householdContracts = [...contracts]
                    billing.ready = true
                    this.calculate()
                } catch(e) {
                    console.log(e)
                }
            },
            async message(text = '', error = false) {
                var style = error ? Style.color('red') : Style.color('#2dbc4e')
                this.status = { text, style }
                if (!error)
                    return
                await Sys.wait(2500)
                this.message()
            },
            async save() {
            },
            show(show) {
                var { el } = this
                $(el).modal(show ? 'show' : 'hide')
            }
        }
    }
</script>