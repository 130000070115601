class Tab {

    static open(url = '', width = 1280, height = 720, menubar = 0, toolbar = 0) {
        var open = [
            url,
            `_blank`,
            `toolbar=${toolbar}, menubar=${menubar}, width=${width}, height=${height}`
        ]
        return window.open(...open)
    }

}

export default Tab