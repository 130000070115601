<template>
    <div class="h-100">
        <h5 class="bg-light p-3 border-bottom">
            <span>Dashboard</span>
            <i class="fab fa-youtube video" v-on:click="dashboard.video()"></i>
        </h5>
        <div class="mb-2" style="height: 80vh">
            <template v-if="dashboard.loading">
                <loading-component></loading-component>
            </template>
            <template v-else>
                <scroll-area>
                    <div class="m-3">
                        <div class="row">
                            <div class="col-6">
                                <dashboard-contract :dashboard="dashboard"></dashboard-contract>
                            </div>
                            <div class="col pl-0">
                                <dashboard-invoice :dashboard="dashboard"></dashboard-invoice>
                            </div>
                        </div>
                    </div>
                </scroll-area>
            </template>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    import { loading as loadingComponent, scrollArea } from '../../component/'
    import { skivy71, smartkx } from '../../npm.org'
    import { alert, events, firebase, firestore, session } from '../../service'

    import dashboardContract from './contract'
    import dashboardInvoice from './invoice'

    export default {
        get components() {
            return {
                dashboardContract,
                dashboardInvoice,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                dashboard: '',
                state: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
        },
        methods: {
            init() {
                var config = session.get(`config`)
                var state = session.get('state')
                var dashboard = {
                    _record: async (collection) => {
                        var token = await firebase.auth.currentUser
                            .getIdToken(true)
                        var { firestore } = smartkx
                        var data = firestore.data(
                            await firestore
                                .collection(collection)
                                .where(`householdId`, `==`, state.household._id)
                                .get(token)
                        )
                        return data
                            .sort((a, b) => new Date(b._created).getTime() - new Date(a._created).getTime())
                            .pop()
                    },
                    contract: ``,
                    get date() {
                        var format = `MMM D, YYYY`
                        return { format }
                    },
                    get document() {
                        var ref = (o) => `...${o._id.slice(-8)}` 
                        return { ref }
                    },
                    invoice: ``,
                    async load() {
                        if (this.loading)
                            return `already loading...`
                        this.loading = true
                        var [contract, invoice] = await Promise.all([`contracts`, `invoices`].map(c => this._record(c)))
                        this.contract = contract
                        this.invoice = invoice
                        this.loading = false
                        console.log({ invoice})
                    },
                    loading: false,
                    async video() {
                        try {
                            var src = await firebase.storageURL(`video/dashboard.mp4`)
                            events.$emit('video', { src, type: `video/mp4` })
                        } catch(e) {
                            console.log(e)
                            alert.error(e.message)
                        }
                    }
                }
                dashboard.load()
                this.dashboard = dashboard
                this.state = state
            }
        },
        watch: {
            'state.household': {
                handler() {
                    this.dashboard.load()
                }
            }
        }
    }
</script>