<template>
    <div class="ml-3 mr-5">
        <template>
            <table class="table table-sm table-borderless manual">
                <thead>
                    <tr>
                        <th colspan="4">Range</th>
                        <th>Rate</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(b, index) in schedule.breaks" :key="index">
                        <td>
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">$</div>
                                </div>
                                <input class="form-control form-control-sm" :value="b" readonly> 
                            </div>
                        </td>
                        <td class="middle-super">
                            <span>to</span>
                        </td>
                        <td>
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">$</div>
                                </div>
                                <template v-if="index < schedule.breaks.length - 1">
                                    <input class="form-control form-control-sm" v-model="schedule.breaks[index + 1]" placeholder="break" v-on:focus="focus('breaks', index + 1)" v-on:blur="blur('breaks', index + 1)">
                                </template>
                                <template v-else>
                                    <input class="form-control form-control-sm" value="∞" readonly>
                                </template>
                            </div>
                        </td>
                        <td class="middle-super">
                            <span>@</span>
                        </td>
                        <td>
                            <div class="input-group input-group-sm mb-2">
                                <input class="form-control form-control-sm rate" v-model="schedule.rates[index]" placeholder="rate" v-on:blur="blur('rates', index)">
                                <div class="input-group-append">
                                    <div class="input-group-text">%</div>
                                </div>
                            </div>
                        </td>
                        <td class="delete">
                            <button :disabled="!index" type="button" class="btn btn-sm btn-outline-danger" v-on:click="remove(index)">
                                <span>X</span>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td class="add">
                            <button class="btn btn-outline-warning btn-sm" v-on:click="add()">
                                <span>Add</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
    </div>
</template>

<style scoped>
    div.schedule {
        padding: 10px 10px 0 10px;
    }
    td.middle-super {
        vertical-align: middle;
    }
    td.middle-super span {
        vertical-align: super;
    }
    td.delete {
        width: 50px;
        text-align: right;
        vertical-align: middle;
    }
    td.delete button {
        margin-bottom: 7px;
    }
    td.range {
        width: 30%;
    }
    td.rate {
        width: 15%;
    }
    input.rate {
        text-align: right; 
    }
    th:nth-child(5) {
        width: 150px;
    }
</style>

<script>
    import { Format } from '../../../../factory'
    import { session } from '../../../../service'

    import numeral from 'numeral'

    export default {
        props: [`schedule`],
		data() {
			return {
			}
        },
        created() {
        },
		computed: {
		},
		methods: {
            _value(v) {
                return Number(String(v).split(',').join(''))
            },
            add() {
                var { breaks, rates } = this.schedule
                var last = {
                    break: breaks[breaks.length - 1],
                    rate: rates[rates.length - 1]
                }
                var push = {
                    break: numeral(last.break).value() + 1e6,
                    rate: numeral(last.rate).value() - 0.1
                }
                if (push.rate < 0)
                    return
                breaks.push(numeral(push.break).format('0,0'))
                rates.push(numeral(push.rate).format('0.00'))
            },
            blur(type, index) {
                var list = this.schedule[type]
                var value = type == 'rates' ? numeral(list[index]).format('0.00') : numeral(list[index]).format('0,0.[00]')
                list.splice(index, 1, value)
            },
			currency(v) {
				var value = Format.currency(v)
				return `$${value}`
            },
            focus(type, index) {
                var list = this.schedule[type]
                var value = type == 'breaks' ? numeral(list[index]).value() : '' 
                list.splice(index, 1, value)
            },
            remove(index) {
                var { breaks, rates } = this.schedule
                breaks.splice(index, 1)
                rates.splice(index, 1)
            }
		}
    }
</script>