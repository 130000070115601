<template>
    <div>
        <table class="table table-sm table-borderless manual">
            <thead>
                <tr>
                    <th>Text</th>
                    <th>Manager</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td>
                        <span v-text="item.text"></span>
                    </td>
                    <td>
                        <span v-text="item.manager"></span>
                    </td>
                    <td>
                        <span v-text="date(item.date)"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
</style>

<script>
    import moment from 'moment'

    export default {
        props: [`log`],
		data() {
			return {
			}
        },
        created() {
        },
		computed: {
            items() {
                return this.log.items
                    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            }
		},
		methods: {
            date(d) {
                var format = {
                    time: `HH:mm:ss`,
                    date: `MMM D, YYYY`
                }
                var day = {
                    now: new Date(Date.now()).getDate(),
                    record: new Date(d).getDate() 
                }
                return day.now == day.record ? moment(d).format(format.time) : moment(d).format(format.date)
            }
		}
    }
</script>