<template>
    <div class="card">
        <div class="card-header">
            <span>Contract</span>
        </div>
        <div class="card-body">
            <template v-if="dashboard.contract">
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th>
                                <span>Date</span>
                            </th>
                            <th>
                                <span>Reference</span>
                            </th>
                            <th>
                                <span>Status</span>
                            </th>
                            <th>
                                <span>Documents</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span v-text="contract.date"></span>
                            </td>
                            <td>
                                <span v-text="contract.ref"><span>
                            </td>
                            <td>
                                <span v-text="contract.status"></span>
                            </td>
                            <td class="docs">
                                <img class="contract" src="/img/ethereum-icon.svg" v-on:click="view.open('contract')">
                                <img class="pdf" src="/img/pdf-icon.svg" v-on:click="view.open('pdf')">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr>
                <p>
                    <strong class="pl-1">Breakdown</strong>
                </p>
                <div class="mx-2" v-for="schedule in contract.schedules" :key="schedule.name">
                    <p>
                        <em class="pl-1" v-text="schedule.name"></em>
                    </p>
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th>
                                    <span>Range</span>
                                </th>
                                <th>
                                    <span>Rate</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(range, index) in schedule.ranges" :key="index">
                                <td>
                                    <span v-text="range"></span>
                                </td>
                                <td>
                                    <span v-text="schedule.rates[index]"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <template v-if="schedule.minimum">
                        <em>
                            <small v-text="view.minimum(schedule)"></small>
                        </em>
                    </template>
                    <hr>
                    <p>
                        <em class="pl-1">Accounts</em>
                    </p>
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Custodian</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(account, index) in contract.accounts(schedule)" :key="index">
                                <td>
                                    <span v-text="account.number"></span>
                                </td>
                                <td>
                                    <span v-text="account.name"></span>
                                </td>
                                <td>
                                    <span v-text="account.type"></span>
                                </td>
                                <td>
                                    <span v-text="account.custodian"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-else>
                <em>No active contract</em>
            </template>
        </div>
    </div>
</template>

<style scoped>
    td.docs img:last-child {
        margin-left: 25px;
    }
    img.contract {
        width: 24px;
        cursor: pointer;
        margin-top: -5px;
    }
</style>

<script>
    import { Contract, lib, Sys, Tab } from '../../factory'
    import { alert, session, firebase } from '../../service'
    import { axios, moment, numeral } from '../../npm'
    import { smartkx } from '../../npm.org'

    export default {
        props: ['dashboard'],
        data() {
            return {
                view: ``
            }
        },
        created() {
            this.init()
        },
        computed: {
            contract() {
                var { string } = lib
                var { dashboard } = this
                var { contract: c } = dashboard
                var keys = [`date`, `ref`, `status`, `schedules`, `accounts`]
                return keys
                    .reduce((o, key) => {
                        switch(key) {
                            case `date`:
                                o[key] = moment(c[key], `YYYYMMDD`)
                                    .format(dashboard.date.format)
                                break
                            case `ref`:
                                o[key] = dashboard.document.ref(c)
                                break
                            case `status`:
                                o[key] = smartkx.contract.status[c.status] || `Deployed?`
                                break
                            case `schedules`:
                                function schedule(s) {
                                    var { minimum } = s
                                    var name = `Schedule - ${s.name}`
                                    var ranges = s.breaks
                                        .map((start, i) => {
                                            var end = s.breaks[i + 1]
                                            return `$${start} - ${end || `on`}`
                                        })
                                    var rates = s.rates
                                        .map(r => `${r}%`)
                                    return { minimum, name, ranges, rates }
                                }
                                o[key] = c.schedules
                                    .map(s => schedule(s))
                                break
                            case `accounts`:
                                function account(a) {
                                    var { name, number, scheduleName } = a
                                    var type = string.capitalise(a.type)
                                    var custodian = smartkx.account.custodian(a.custodian)
                                    return { custodian, name, number, scheduleName, type }
                                }
                                o._accounts = c.accounts
                                    .map(a => account(a))
                                o[key] = (s) => o._accounts
                                    .filter(a => a.scheduleName == s.name.split(`-`).pop().trim())
                                break
                            default:
                                throw new Error(`Unexpected key, ${key}!`)
                        }
                        return o
                    }, {})
            }
        },
        methods: {
            init() {
                var { dashboard } = this
                var view = {
                    minimum(s) {
                        return numeral(s.minimum).value()
                            ? `This schedule is subject to a minimum fee of $${s.minimum} (applied pro-rata)`
                            : ``
                    },
                    open(type) {
                        Contract.open(dashboard.contract, type)
                    }
                }
                this.view = view
            }
        }
    }
</script>