import { Contract } from '@smartkx/ethers'
import { firebase, session } from '../service'

//import testing from './testing'

class Blockchain {

    static _json(file) {
        return new Promise(async (resolve, reject) => {
            var files = session.get('files')
            if (files.get(file))
                return resolve(files.get(file))
            try {
                console.log(`Downloading json, ${file}...`)
                var json = await firebase.download(file)
                resolve(files.set(file, json).get(file))
            } catch(e) {
                reject(e)
            }
        })
    }

    static _init() {
        return new Promise(async (resolve, reject) => {
            try {
                var blockchain = session.get('blockchain')
                blockchain.contracts = new Map()
                blockchain.ready = true
                var { providers } = await firebase.download(`conf/blockchain.json`)
                var { ethereum } = window
                blockchain.providers = ethereum ? [...providers, { name: 'web3', instance: ethereum }] : [...providers]
                resolve()
            } catch(e) {
                reject(e)
            }
        })
    }

    /*
    static async frig() {
        await Blockchain._init()
        var [blockchain, config] = session.get('blockchain', 'config')
        var { abi, address, network } = config.house
        var contract = new Contract(abi, network, address, blockchain.providers)
        var tx = await contract.methods.setNumber(100000001)
        console.log({ tx })
    }
    */

    static contract(file, network, address) {
        return new Promise(async (resolve, reject) => {
            try {
                var blockchain = session.get('blockchain')
                if (!blockchain.ready)
                    await Blockchain._init()
                var { contracts, providers } = blockchain
                var contract = contracts.get(file)
                if (contract) {
                    contract.address = address
                    contract.network = network
                    return resolve(contract)
                }
                var { abi } = await Blockchain._json(file)
                contract = new Contract(abi, network, address, providers)
                resolve(contracts.set(file, contract).get(file))
            } catch(e) {
                reject(e)
            }
        })
    }

}

export default Blockchain