<template>
    <div>
        <div class="row">
            <div class="col-2">
                <div class="form-group">
                    <label>Number</label>
                    <input class="form-control form-control-sm" placeholder="Account number" v-model="form.data.number" v-on:focus="form.focus(`number`)" v-on:blur="form.blur(`number`)">
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label>Name</label>
                    <input class="form-control form-control-sm" placeholder="Account name" v-model="form.data.name" v-on:focus="form.focus(`name`)" v-on:blur="form.blur(`name`)">
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label>Custodian</label>
                    <select class="form-control form-control-sm" v-model="form.data.custodian" v-on:change="form.select('custodian')" ref="custodian">
                        <option v-for="(custodian, i) in form.custodians" :key="i">
                            <span v-text="custodian.label"></span>
                        </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <template v-if="!account.import">
                    <div class="action">
                        <button type="button" class="btn btn-success btn-block btn-sm" v-on:click="form.save()" :disabled="form.pending || !form.edit">
                            <template v-if="form.pending">
                                <i class="fa fa-spinner fa-spin"></i>
                            </template>
                            <template v-else>
                                <i class="fas fa-check-circle"></i>
                            </template>
                            <span class="label">Save</span>
                        </button>
                        <template v-if="account._id">
                            <button class="btn btn-sm btn-block btn-dark" v-on:click="form.audit()">
                                <i class="fas fa-history"></i>
                                <span class="label">Audit</span>
                            </button>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    div.row {
        margin: 0.5em 0;
    }
    div.row p {
        text-align: right;
    }
    div.action {
        margin-top: 32px;
        width: 6em;
    }
    button {
        text-align: left;
        padding-left: 15px;
        margin-bottom: 17px;
    }
    button i {
        width: 15px;
    }
    span.label {
        padding-left: 10px;
        color: white;
    }
</style>

<script>
    import { Api, Sys } from '../../factory'
    import { smartkx } from '../../npm.org'
    import { alert, events, session } from '../../service'

    export default {
        props: [`account`],
        data() {
            return {
                form: ``
            }
        },
        created() {
            this.init()
        },
        computed: {
        },
        methods: {
            init() {
                var state = session.get(`state`)
                var form = {
                    _edit: ``,
                    _account(a = {}) {
                        var { _id = ``, householdId, providerId } = a
                        var { data } = this
                        var { name, number } = data
                        var custodian = this.custodians
                            .find(c => c.label == data.custodian)
                            .value
                        return { _id, name, number, custodian, householdId, providerId }
                    },
                    _v(key, index) {
                        var { data } = this
                        return data[key]
                    },
                    audit: () => {
                        var { account } = this
                        events.$emit(`firm-menu`, { audit: { account }})
                    },
                    blur(key, index) {
                        var v = this._v(key, index)
                        if (v != this._edit)
                            this.edit++
                    },
                    get custodians() {
                        return smartkx.account.custodians
                            .map((value) => {
                                var label = smartkx.account.custodian(value)
                                return { label, value }
                            })
                    },
                    data: {
                        name: ``,
                        number: ``,
                        custodian: ``
                    },
                    edit: 0,
                    focus(key, index) {
                        this._edit = this._v(key, index)
                    },
                    init(a) {
                        var { data } = this
                        data.name = a.name
                        data.number = a.number
                        data.custodian = smartkx.account.custodian(a.custodian)
                        return this
                    },
                    pending: false,
                    save: async () => {
                        var { form } = this
                        var { _id: managerId } = state.user
                        if (form.pending)
                            return
                        form.pending = true
                        var account = form._account(this.account)
                        var data = { managerId, account }
                        try {
                            var resp = await Api.post(`account/save`, data)
                            var { message, refresh } = resp.data
                            alert.message(message, 3e3)
                            if (refresh)
                                events.$emit(`firmAccounts`, { refresh })
                        } catch(e) {
                            console.log(e.message)
                            alert.error(e.message)
                        } finally {
                            form.pending = false
                        }
                    },
                    select() {
                        if (!this.edit)
                            this.edit++
                    }
                }
                this.form = form.init(this.account)
            }
        }
    }
</script>