<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Contract help</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <p>Some helpful text here...</p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>

    export default {
        data() {
            return {
                view: ``
            }
        },
        created() {
//            this.init()
        },
        methods: {
            init() {
                var state = session.get(`state`)
                this.view ={
                    comments: ``,
                    get disabled() {
                        return !this.comments.length && !this.questions.length
                    },
                    questions: ``,
                    async submit() {
                        var { comments, questions } = this
                        try {
                            var { user: manager } = state
                            var data ={ comments, questions, manager }
                            var resp = await Api.post(`client/feedback`, data)
                            alert.message(resp.data, 3e3)
                        } catch(e) {
                            console.log(e)
                            alert.error(e.message)
                        }
                    }
                }
            }
        }
    }
</script>