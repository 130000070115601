<template>
    <div id="home" class="h-100 bg-light">
        <header>
    		<navbar-component></navbar-component>
        </header>
        <main>
            <template v-if="view.loading">
                <p class="loading">Loading...</p>
            </template>
            <template v-else>
                <component :is="view.component"></component>
            </template>
        </main>
    </div>
</template>

<style scoped>
</style>

<script>
    import { navbar as navbarComponent } from '../component'
    import { Contract, lib, Sys } from '../factory'
    import { Household } from '../model'
    import { smartkx } from '../npm.org'
    import { alert, auth, blockchain, events, firebase, session } from '../service'
    import { view as clientsView } from '../clients'
    import { view as firmView } from '../firm'
    import { view  as adminView } from '../admin'

    var { firestore } = smartkx

    export default {
        get components() {
            return {
                adminView,
                firmView,
                clientsView,
                navbarComponent
            }
        },
		data() {
			return {
                state: '',
                view: ''
			}
		},
		created() {
            var { navbar } = this
            this.events = { navbar: navbar.bind(this) }
            events.$watch(this.events)
            this.init()
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
            ready() {
                var { ready } = this.state
                return ready
            }
        },
		methods: {
			async _company(household) {
                var [company] = firestore.data(
                    await firestore
                        .collection(`companys`)
                        .doc(household.companyId)
                        .get(await firestore.token(firebase))
                )
                return company
            },
            async init() {
                var [data, state] = session.get('data', 'state')
                data.households = []
                state.ready = false
                state.mock = false
                state.household = ''
                state.contracts = []
                this.state = state
                this.view = {
                    _components: [`firm-view`, `clients-view`, `admin-view`],
                    _index: 0,
                    get component() {
                        return this._components[this._index]
                    },
                    loading: false,
                    ready: false,
                    select(v) {
                        var index
                        switch(v) {
                            case `clients`:
                            case `firm`:
                            case `admin`:
                                index = this._components
                                    .findIndex(c => c == `${v}-view`)
                                break
                        }
                        if (typeof index != 'number' || index < 0)
                            throw new Error(`Unable to determine view index for ${v}!!`)
                        this._index = index
                    }
                }
                var { view } = this
                try {
                    try {
                        view.loading = true
                        var { company, households } = await this.load(state.user)
                        state.company = company
                        var [household = ''] = households
                        state.ready = true
                        data.households = [...households.map(h => new Household(h))]
                        state.household = household
                        view.loading = false
                    } catch(e) {
                        alert.error(e.message)
                    }
                } catch(e) {
                    await alert.error(e.message, 3000)
                    auth.logout()
                }
            },
            navbar(e) {
                var { view } = this
                var [type] = Object.keys(e)
                var data = e[type]
                var item
                switch(type) {
                    case `select`:
                        item = data.item.toLowerCase()
                        view.select(item)
                        break;
                }
            },
            async load(user) {
                var { companyId } = user
                var households = firestore.data(
                    await firestore
                        .collection(`households`)
                        .where(`companyId`, `==`, user.companyId)
                        .get(await firestore.token(firebase))
                )
                .sort((a, b) => a.name.localeCompare(b.name))
                var company, contracts, schedule, values, quarters
                for (var household of households) {
                    if (!company)
                        company = await this._company(household)
                    household.contracts = []
                }
                return { company, households }
            }
		}
	}
</script>