<template>
    <div>
        <div v-for="schedule in table.schedules" :key="schedule.name">
            <hr>
            <p class="font-italic schedule">Schedule - 
                <span v-text="schedule.name"></span>
            </p>
            <p class="font-italic">Accounts</p>
            <table class="table table-sm table-borderless">
                <thead>
                    <tr>
                        <th>
                            <span>Number</span>
                        </th>
                        <th class="name">
                            <span>Name</span>
                        </th>
                        <th>
                            <span>Value</span>
                        </th>
                        <th>
                            <span>Fee</span>
                        </th>
                        <th>
                            <span>Billed To</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="account in table.accounts(schedule)" :key="account.number">
                        <td v-text="table.display(account, `number`)"></td>
                        <td v-text="table.display(account, `name`)"></td>
                        <td v-text="table.display(account, `value`)"></td>
                        <td v-text="table.display(account, `fee`)"></td>
                        <td v-text="table.display(account, `billedTo`)"></td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <hr>
                        </td>
                    </tr>
                    <tr class="total">
                        <td>
                            <span>Totals</span>
                        </td>
                        <td></td>
                        <td>
                            <span v-text="schedule.accountsTotal"></span>
                        </td>
                        <td>
                            <span v-text="schedule.fees"></span>
                        </td>
                    </tr>
                    <template v-if="schedule.note">
                        <tr>
                            <td colspan="5">
                                <p class="font-italic">Note: 
                                    <span v-text="schedule.note"></span>
                                </p>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <hr class="my-1 mr-5">
            <table class="mt-2 table table-sm table-borderless action">
                <tbody>
                    <tr>
                        <td>
                            <button class="btn btn-sm btn-block btn-dark" v-on:click="table.action(`audit`)">
                                <i class="fas fa-history"></i>
                                <span class="label">Audit</span>
                            </button>
                        </td>
                        <td>
                            <span class="desc">View the invoice audit logs</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>
    table.action td:first-child {
        width: 130px;
    }
    table.action td:nth-child(2) {
        vertical-align: inherit;
        padding-left: 20px;
    }
    p.schedule {
        font-weight: bold;
    }
    tr.total span {
        font-weight: bold;
    }
    div.schedule {
        padding: 10px 10px 0 10px;
    }
    td.middle-super {
        vertical-align: middle;
    }
    td.middle-super span {
        vertical-align: super;
    }
    td.delete {
        width: 50px;
        text-align: right;
        vertical-align: middle;
    }
    td.delete button {
        margin-bottom: 7px;
    }
    td.range {
        width: 30%;
    }
    td.rate {
        width: 15%;
    }
    input.rate {
        text-align: right; 
    }
    th:nth-child(5) {
        width: 150px;
    }
    th.name {
        width: 45%;
    }
</style>

<script>
    import { Contract } from '../../../factory'
    import { events } from '../../../service'

    import numeral from 'numeral'

    export default {
        props: [`invoice`],
		data() {
			return {
                table: ''
			}
        },
        created() {
            this.init()
        },
		computed: {
		},
		methods: {
            init() {
                var { invoice } = this
                this.table = {
                    _currency: (v, scale = Contract.scale()) => {
                        return numeral(v)
                            //.divide(scale)
                            .format(`$0,0.00`)
                    },
                    accounts(s) {
                        return invoice.contract.accounts
                            .filter(a => a.scheduleName == s.name)
                            .map((a) => {
                                var { name, number } = a
                                var acc = invoice.accounts
                                    .find(acc => acc.number == a.number)
                                var { billedTo } = acc
                                var fee = this._currency(acc.fee, Contract.scale('fee'))
                                var value = this._currency(acc.value)
                                return { billedTo, fee, name, number, value }
                            })
                    },
                    action(type) {
                        switch(type) {
                            case `audit`:
                                events.$emit(`household-menu`, { audit: { invoice }})
                                break
                            default:
                                throw new Error(`Invalid action type, ${type}!`)
                        }
                    },
                    display(a, key) {
                        return a[key]
                    },
                    get schedules() {
                        var { _currency } = this
                        return invoice.contract.schedules
                            .map((s, i) => {
                                var { name } = s
                                var schedule = invoice.schedules[i]
                                var fees = _currency(schedule.fees, Contract.scale('fees'))
                                return {
                                    get accountsTotal() {
                                        var total = invoice.contract.accounts
                                            .filter(a => a.scheduleName == s.name)
                                            .map(a => invoice.accounts.find(acc => acc.number == a.number))
                                            .reduce((t, a) => t += a.value, 0)
                                        console.log({ total })
                                        return _currency(!isNaN(total) ? total : 0)
                                    },
                                    fees,
                                    name,
                                    get note() {
                                        var minimum = schedule.feesMinimum ? _currency(schedule.feesMinimum) : ``
                                        var shortfall = schedule.feesShortfall ? _currency(schedule.feesShortfall) : `` 
                                        return shortfall
                                            ? `The above schedule incurrs a minimum fee of ${minimum} and the shortfall of ${shortfall} has been applied to the accounts, pro-rata.`
                                            : ``
                                    }
                                }
                            })
                    }
                }
            }
		}
    }
</script>