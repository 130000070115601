<template>
    <div>
        <template v-if="table.loading">
            <div class="p-5">
                <loading-component :loading="loading"></loading-component>
            </div>
        </template>
        <template v-else>
            <div v-for="(schedule, index) in table.contract.schedules" :key="index" class="pt-2">
                <template v-if="index">
                    <hr>
                </template>
                <p class="font-italic schedule m-1">Schedule - 
                    <span v-text="schedule.name"></span>
                </p>
                <div class="mx-3 my-2">
                    <!--<p class="font-italic m-1">Accounts</p>-->
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th class="number">
                                    <span>Number</span>
                                </th>
                                <th class="name">
                                    <span>Name</span>
                                </th>
                                <th>
                                    <span>Fee</span>
                                </th>
                                <th>
                                    <span>Value</span>
                                </th>
                                <th>
                                    <span>Billed To</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="account in table.accounts(schedule)" :key="account.number">
                                <td v-text="table.display(account, `number`)"></td>
                                <td v-text="table.display(account, `name`)"></td>
                                <td v-text="table.display(account, `fee`)"></td>
                                <td v-text="table.display(account, `value`)"></td>
                                <td v-text="table.display(account, `billedTo`)"></td>
                            </tr>
                            <tr>
                                <td colspan="5">
                                    <hr class="m-0">
                                </td>
                            </tr>
                            <tr class="total">
                                <td>
                                    <span>Totals</span>
                                </td>
                                <td></td>
                                <td>
                                    <span v-text="table.total(schedule, `fee`)"></span>
                                </td>
                                <td>
                                    <span v-text="table.total(schedule, `value`)"></span>
                                </td>
                            </tr>
                            <template v-if="schedule.note">
                                <tr>
                                    <td colspan="5">
                                        <p class="font-italic">Note: 
                                            <span v-text="schedule.note"></span>
                                        </p>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr class="mt-1">
            <p class="font-italic m-1">Billing</p>
            <div class="mx-3 my-2">
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th class="number">
                                <span>Number</span>
                            </th>
                            <th class="name">
                                <span>Name</span>
                            </th>
                            <th>
                                <span>Total</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="account in table.contract.accounts" :key="account.number">
                            <td>
                                <span v-text="table.display(account, 'number')"></span>
                            </td>
                            <td>
                                <span v-text="table.display(account, 'name')"></span>
                            </td>
                            <td>
                                <span v-text="table.billable(account)"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="my-1 mr-5">
            <table class="mt-2 table table-sm table-borderless action">
                <tbody>
                    <tr>
                        <td>
                            <button class="btn btn-sm btn-block btn-dark" v-on:click="table.action(`audit`)">
                                <i class="fas fa-history"></i>
                                <span class="label">Audit</span>
                            </button>
                        </td>
                        <td>
                            <span class="desc">View the invoice audit logs</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
    </div>
</template>

<style scoped>
    table.action td:first-child {
        width: 130px;
    }
    table.action td:nth-child(2) {
        vertical-align: inherit;
        padding-left: 20px;
    }
    th.number {
        width: 20%;
    }
    th.name {
        width: 30%;
    }
    div.row {
        margin: 0.5em 0;
    }
    /*
    div.row p {
        text-align: right;
    }
    */
    div.action {
        margin-top: 32px;
        width: 6em;
    }
    button {
        text-align: left;
        padding-left: 15px;
    }
    button i {
        width: 15px;
    }
    span.label {
        padding-left: 10px;
        color: white;
    }
</style>

<script>
    import { loading as loadingComponent } from '../../component'
    import { Api, Sys } from '../../factory'
    import { numeral } from '../../npm'
    import { smartkx } from '../../npm.org'
    import { alert, events, session } from '../../service'

    var { firebase, firestore } = smartkx

    export default {
        get components() {
            return {
                loadingComponent
            }
        },
        props: [`invoice`],
        data() {
            return {
                loading: ``,
                table: ``
            }
        },
        created() {
            this.init()
        },
        computed: {
        },
        methods: {
            init() {
                var { invoice } = this
                var state = session.get(`state`)
                this.loading = { class: `fa fa-sync fa-spin`, style: `font-size: 50px; opacity: 0.3` }
                var table = {
                    _total(s, key) {
                        var t = this.contract.accounts
                            .filter(a => a.scheduleName == s.name)
                            .reduce((t, a) => {
                                var account = invoice.accounts
                                    .find(acc => acc.number == a.number)
                                return t += account[key]
                            }, 0)
                        var scale = key == `fee` ? 1e4 : 1e2
                        return numeral(t)
                            //.divide(scale)
                            .format(`$0,0.00`)
                    },
                    accounts(s) {
                        console.log({ invoice })
                        return this.contract.accounts
                            .filter(a => a.scheduleName == s.name)
                            .map((a) => {
                                var { billedTo, fee, value } = invoice.accounts
                                    .find(acc => acc.number == a.number)
                                a.billedTo = billedTo
                                a.fee = fee
                                a.value = value
                                return a
                            })
                    },
                    action(type) {
                        switch(type) {
                            case `audit`:
                                events.$emit(`firm-menu`, { audit: { invoice }})
                                break
                            default:
                                throw new Error(`Invalid action type, ${type}!`)
                        }
                    },
                    billable(a) {
                        var { accounts } = this.contract
                        var index = accounts
                            .findIndex(acc => acc.number == a.number)
                        var account = accounts[index]
                        var t = account.billing == -1
                            ? invoice.accounts.find(acc => acc.number == a.number).fee
                            : 0
                        accounts
                            .forEach((acc) => {
                                if (acc.billing == -1)
                                    return
                                var account = accounts[acc.billing]
                                var { fee } = invoice.accounts
                                    .find(a => a.number == account.number)
                                t += fee
                            })
                        return numeral(t)
                            //.divide(1e4)
                            .format(`$0,00.00`)
                    },
                    display(i, key) {
                        switch(key) {
                            case `fee`:
                            case `value`:
                                var scale = key == `fee` ? 1e4 : 1e2
                                return numeral(i[key])
                               //     .divide(scale)
                                    .format(`$0,0.00`)
                            default:
                                return i[key]
                        }
                    },
                    total(s, key) {
                        var t = this.contract.accounts
                            .filter(a => a.scheduleName == s.name)
                            .reduce((t, a) => {
                                var account = invoice.accounts
                                    .find(acc => acc.number == a.number)
                                return t += account[key]
                            }, 0)
                        var scale = key == `fee` ? 1e4 : 1e2
                        return numeral(t)
                            //.divide(scale)
                            .format(`$0,0.00`)
                    },
                    /*

                                        _currency: (v, scale = Contract.scale()) => numeral(v).divide(scale).format(`$0,0.00`),
                    accounts(s) {
                        return invoice.contract.accounts
                            .filter(a => a.scheduleName == s.name)
                            .map((a) => {
                                var { name, number } = a
                                var acc = invoice.accounts
                                    .find(acc => acc.number == a.number)
                                var { billedTo } = acc
                                var fee = this._currency(acc.fee, Contract.scale('fee'))
                                var value = this._currency(acc.value)
                                return { billedTo, fee, name, number, value }
                            })
                    },
                    action(type) {
                        switch(type) {
                            case `audit`:
                                events.$emit(`household-menu`, { audit: { invoice }})
                                break
                            default:
                                throw new Error(`Invalid action type, ${type}!`)
                        }
                    },
                    display(a, key) {
                        return a[key]
                    },
                    get schedules() {
                        var { _currency } = this
                        return invoice.contract.schedules
                            .map((s, i) => {
                                var { name } = s
                                var schedule = invoice.schedules[i]
                                var fees = _currency(schedule.fees, Contract.scale('fees'))
                                return {
                                    get accountsTotal() {
                                        var total = invoice.contract.accounts
                                            .filter(a => a.scheduleName == s.name)
                                            .map(a => invoice.accounts.find(acc => acc.number == a.number))
                                            .reduce((t, a) => t += a.value, 0)
                                        console.log({ total })
                                        return _currency(!isNaN(total) ? total : 0)
                                    },
                                    fees,
                                    name,
                                    get note() {
                                        var minimum = schedule.feesMinimum ? _currency(schedule.feesMinimum) : ``
                                        var shortfall = schedule.feesShortfall ? _currency(schedule.feesShortfall) : `` 
                                        return shortfall
                                            ? `The above schedule incurrs a minimum fee of ${minimum} and the shortfall of ${shortfall} has been applied to the accounts, pro-rata.`
                                            : ``
                                    }
                                }
                            })
                    */
                    contract: ``,
                    async load() {
                        if (this.loading)
                            return
                        this.loading = true
                        var [contract] = firestore.data(
                            await firestore
                                .collection(`contracts`)
                                .doc(invoice.contractId)
                                .get(await firestore.token(firebase))
                        )
                        this.contract = contract
                        this.loading = false
                    },
                    loading: false
                }
                table.load()
                this.table = table
                /*
                var form = {
                    _edit: ``,
                    _account(a = {}) {
                        var { _id = ``, householdId, providerId } = a
                        var { data } = this
                        var { name, number } = data
                        var custodian = this.custodians
                            .find(c => c.label == data.custodian)
                            .value
                        return { _id, name, number, custodian, householdId, providerId }
                    },
                    _v(key, index) {
                        var { data } = this
                        return data[key]
                    },
                    audit: () => {
                        var { account } = this
                        events.$emit(`firm-menu`, { audit: { account }})
                    },
                    blur(key, index) {
                        var v = this._v(key, index)
                        if (v != this._edit)
                            this.edit++
                    },
                    get custodians() {
                        return smartkx.account.custodians
                            .map((value) => {
                                var label = smartkx.account.custodian(value)
                                return { label, value }
                            })
                    },
                    data: {
                        name: ``,
                        number: ``,
                        custodian: ``
                    },
                    edit: 0,
                    focus(key, index) {
                        this._edit = this._v(key, index)
                    },
                    init(a) {
                        var { data } = this
                        data.name = a.name
                        data.number = a.number
                        data.custodian = smartkx.account.custodian(a.custodian)
                        return this
                    },
                    pending: false,
                    save: async () => {
                        var { form } = this
                        var { _id: managerId } = state.user
                        if (form.pending)
                            return
                        form.pending = true
                        var account = form._account(this.account)
                        var data = { managerId, account }
                        try {
                            var resp = await Api.post(`account/save`, data)
                            var { message, refresh } = resp.data
                            alert.message(message, 3e3)
                            if (refresh)
                                events.$emit(`firmAccounts`, { refresh })
                        } catch(e) {
                            console.log(e.message)
                            alert.error(e.message)
                        } finally {
                            form.pending = false
                        }
                    },
                    select() {
                        if (!this.edit)
                            this.edit++
                    }
                }
                this.form = form.init(this.account)
                */
            }
        }
    }
</script>