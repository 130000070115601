<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom shadow-sm sticky-top nav-fill">
        <a class="navbar-brand mb-0 h1" href="#">
            <img class="logo" src="/img/skx-logo.png">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="btn-group btn-group-sm mr-auto">
                <button type="button" class="btn" :class="nav.class(index)" v-for="(item, index) in nav.items" :key="index" v-on:click="nav.select(index)">
                    <span v-text="item"></span>
                </button>
            </div>
            <template v-if="state.ready">
                <!--
                <div class="button mr-2">
                    <button class="btn btn-sm btn-warning" v-on:click="nav.play()">
                        <span>Play</span>
                    </button>
                </div>
                -->
                <div class="button mr-2">
                    <button class="btn btn-sm btn-success" v-on:click="nav.comment()">
                        <i class="far fa-comment mr-1"></i>
                        <span>Comments / Questions</span>
                    </button>
                </div>
                <div class="button user">
                    <div class="dropdown">
                        <button class="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="far fa-user mr-1"></i>
                            <span v-text="user.name"></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" v-on:click="logout">
                                <span>Logout</span>
                            </a>
                        </div>
                    </div>
                </div>
                <template v-if="user.mock">
                    <div class="button ml-3">
                        <button class="btn btn-sm btn-success" v-text="mockText" v-on:click="mockToggle"></button>
                    </div>
                </template>
            </template>
        </div>
    </nav>
</template>

<style scoped>
    img.logo {
        margin-top: -4px;
        width: 170px;
    }
    div.btn-group button {
        min-width: 70px !important;
    }

    div.dropdown span:nth-child(1) {
        padding-right: 5px;
    }
</style>

<script>
    import events from '@skivy71/events'
    import { lib } from '../factory'
    import { Household } from '../model'
    import { alert, auth, firebase, session } from '../service'

    export default {
		get components() {
            return {}
        },
		data() {
			return {
                nav: '',
				state: ''
			}
		},
		created() {
			this.init()
        },
        mounted() {
            this.nav.select(1)
        },
		computed: {
            demo() {
                var { company } = this.state
                return true//company._id == `S2hVNEIG63wRq59zHcK3`
            },
			hello() {
                var { string } = lib
                var { company, user } = this.state
                return `Welcome, ${user.name}`
                var { name } = user
                var firm = company ? Household.company(company) : ''
                return firm ? `${name}, ${firm}` : name
			},
            isAdmin() {
                return this.state.user.role == `admin`
            },
			mockText() {
				var { mock } = this.state
				var mode = mock ? 'On' : 'Off'
				return `Mock: ${mode}`
            },
            user() {
                return this.state.user
            }
		},
		methods: {
			init() {
                var state = session.get('state')
                if (!state.hasOwnProperty('mock'))
                    this.$set(state, 'mock', false)
                if (!state.hasOwnProperty('ready'))
                    this.$set(state, 'ready', false)
                //state.mock = true
                this.state = state
                this.nav = {
                    _index: 0,
                    get brand() {
                        return `SmartKx`
                    }, 
                    class(index) {
                        return index == this._index ? `btn-secondary` : `btn-outline-secondary`
                    },
                    comment() {
                        var component = `comments`
                        var data = {}
                        events.$emit(`modal`, { component, data, class: `modal-lg` })
                    },
                    get items() {
                        var items = [`Firm`, `Clients`]
                        if (state.user.role == `admin`)
                            items.push(`Admin`)
                        return items
                    },
                    async play() {
                        var src = await firebase.storageURL(`video/Intro.mp4`)
                        //console.log({ src })
                        events.$emit('video', { src, type: `video/mp4` })
//                        : `https://www.loom.com/share/e1ea4e940d5b4a79bf2c0659e3e3d956`, type: `video/mp4` })
                    },
                    select(index) {
                        this._index = index
                        var item = this.items[index]
                        var select = { index, item }
                        events.$emit(`navbar`, { select })
                    }
                }
			},
			logout() {
                auth.logout()
			},
			mockToggle() {
				var { state } = this
				state.mock = !state.mock
			}
		}
	}
</script>