<template>
    <div class="row h-100">
        <template v-if="sidebar.reveal">
            <div class="reveal">
                <button class="btn btn-light" v-on:click="sidebar.toggle()">></button>
            </div>
        </template>
        <aside class="bg-light px-0" :style="sidebar.style">
            <template v-show="sidebar.content">
                <firm-info :sidebar="sidebar"></firm-info>
                <firm-menu :sidebar="sidebar"></firm-menu>
            </template>
        </aside>
        <main class="col bg-white px-0">
            <firm-main :sidebar="sidebar"></firm-main>
        </main>
    </div>
</template>

<style scoped>
    div.row {
        margin: 0;
    }
    div.reveal {
        position: absolute;
        top: 50%;
        left: -10px;
        z-index: 100000;
    }
    div.reveal button {
        padding-left: 12px;
        width: 22px;
        cursor: pointer;
    }
    aside {
        transition: width 0.5s;
        overflow: hidden;
    }
</style>

<script>
    import { lib, Sys } from '../factory'
    import firmInfo from './info'
    import firmMain from './main'
    import firmMenu from './menu'

    export default {
        get components() {
            return {
                firmInfo,
                firmMain,
                firmMenu
            }
        },
        data() {
            return {
                sidebar: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
            company() {
                var { company } = this.state
                return company
            },
            household() {
                var { household } = this.state
                return household
            },
        },
        methods: {
            init() {
                this.sidebar = {
                    _active: true,
                    _width: 350,
                    content: true,
                    reveal: false,
                    get style() {
                        var { _active, _width } = this
                        return _active ? `width: ${_width}px` : `width: 0`
                    },
                    async toggle() {
                        var { _active } = this
                        this._active = !_active
                        if (this._active) {
                            this.reveal = false
                            await Sys.wait(350)
                            this.content = true
                        } else {
                            this.content = false
                            await Sys.wait(100)
                            this.reveal = true
                        }
                    },
                    views: [
                        //`dashboard`,
                        `contacts`,
                        `audit`,
                        'clients',
                        `accounts`,
                        `billing`,
                        //`settings`
                    ]
                }
            }
        }
    }
</script>