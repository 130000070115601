<template>
    <div class="h-100">
        <template v-if="mock">
            <mock-dashboard></mock-dashboard>
        </template>
        <template v-else>
            <div class="h-100">
                <h5 class="bg-light p-3 border-bottom">Dashboard</h5>
                <div class="h-100 m-3">
                    <div class="row">
                        <div class="col-8 border-right">
                            <div class="card">
                                <h6 class="card-header text-truncate" title="Orphaned Accounts">Orphaned Accounts <span class="small">({{json.orphaned.length}})</span></h6>
                                <div class="card-body">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th class="border-top-0">Name</th>
                                                <th class="border-top-0">Number</th>
                                                <th class="border-top-0">Custodian</th>
                                                <th class="border-top-0 text-right">Value</th>
                                                <th class="border-top-0 text-right">Open Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(a, i) in json.orphaned" :key="i">
                                                <td>{{a.name}}</td>
                                                <td>{{a.number}}</td>
                                                <td>{{a.custodian}}</td>
                                                <td class="text-monospace text-right">{{display(a.amount)}}</td>
                                                <td class="text-right">{{a.opendate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <h4 class="border-bottom pb-1 border-bottom-0">Assets Under Management</h4>
                            <div class="card text-center">
                                <div class="card-header">
                                    <h6 class="border-bottom pb-2">Current Quarter: Q1 2020</h6>
                                    <div class="row">
                                        <div class="col-6 border-right">
                                            AUM
                                        </div>
                                        <div class="col-6">
                                            Fees (To-Date)
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6 border-right font-monospace">
                                            $233,732,882
                                        </div>
                                        <div class="col-6 font-monospace font-warning">
                                            $1,293,188
                                        </div>
                                    </div>
                                </div>
                                <div class="card-header border-top">
                                    <h6 class="border-bottom pb-2">Previous Quarter: Q4 2019</h6>
                                    <div class="row">
                                        <div class="col-6 border-right">
                                            AUM
                                        </div>
                                        <div class="col-6">
                                            Billed
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6 border-right font-monospace">
                                            $214,120,074
                                        </div>
                                        <div class="col-6">
                                            $2,623,448
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>
</style>

<script>
    import json from './data.json'
    import numeral from 'numeral'
    import mockDashboard from './mock'
    import { session } from '../../service'

    export default {
        get components() {
            return {
                mockDashboard
            }
        },
        data() {
            return {
                state: '',
                json: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
            mock() {
                var { mock } = this.state
                return mock
            }
        },        
        methods: {
            init() {
                this.json = json
                this.state = session.get(`state`)
            },
            display(val) {
                return numeral(val).format('$0,0.00')
            }
        }
    }
</script>