<template>
    <div class="h-100">
        <template v-if="table.loading">
            <div class="h-100">
                <loading-component></loading-component>
            </div>
        </template>
        <template v-else>
            <scroll-area>
                <div class="my-3 mx-4">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th class="active"></th>
                                <th>
                                    <span>Active</span>
                                </th>
                                <th>
                                    <span>Type</span>
                                </th>
                                <th>
                                    <span>Id</span>
                                </th>
                                <th>
                                    <span>Date</span>
                                </th>
                                <th>
                                    <span>Status</span>
                                </th>
                                <th>
                                    <span>Schedules</span>
                                </th>
                                <th>
                                    <span>Accounts</span>
                                </th>
                                <th>
                                    <span>Documents</span>
                                </th>
                                <th>
                                    <span>Created</span>
                                </th>
                                <th>
                                    <span>Updated</span>
                                </th>
                                <th>
                                    <i class="fas fa-redo" v-on:click="table.load()"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in table.data" :key="index">
                                <template v-if="index % 2 == 0">
                                    <td>
                                        <i class="fas toggle" :class="table.class('caret', index)" v-on:click="table.toggle(index)"></i>
                                    </td>
                                    <td>
                                        <i class="far" :class="table.class('check', index)"></i>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'type')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'id')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'date')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'status')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'schedules')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'accounts')"></span>
                                    </td>
                                    <td class="docs">
                                        <img class="contract" src="/img/ethereum-icon.svg" v-on:click="table.open(item, 'contract')">
                                        <img class="pdf" src="/img/pdf-icon.svg" v-on:click="table.open(item, 'pdf')">
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'created')"></span>
                                    </td>
                                    <td>
                                        <span v-text="table.display(item, 'updated')"></span>
                                    </td>
                                    <td>
                                        <i class="far fa-trash-alt remove" v-on:click="table.remove(item, index)"></i>
                                    </td>
                                </template>
                                <template v-else-if="table.contract(index)">
                                    <td></td>
                                    <td colspan="10">
                                        <contract-detail :contract="table.contract(index)"></contract-detail>
                                    </td>
                                    <td></td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </scroll-area>
        </template>
    </div>
</template>

<style scoped>
    td.docs img:last-child {
        margin-left: 25px;
    }
    i.fas.fa-redo {
        cursor: pointer;
    }
    i.remove {
        cursor: pointer;
    }
    i.remove:hover {
        color: red;
    }
    div.col-10 {
        text-align: right;
    }
    button {
        margin: 4px 0;
    }
    i.click {
        cursor: pointer;
    }
    img.contract {
        width: 24px;
        cursor: pointer;
        margin-top: -5px;
    }
    button {
        margin-top: -5px;
    }
    button i {
        padding: 0 5px;
    }
        i.fas.toggle {
        cursor: pointer;
    }
    th.active  {
        width: 30px;
    }
</style>

<script>
    import { loading as loadingComponent, scrollArea } from '../../../component'
    import { Api, Contract, lib, Sys, Tab } from '../../../factory'
    import { Household } from '../../../model'
    import { alert, events, firebase, session } from '../../../service'
    import { moment } from '../../../npm'
    import { smartkx } from '../../../npm.org'

    import contractDetail from './detail'

    export default {
        get components() {
            return {
                contractDetail,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                table: '',
                state: ''
            }
        },
        created() {
            var { contractEvent } = this
            this.events = { contract: contractEvent.bind(this) }
            events.$watch(this.events)
            this.init()
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
        },
        methods: {
            contractEvent(e) {
                var [type] = Object.keys(e)
                switch(type) {
                    case `deploy`:
                    case `initialize`:
                    case `refresh`:
                        this.table.load()
                        break
                }
            },
            init() {
                var { string } = lib
                var state = session.get('state')
                var table = {
                    _active: -1,
                    _contract(contract) {
                        return contract.type == `linked`
                            ? this._data.find(c => c._id == contract.parentId)
                            : contract
                    },
                    _data: [], 
                    _date(d) {
                        var day = {
                            now: new Date(Date.now()).getDate(),
                            record: new Date(d).getDate() 
                        }
                        return day.now == day.record ? moment(d).format(this._timeFormat) : moment(d).format(this._dateFormat)
                    },
                    _timeFormat: `HH:mm:ss`,
                    _dateFormat: `MMM D, YYYY`,
                    class(type, index) {
                        switch(type) {
                            case `check`:
                                var c = this.data[index]
                                return c.active ? `fa-${type}-square` : `fa-square`
                            case `caret`:
                                var fa = `fa-${type}`
                                return this._active == index ? `${fa}-down` : `${fa}-right`
                            default:
                                throw new Error(`Invalid class type, ${type}!`)
                        }
                    },
                    contract(index) {
                        var active = index - 1
                        return this._active == active ? this.data[active] : ''
                    },
                    get data() {
                        return this._data
                            .filter(c => c.householdId == state.household._id)
                            .reduce((list, c) => {
                                list.push(...[c, null])
                                return list
                            }, [])
                    },
                    display(c, key) {
                        var { string } = lib
                        switch(key) {
                            case `date`:
                                return moment(c[key], `YYYYMMDD`).format(this._dateFormat)
                            case `id`:
                                return `...${c._id.slice(-6)}`
                            case `created`:
                            case `updated`:
                                var contract = this._contract(c)
                                return this._date(contract[`_${key}`]) 
                            case `executed`:
                                var contract = this._contract(c)
                                return contract.executed ? this._date(contract.executed) : `...`
                            case `accounts`:
                            case `schedules`:
                                var contract = this._contract(c)
                                return contract[key].length
                            case `type`:
                                return string.capitalise(c[key])
                            case `status`:
                                var contract = this._contract(c)
                                return smartkx.contract.status[contract.status]
                            default:
                                return c[key]
                        }
                    },
                    async load() {
                        if (this.loading)
                            return console.log('table loading...')
                        this.loading = true
                        var contracts = [...await Household.contracts(state.household)]
                            .sort((a, b) => new Date(b._created).getTime() - new Date(a._created).getTime())
                        var linked = []
                        for (var c of contracts.filter(c => c.type == `linked`)) {
                            var [contract] = firebase.docs(
                                await firebase.db
                                    .collection(`contracts`)
                                    .where(firebase.documentId(), `==`, c.parentId)
                                    .get()
                            )
                            linked.push(contract)
                        }
                        this._data = [...contracts, ...linked]
                        this.loading = false
                    },
                    loading: false,
                    open(c, type) {
                        Contract.open(this._contract(c), type)
                    },
                    async remove(contract, i, confirm) {
                        try {
                            var { _data: contracts } = this
                            var index = contracts
                                .findIndex(c => c._id == contract._id)
                            if (index < 0)
                                throw new Error(`Unable to find index for contract, ${c._id}!`)
                            var c = contracts[index]
                            if (c.status > 2 && !state.user.demo)
                                return alert.warning(`This contract has been signed and cannot be removed!`, 3e3)
                            if (!confirm) {
                                events.$emit(`confirm`, { operation: `contract deletion`, prompt: `Are you really, really sure???`, data: [contract, i] })
                                events.$once(`confirmed`, ({ data, confirmed }) => {
                                    if (!confirmed)
                                        return console.log('not confirmed!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
                                    this.remove(...data, true)
                                })
                            } else {
                                var { _id: managerId } = state.user
                                var { _id: contractId } = contract
                                var data = { contractId, managerId }
                                try {
                                    var resp = await Api.post(`contract/delete`, data)
                                    alert.message(`Contract has been deleted!`, 3e3)
                                    await Sys.wait(1.5e3)
                                    this.load()
                                    /*
                                    console.log(resp.data)
                                    contracts.splice(index, 1)
                                    */                      
                                } catch(e) {
                                    console.log(e)
                                    console.log(e.message)
                                    alert.error(e.message)
                                }
                            }
                        } catch(e) {
                            alert.error(e.message)
                        }
                    },
                    toggle(index) {
                        this._active = this._active == index ? -1 : index
                    }
                }
                table.load()
                this.state = state
                this.table = table
            }
        },
        watch: {
            'state.household': {
                handler() {
                    this.table.load()
                }
            }
        }
    }
</script>