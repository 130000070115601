import { skivy71 } from '../npm.org'
import { session } from '../service'

class Api {

    static _path(p) {
        var path = p[0] == `/` ? p.slice(1) : p
        return path[path.length - 1] == `/` ? path : `${path}/`
    }

    static _url(path = `/`) {
        var config = session.get(`config`)
        return `${config.api.url}/${Api._path(path)}`
    }

    static get(path) {
        return skivy71.http.get(Api._url(path))
    }

    static post(path, data) {
        return skivy71.http.post(Api._url(path), data)
    }

}

module.exports = Api