<template>
    <div class="root" :style="alert.style">
        <div class="alert" :class="alert.data.class" role="alert" v-on:click="alert.hide()">
            <span v-text="alert.data.text"></span>
        </div>
	</div>
</template>

<style scoped>
    div.root {
        position: fixed;
        width: 500px;
        z-index: 1000000;
        left: 50%;
        margin-left: -250px;
    }
    div.root {
        transition: all 0.5s;
    }
    div.alert {
        cursor: pointer;
        width: 100%;
    }
    span {
        text-align: center;
        display: block;
    }
</style>

<script>
    import events from '@skivy71/events'
    import { sys } from '@skivy71/utils'

    export default {
        data() {
            return {
                alert: ''
            }
        },
        created() {
            this.init()
            var { alert } = this
            this.events = events.$watch({
                alert: alert.message.bind(alert)
            })
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
        },
        methods: {
            init() {
                var alert = {
                    _active: '',
                    data: {},
                    hide() {
                        this._active = false
                        return this
                    },
                    async message(data) {
                        this.data = { ...data }
                        this._active = true
                        if (!isNaN(data.hide) && data.hide > 0) {
                            await sys.wait(data.hide)
                            this.hide()
                        }
                    },
                    get style() {
                        var px = this._active ? 70: -100
                        return `top: ${px}px`
                    }
                }
                this.alert = alert.hide()
            }
        }
    }
</script>