export default (() => {

    class Email {
        static valid(email = '') {
            var [acc, domain = ''] = email.split('@')
            if (acc.length ) {
                var [label, tld = ''] = domain.split('.')
                if (label.length && tld.length)
                    return true
            }
        }
    }
      
    return Email
      
})()