<template>
    <div class="m-3">
        <table class="table table-sm table-borderless">
            <tbody>
                <tr>
                    <td>
                        <h5>Household</h5>
                    </td>
                    <td class="hide">
                        <button type="button" class="btn btn-sm btn-outline-secondary" v-on:click="hide">Hide</button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>
                            <strong>Name</strong>:
                        </span>
                    </td>
                    <td class="household-select">
                        <select class="form-control form-control-sm" v-model="select.householdName" v-on:change="select.household()" ref="household">
                            <option v-for="household in select.households" :key="household.ref">
                                <span v-text="household.name"></span>
                            </option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>
                            <strong>Email</strong>:
                        </span>
                    </td>
                    <td>
                        <span v-text="household.email"></span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>
                            <strong>Reference</strong>:
                        </span>
                    </td>
                    <td>
                        <span v-text="household.ref"></span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>
                            <strong>Company</strong>:
                        </span>
                    </td>
                    <td>
                        <span v-text="company"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
    td.household-select select {
        margin: -3px 0 0 -14px;
    }

    /*
    div.root {
        padding: 10px 10px 0 15px;
    }
    */
    td.hide {
        text-align: right;
    }
    td.hide button {
        margin-top: -5px;
    }
    span {
        font-size: 14px;
    }
    table td:first-child {
        width: 10px;
    }
</style>

<script>
    import { Household } from '../model'
    import { session } from '../service'

    export default {
        props: ['sidebar'],
        data() {
            return {
                select: '',
                state: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
            company() {
                var { company } = this.state
                return Household.company(company)
            },
            household() {
                var { household } = this.state
                return household
            }
        },
        methods: {
            hide() {
                var { sidebar } = this
                sidebar.toggle()
            },
            init() {
                var [data, state] = session.get('data', 'state')
                this.state = state
                this.select = {
                    household() {
                        state.household = data.households
                            .find(h => h.name == this.householdName)
                    },
                    householdName: state.household.name,
                    get households() {
                        return data.households
//                            .sort((a, b) => a.name.localeCompare(b.name))
                    }
                }
            }
        }
    }
</script>