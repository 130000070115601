<template>
    <div class="h-100">
        <h5 class="bg-light p-3 border-bottom">
            <span>Contracts</span>
            <i class="fab fa-youtube video" v-on:click="nav.video()"></i>
        </h5>
        <div class="mb-2" style="height: 80vh">
            <div class="tab-list mx-3 pt-3">
                <ul class="nav nav-tabs">
                    <li class="nav-item" v-for="(tab, index) in nav.tabs" :key="index">
                        <a class="nav-link" v-on:click="nav.select(index)" :class="nav.class(index)" :style="nav.style(index)">
                            <span v-text="tab"></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-components pt-2">
                <component :is="nav.component"></component>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    import existingContracts from './existing'
    import newContract from './new'
    import { lib } from '../../factory'
    import { Household } from '../../model'
    import { moment } from '../../npm'
    import { alert, events, firebase, session } from '../../service'

    export default {
        get components() {
            return {
                existingContracts,
                newContract
            }
        },
        data() {
            return {
                nav: ''
            }
        },
        created() {
            var { summary } = this
            this.events = events.$watch({
                summary: summary.bind(this) 
            })
            this.init()
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            init() {
                var { string } = lib
                this.nav = {
                    _components: ['existing-contracts', `new-contract`],
                    _index: 0,
                    get component() {
                        return this._components[this._index]
                    },
                    class(index) {
                        return index == this._index ? 'active' : '' 
                    },
                    select(index) {
                        this._index = index
                    },
                    style(index) {
                        var [cursor, color] = this._index == index ? ['default', 'inherit'] : ['pointer', '#007bff']
                        return [`cursor: ${cursor}`, `color: ${color}`]
                            .join(';')
                    },
                    get tabs() {
                        return this._components
                            .map((c) => {
                                switch(c) {
                                    case `existing-contracts`:
                                        return `Existing`
                                    default:
                                        return c.split(`-`).map(s => string.capitalise(s)).join(` `)
                                }
                            })
                    },
                    async video() {
                        try {
                            var video = this._index ? `contract-1.mp4` : `contract-2.mp4`
                            var src = await firebase.storageURL(`video/${video}`)
                            events.$emit('video', { src, type: `video/mp4` })
                        } catch(e) {
                            console.log(e)
                            alert.error(e.message)
                        }
//                        alert.message(`Coming soon, index ${this._index}!`, 1e3)
                    }
                }
            },
            summary(e) {
                if (e.saved)
                    this.nav.select(0)
            }
        }
    }
</script>