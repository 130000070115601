<template>
    <div>
        <div class="row">
            <div class="col-12 border-bottom">
                <h3>
                    Billing
                    <small class="text-muted">Monlthy Billing on Average Daily Value</small>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-4 border-right">
                <div class="border-bottom py-3">
                    <a href="#" class="float-right mr-2 mt-1 small">Download Annual Report</a>
                    <h4 class="mb-0"><i class="fa-fw fas fa-caret-down"></i> 2019</h4>
                </div>

                <a href="#" class="d-block py-3 border-bottom text-decoration-none text-body">
                    <i class="fa-fw fas fa-clock fa-2x float-left ml-3 mr-3 mt-2"></i>
                    <div>
                        <span class="badge badge-warning float-right mt-1 mr-2">Incomplete</span>
                        <strong>August 2019</strong><br>
                        <small>$1,192.76 To-Date</small>
                    </div>
                </a>

                <a href="#" class="d-block py-3 border-bottom text-decoration-none bg-primary text-white">
                    <i class="fa-fw fas fa-clock fa-2x float-left ml-3 mr-3 mt-2"></i>
                    <div>
                        <span class="badge badge-danger float-right mt-1 mr-2">Not Billed</span>
                        <strong>July 2019</strong><br>
                        <small>Incomplete</small>
                    </div>
                </a>

                <a href="#" class="d-block py-3 border-bottom text-decoration-none text-body">
                    <i class="fa-fw fas fa-file-invoice fa-2x float-left ml-3 mr-3 mt-2"></i>
                    <div>
                        <span class="badge badge-danger float-right mt-1 mr-2">Updated</span>
                        <strong>June 2019</strong><br>
                        <small class="text-secondary">$3,192.76 on Jul 3, 2019</small>
                    </div>
                </a>

                <a href="#" class="d-block py-3 border-bottom text-decoration-none text-body">
                    <i class="fa-fw fas fa-clock fa-2x float-left ml-3 mr-3 mt-2"></i>
                    <div>
                        <span class="badge badge-danger float-right mt-1 mr-2">Not Billed</span>
                        <strong>May 2019</strong><br>
                        <small class="text-secondary">$3,192.76 Outstanding</small>
                    </div>
                </a>

                <a href="#" class="d-block py-3 border-bottom text-decoration-none text-body">
                    <i class="fa-fw fas fa-file-invoice fa-2x float-left ml-3 mr-3 mt-2"></i>
                    <div>
                        <span class="badge badge-success float-right mt-1 mr-2">Up To Date</span>
                        <strong>April 2019</strong><br>
                        <small class="text-secondary">$3,192.76 on May 2, 2019</small>
                    </div>
                </a>

                <a href="#" class="d-block py-3 border-bottom text-decoration-none text-body">
                    <i class="fa-fw fas fa-file-invoice fa-2x float-left ml-3 mr-3 mt-2"></i>
                    <div>
                        <span class="badge badge-warning float-right mt-1 mr-2">Amended 1x</span>
                        <strong>March 2019</strong><br>
                        <small class="text-secondary">$3,192.76 on Apr 10, 2019</small>
                    </div>
                </a>

                <a href="#" class="d-block py-3 border-bottom text-decoration-none text-body">
                    <i class="fa-fw fas fa-file-invoice fa-2x float-left ml-3 mr-3 mt-2"></i>
                    <div>
                        <span class="badge badge-success float-right mt-1 mr-2">Up To Date</span>
                        <strong>February 2019</strong><br>
                        <small class="text-secondary">$2,955.13 on Mar 2, 2019</small>
                    </div>
                </a>

                <a href="#" class="d-block py-3 border-bottom text-decoration-none text-body">
                    <i class="fa-fw fas fa-file-invoice fa-2x float-left ml-3 mr-3 mt-2"></i>
                    <div>
                        <span class="badge badge-success float-right mt-1 mr-2">Up To Date</span>
                        <strong>January 2019</strong><br>
                        <small class="text-secondary">$3,192.76 on Feb 3, 2019</small>
                    </div>
                </a>

                <div class="border-bottom py-3">
                    <a href="#" class="float-right mr-2 mt-1 small">Download Annual Report</a>
                    <h4 class="mb-0"><i class="fa-fw fas fa-caret-right"></i> 2018</h4>
                </div>

                <div class="border-bottom py-3">
                    <a href="#" class="float-right mr-2 mt-1 small">Download Annual Report</a>
                    <h4 class="mb-0"><i class="fa-fw fas fa-caret-right"></i> 2017</h4>
                </div>

                <div class="border-bottom py-3">
                    <a href="#" class="float-right mr-2 mt-1 small">Download Annual Report</a>
                    <h4 class="mb-0"><i class="fa-fw fas fa-caret-right"></i> 2016</h4>
                </div>

                <small class="d-block text-right mt-3">
                    <a href="#">Something...</a>
                </small>
            </div>
            <div class="col-8 pt-3">
                <div class="border-bottom mb-0 pb-3">
                    <h4 class="mb-0">July 2019 <span class="float-right">Total: <span class="text-danger">Incomplete</span></span></h4>
                </div>
                <div class="alert alert-danger mt-3" role="alert">
                    <i class="fas fa-exclamation-circle"></i> Some values are missing to calculate fees for this month.
                </div>
                <div class="row">
                    <div class="col-12">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Last Updated</th>
                                    <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row"><strong>July 1, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.77</td>
                                    <td>(6/2/2019 @ 2:19AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 2, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 3, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 4, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 5, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td><span class="text-danger">Incomplete</span></td>
                                    <td>NA</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 6, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.25</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 7, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 8, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 9, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 10, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 11, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.77</td>
                                    <td>(6/2/2019 @ 2:19AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 12, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 13, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 14, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 15, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td><span class="text-danger">Incomplete</span></td>
                                    <td>NA</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 16, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.25</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 17, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 18, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 19, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 20, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 21, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.77</td>
                                    <td>(6/2/2019 @ 2:19AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 22, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 23, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 24, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 25, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td><span class="text-danger">Incomplete</span></td>
                                    <td>NA</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 26, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.25</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 27, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 28, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 29, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 1300, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                                <tr>
                                    <td scope="row"><strong>July 10, 2019</strong></td>
                                    <td><strong class="text-warning">Unbilled</strong></td>
                                    <td>$14.82</td>
                                    <td>(6/3/2019 @ 2:14AM)</td>
                                    <td><a href="#">View</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    export default {
        data() {
            return {
            }
        }
    }
</script>