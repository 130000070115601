<template>
	<div :id="id" class="modal fade" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
                <div class="modal-header bg-light">
					<h5 class="modal-title">Edit Billing Schedule</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
                </div>
                <div class="modal-body">
                    <p>Edit</p>
                </div>
			</div>
		</div>
	</div>
</template>

<style scoped>
    div.modal-body > div {
        margin: 20px 10px;
    }
    nav a.dropdown-item {
        cursor: pointer;
    }
    div.modal-content {
        min-height: 85vh;
    }
    div.modal-header {
        margin: 0 3px;
    }
    div.modal-footer table {
        width: 100%;
    }
    div.modal-footer table td:not(:nth-child(2)) {
        width: 50px;
    }
    div.modal-footer table td:nth-child(2) {
        text-align: center;
    }
    div.view {
        margin: 10px;
    }
    div.reveal {
        position: absolute;
        top: 50%;
        left: -10px;
    }
    div.modal-footer p {
        margin: 0;
        font-weight: bold;
        font-size: 18px;
        opacity: 0.6;
    }
</style>

<script>
    import moment from 'moment'
    import { Contract, lib, Style, Sys } from '../../factory'
    import { Household } from '../../model'
    import { firebase, session } from '../../service'

    var { $ } = window

    export default {
        get components() {
            return {
            }
        },
        props: ['modal'],
        data() {
            return {
                state: '',
                select: ''
            }
        },
        created() {
            this.init()
        },
        mounted() {
            var { el, modal } = this
			$(el).on('hidden.bs.modal', () => {
                modal.active = false
            })
            this.show(true)
        },
        computed: {
            el() {
                var { id } = this
                return `#${id}`
            },
            id() {
                var { _uid } = this
                return _uid
            }
        },
        methods: {
            async add() {
                try {
                    var { select, state } = this
                    var { household } = state 
                    var contracts = [...await Household.contracts(household)]
                    var query
                    for (var contract of contracts) {
                        var { _id: contractId } = contract
                        query = firebase.db
                            .collection('records')
                            .where('contractId', '==', contractId)
                        contract.records = firebase.docs(await query.get())
                            .sort((a, b) => +a.date - b.date)
                    }
                    var _dates = contracts
                        .reduce((arr, c) => {
                            c.records.forEach((r) => {
                                if (arr.find(d => d == r.date))
                                    return
                                arr.push(+r.date)
                            })
                            return arr
                        }, [])
                        .sort((a, b) => a - b)
                    var dates = this.interpolate(_dates)
                        .map(date => moment(date, `YYYYMMDD`).format(`MMM DD, YYYY`))
                    select.dates = [...dates]
                    select.startDate = dates[0]
                    select.endDate = dates[dates.length - 1]
                    select.householdContracts = [...contracts]
                } catch(e) {
                    console.log(e)
                }
            },
            init() {
                /*
                console.log('action', this.modal.action)
                this.state = session.get('state')
                this.select = {
                    householdContracts: [],
                    account({ name, number }) {
                        return `${number} - ${name}`
                    },
                    contract({ _id }) {
                        return `Contract ref: ${_id}`
                    },
                    get contracts() {
                        var [start, end] = [this.startDate, this.endDate]
                            .map(d => +moment(d, 'MMM DD, YYYY').format('YYYYMMDD'))
                        return this.householdContracts
                            .filter((c) => {
                                var records = c.records
                                    .filter(r => r.date >= start && r.date <= end)
                                return records.length > 0
                            })
                    },
                    dates: [],
                    startDate: '',
                    get startDates() {
                        return this.dates
                    },
                    endDate: '',
                    get endDates() {
                        var index = this.dates.findIndex(d => d == this.startDate)
                        return this.dates.slice(index)
                    },
                    recurr: 'No',
                    get recurring() {
                        return ['No', 'Yes']
                    },
                    get period() {
                        var format = `MMM DD, YYYY`
                        var days = +(moment(this.endDate, format).diff(moment(this.startDate, format)) / 86400e3).toFixed()
                        var suffix = days == 1 ? `day` : `days`
                        return `${days} ${suffix}`
                    }
                }
                this.add()
                */
            },
            interpolate(list) {
                var format = `YYYYMMDD`
                var difference = (a, b) => moment(b, format).diff(moment(a, format))
                var carry = (d) => +moment(d, format).add(1, 'days').format(format)
                return list
                    .reduce((arr, item, i) => {
                        if (arr.length) {
                            var n = 0
                            while(difference(arr[arr.length - 1], item) > 86400e3) {
                                arr.push(carry(arr[arr.length - 1]))
                                n++
                                if (n > 3)
                                    throw 'oops'
                            }
                        }
                        arr.push(item)
                        return arr
                    }, [])
            },
            message(text = '', error = false) {
                var style = error ? Style.color('red') : Style.color('#2dbc4e')
                this.status = { text, style }
            },
            async save() {
                /*
                var { contract, message, status } = this
                status.text = 'Save???'
                status.style = 'color: red'
                var summary = contract.summary()
                console.log({ summary })
                try {
                    message('Saving contract...')
                    var parentId = contract.household ? contract.household._id : ''
                    var { company, household, user } = session.get('state') 
                    var { _id: householdId } = household
                    var { _id: managerId } = user
					var address = ''
					var executed = ''
                    var active = false
                    var network = 'ropsten'
                    var created = new Date().toISOString()
                    var { accounts, caveats, schedules } = summary
					var doc = { accounts, active, address, caveats, created, executed, householdId, managerId, network, parentId, schedules }
                    var collection = 'contracts'
                    var { id: documentId } = await firebase.db
						.collection(collection)
                        .add(doc)
                    var result = await firebase.db
                        .collection(collection)
                        .where(firebase.documentId(), '==', documentId)
                        .get()
                    var [record] = firebase.docs(result)
                    await Sys.wait(1500)
                    message('Done!')
                    await Sys.wait(1500)
                    message('Submitting contract...')
                    await Contract.submit(record, company, household)
                    await Sys.wait(1500)
                    message('Done!')
                    await Sys.wait(1500)
                    message('Contract is now awaiting approval')
                } catch(e) {
                    message(e.message || 'An unknown error has occurred!', true)
                }
                */
            },
            show(show) {
                var { el } = this
                $(el).modal(show ? 'show' : 'hide')
            }
        }
    }
</script>