<template>
    <div class="ml-3 mr-5">
        <table class="table table-sm table-borderless manual">
            <thead>
                <tr>
                    <th>
                        <span>Name</span>
                    </th>
                    <th>
                        <span>Number</span>
                    </th>
                    <th>
                        <span>Schedule</span>
                    </th>
                    <th>
                        <span>Include</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(account, index) in household.accounts" :key="index">
                    <td>
                        <span v-text="account.name"></span>
                    </td>
                    <td>
                        <span v-text="account.number"></span>
                    </td>
                    <td>
                        <select class="form-control form-control-sm" v-model="account.scheduleName">
                            <option v-for="schedule in contract.schedules" :key="schedule.name">
                                <span v-text="schedule.name"></span>
                            </option>
                        </select>
                    </td>
                    <td>
                        <input type="checkbox" v-model="account.select">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
    select {
        width: 90%;
        margin-top: -5px;
    }
</style>

<script>
    export default {
        props: [`contract`, `household`],
		data() {
			return {
                settings: ''
			}
        },
        created() {
            this.init()
        },
		computed: {
		},
		methods: {
            init() {
                var [schedule] = this.contract.schedules
                var { accounts } = this.household
                accounts.forEach((a) => {
                    if (!a.scheduleName)
                        a.scheduleName = schedule.name
                })
            }
		}
    }
</script>