<template>
    <div class="h-100 scrollbar" data-simplebar>
        <slot></slot>
    </div>
</template>

<style scoped>
</style>

<script>
    import Simplebar from 'simplebar'
    import 'simplebar/dist/simplebar.css'

    export default {
        data() {
            return {
            }
        },
        mounted() {
            var el = document.getElementsByClassName(`scrollbar`)[0]
            new Simplebar(el)
        }
    }
</script>