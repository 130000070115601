<template>
    <div class="row m-3">
        <div class="col-3">
            <div class="card">
                <div class="card-header">
                    <h5>Demo Account</h5>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label>User name</label>
                        <input type="text" class="form-control form-control-sm" placeholder="Name" v-model="view.demo.name" :disabled="view.disabled('demo.name')">
                    </div>
                    <div class="form-group">
                        <label>User email</label>
                        <input type="email" class="form-control form-control-sm" placeholder="Email" v-model="view.demo.email" :disabled="view.disabled('demo.email')">
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <button class="btn btn-sm btn-danger btn-block" v-on:click="view.disable('demo')" :disabled="view.disabled(`demo.disable`)">Disable</button>
                        </div>
                        <div class="col">
                            <button class="btn btn-sm btn-primary btn-block" v-on:click="view.add('demo')" :disabled="view.disabled(`demo.add`)">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import { Api } from '../factory'
    import { alert, session } from '../service'

    export default {
        data() {
            return {
                view: ``
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                var state = session.get(`state`)
                var view = {
                    _pending: ``,
                    async add(type) {
                        switch(type) {
                            case `demo`:
                                try {
                                    var { name, email } = this.demo
                                    var { _id: managerId } = state.user
                                    var demo = true
                                    this._pending = `demo-add`
                                    var resp = await Api.post(`auth/add`, { demo, email, managerId, name })
                                    alert.message(resp.data)
                                } catch(e) {
                                    console.log(e)
                                    alert.error(e.message)
                                } finally {
                                    this._pending = ``
                                    this.init()
                                }
                                break
                            default:
                                throw new Error(`Invalid add type, ${type}!`)
                        }
                    },
                    demo: ``,
                    disable(type) {
                        switch(type) {
                            case `demo`:
                                alert.message(`Not yet implemented!`, 1e3)
                                break
                            default:
                                throw new Error(`Invalid disable type, ${type}!`)
                        }
                    },
                    disabled(s) {
                        var [type, prop] = s.split(`.`)
                        switch(type) {
                            case `demo`:
                                var { demo } = this
                                if (prop == `name` || prop == `email`)
                                    return this._pending == `demo-add`
                                return !demo.email.includes(`.`) || !demo.email.includes(`@`) || demo.name.length < 4 || !demo.name.includes(` `) || this._pending == type 
                            default:
                                throw new Error(`Invalid disabled type, ${type}!`)
                        }
                    },
                    init() {
                        this.demo = {
                            email: ``,
                            name: ``
                        }
                        return this
                    }
                }
                this.view = view.init()
            }
        }
    }
</script>