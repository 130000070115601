<template>
    <div class="root">
        <div>
            <div class="card">
                <div class="card-body">
                    <div>
                        <img src="../../img/logo-skx-kw.png">
                        <form>
                            <div class="form-group">
                                <label>Email</label>
                                <input class="form-control" v-model="login.email" placeholder="Email">
                            </div>
							<template v-if="!login.forgot">
								<div class="form-group">
									<label>Password</label>
									<input type="password" class="form-control" v-model="login.pass" placeholder="Password">
								</div>
								<div class="form-group">
									<hr style="opacity: 0.4">
									<button type="button" class="btn btn-primary btn-block" :disabled="login.disabled('login')" v-on:click="login.submit()">Sign in</button>
								</div>
								<p class="forgot">
									<a href="#" v-on:click="login.reset(true)">Forgot password</a>
								</p>
							</template>
							<template v-else>
								<div class="form-group">
									<hr style="opacity: 0.4">
									<button type="button" class="btn btn-info btn-block" :disabled="login.disabled('reset')" v-on:click="login.submit(true)">Reset password</button>
								</div>
							</template>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
	p.forgot {
		text-align: right;
		margin-top: -15px;
		font-size: 12px;
	}
    div.root {
        background-color: #e9ebee;
        width: 100%;
        height: 100vh;
    }
    div.root > div {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 600px;
        height: 400px;
        margin-left: -300px;
        margin-top: -250px;
	}
	div.card {
        width: 600px;
        margin: auto;
    }
    div.card-body {
        padding: 30px;
        background: white;
	}
	div.card-body form {
		margin-top: 25px;
	}
	img {
		max-width: 100%;
	}
</style>

<script>
    import { Api, Email, Sys } from '../factory'
	import { alert, auth, firebase } from '../service'
	
	export default {
		data() {
			return {
				login: ''
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				var login = {
					disabled(type) {
						var { email, pass, pending } = this
						switch(type) {
							case `login`:
								return !pending && Email.valid(email) && pass.length > 3 ? false : true
							case `reset`:
								return !pending && Email.valid(email) ? false : true
							default:
								throw new Error(`Invalid disabled type, ${type}!`)
						}
					},
					email: '',
					forgot: ``,
					pass: '',
					pending: false,
					reset(forgot = false) {
						this.email = ''
						this.pass = ''
						this.pending = false
						this.forgot = forgot
					},
					async submit(reset) {
						var wait = 3e3
						var { email, pass } = this
						this.pending = true
						if (!reset) {
							var error
							try {
								await auth.login(email, pass)
	//							events.$emit('login', true)
							} catch(e) {
								console.log(e)
								await alert.error(e.message, wait)
								this.reset()
							}
						} else {
							try {
								var resp = await Api.post(`auth/reset`, { email })
								await alert.message(resp.data, wait)
								this.reset()
							} catch(e) {
								console.log(e)
								alert.error(`Password reset failed!`)
							}
						}
					}
				}
				this.login = login
			}
		}
	}
</script>