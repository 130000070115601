class Session {

    constructor() {
        console.log('creating session instance...')
        this._state = new Map()
        console.log(this)
    }

    set(key, val) {
        this._state.set(key, val)
        return this
    }

    get(...keys) {
        var data = keys
            .map(key => this._state.get(key))
        return keys.length > 1 ? data : data[0]
    }

}

export default new Session()