import firebase from './firebase'

class Auth {

    static login(email, password) {
        return firebase.auth
            .signInWithEmailAndPassword(email, password)
    }

    static logout() {
        return firebase.auth
            .signOut()
    }

    static state(fn) {
        if (typeof fn != 'function')
            throw new Error(`This method requires a callback function!`)
        return firebase.auth
            .onAuthStateChanged(fn)
    }

}

export default Auth