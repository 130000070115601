<template>
    <div class="h-100">
        <h5 class="bg-light p-3 border-bottom">Dashboard</h5>
        <div class="h-100 m-3">
            <p>Mock Dashboard Content</p>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    export default {
        get components() {
            return {
                //accountsList
            }
        },
        data() {        
            return {
            }
        },
        created() {
            this.init()
        },
        computed: {
        },
        methods: {
            init() {
                console.log('Firm Payments')
            }
        }
    }
</script>