<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Test</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Test modal content</p>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    export default {
        data() {
            return {}
        }
    }
</script>