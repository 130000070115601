<template>
    <div class="h-100">
        <template v-if="mock">
            <mock-billing></mock-billing>
        </template>
        <template v-else>
            <div class="h-100">
                <h5 class="bg-light p-3 border-bottom">
                    <span>Billing</span>
                    <i class="fab fa-youtube video" v-on:click="nav.video()"></i>
                </h5>
                <div class="mb-2" style="height: 80vh">
                    <div class="tab-list mx-3 pt-3">
                        <ul class="nav nav-tabs">
                            <li class="nav-item" v-for="(tab, index) in nav.tabs" :key="index">
                                <a class="nav-link" v-on:click="nav.select(index)" :class="nav.class(index)" :style="nav.style(index)">
                                    <span v-text="tab"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-components pt-2">
                        <component :is="nav.component"></component>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>
</style>

<script>
    import { lib } from '../../factory'
    import mockBilling from './mock'
    import { moment } from '../../npm'
    import editCycle from './edit-cycle'
    import newBill from './new-bill'
    import newCycle from './new-cycle'
    import newInvoice from './new-invoice'
    import newSchedule from './new-schedule'
    import invoiceList from './invoices'
    import { Household } from '../../model'
    import { alert, events, firebase, session } from '../../service'

    export default {
        get components() {
            return {
                editCycle,
                mockBilling,
                newBill,
                newCycle,
                newInvoice,
                newSchedule,
                invoiceList
            }
        },
        data() {
            return {
                nav: '',
                state: ''
            }
        },
        created() {
            var { newBill } = this
            this.events = events.$watch({
                newBill: newBill.bind(this) 
            })
            this.init()
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
            mock() {
                var { mock } = this.state
                return mock
            }
        },
        methods: {
            add() {
                var { modal } = this
                modal.component = `modal-billing`
                modal.active = true
            },
            init() {
                var { string } = lib
                this.state = session.get('state')
                this.nav = {
                    _components: ['invoice-list', `new-invoice`],
                    //, `new-schedule`],
                    _index: 0,
                    get component() {
                        return this._components[this._index]
                    },
                    class(index) {
                        return index == this._index ? 'active' : '' 
                    },
                    select(index) {
                        this._index = index
                    },
                    style(index) {
                        var [cursor, color] = this._index == index ? ['default', 'inherit'] : ['pointer', '#007bff']
                        return [`cursor: ${cursor}`, `color: ${color}`]
                            .join(';')
                    },
                    get tabs() {
                        return this._components
                            .map((c) => {
                                switch(c) {
                                    case `invoice-list`:
                                        return `Invoices`
                                    case `new-invoice`:
                                        return `New Bill`
                                    case `new-schedule`:
                                        return `New Billing Cycle`
                                    default:
                                        return c.split(`-`).map(s => string.capitalise(s)).join(` `)
                                }
                            })
                    },
                    async video() {
                        try {
                            var src = await firebase.storageURL(`video/billing.mp4`)
                            events.$emit('video', { src, type: `video/mp4` })
                        } catch(e) {
                            console.log(e)
                            alert.error(e.message)
                        }
                    }
                }
            },
            newBill(e) {
                if (e.saved)
                    this.nav.select(0)
            }
        }
    }
</script>