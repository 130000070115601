<template>
    <div>
        <p>Exceptions based on current account holdings, none available for current accounts.</p>
        <!--
        <div class="row">
            <div class="col">
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th>Account</th>
                            <th>Type</th>
                            <th>Identifier</th>
                            <th>Schedule</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(caveat, index) in caveats" :key="index">
                            <td>
                                <button type="button" class="btn btn-sm btn-light btn-block text-left" data-toggle="dropdown">
                                    <span v-text="caveat.account ? caveat.account.number : 'Select...'"></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a v-for="(account, index) in select.accounts" :key="index" class="dropdown-item" href="#" v-on:click="select.account(caveat, account)">
                                        <span v-text="account.number"></span>
                                    </a>
                                </div>
                            </td>
                            <td>
                                <button type="button" class="btn btn-sm btn-light btn-block text-left" data-toggle="dropdown" :disabled="!caveat.account">
                                    <span v-text="caveat.type ? caveat.type.label : 'Select...'"></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a v-for="(type, index) in select.types" :key="index" class="dropdown-item" href="#" v-on:click="select.type(caveat, type)">
                                        <span v-text="type.label"></span>
                                    </a>
                                </div>
                            </td>
                            <td>
                                <template v-if="caveat.selected">
                                    <button type="button" class="btn btn-sm btn-light btn-block text-left" :disabled="!caveat.type" v-on:click="select.identifier(caveat, '')">
                                        <span v-text="caveat.identifier || 'Pending'"></span>
                                    </button>
                                </template>
                                <template v-else>
                                    <div class="dropdown">
                                        <input class="form-control form-control-sm" v-model="caveat.identifier" v-on:input="select.input(caveat)">
                                        <div :class="select.identifiers(caveat).length ? 'dropdown-menu show' : ''">
                                            <a v-for="(identifier, index) in select.identifiers(caveat)" :key="index" class="dropdown-item" href="#" v-on:click="select.identifier(caveat, identifier)">
                                                <span v-text="identifier"></span>
                                            </a>
                                        </div>
                                    </div>
                                </template>
                            </td>
                            <td>
                                <button type="button" class="btn btn-sm btn-light btn-block text-left" data-toggle="dropdown" :disabled="!caveat.account || !caveat.type">
                                    <span v-text="caveat.schedule ? caveat.schedule.name : 'Select...'"></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a v-for="(schedule, index) in select.schedules" :key="index" class="dropdown-item" href="#" v-on:click="select.schedule(caveat, schedule)">
                                        <span v-text="schedule.name"></span>
                                    </a>
                                </div>
                            </td>
                            <td class="delete">
                                <button type="button" class="btn btn-sm btn-outline-danger" v-on:click="remove(index)">X</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col">
            <div class="row">
                <button type="button" class="btn btn-sm btn-outline-warning" v-on:click="add">Add</button>
            </div>
        </div>
        -->
    </div>
</template>

<style scoped>
    th:nth-child(1) {
        width: 200px;
    }
    th:nth-child(2) {
        width: 120px;
    }
    th:nth-child(3) {
        width: 220px;
    }
    td.delete {
        width: 50px;
        text-align: right;
        vertical-align: middle;
    }
    td.delete button {
        margin-bottom: 7px;
    }
</style>

<script>
    import _ from 'lodash'
    import { Contract, lib, Http, Stock, Sys } from '../../../factory'
    import { session } from '../../../service'

    export default {
        data() {
            return {
                contract: '',
                select: ''
            }
        },
        created() {
            //this.init()
        },
        computed: {
            caveats() {
                var { caveats } = this.contract
                return caveats
            }
        },
        methods: {
            add() {
                var { caveats } = this
                caveats.push({ account: '', type: '', identifier: '', schedule: '', selected: true })
            },
            init() {
                var { string } = lib
                var { contract } = session.get('state')
                if (!contract.caveats)
                    this.$set(contract, 'caveats', [])
                var select = {
                    _identifiers: {},
                    _labels: {},
                    _types: ['debt', 'equity', 'cash'],
                    account(caveat, account) {
                        caveat.account = account
                    },
                    get accounts() {
                        return Contract.accounts(contract)
                    },
                    identifier(caveat, identifier = '') {
                        var { _identifiers } = this
                        caveat.identifier = identifier
                        caveat.selected = identifier ? true : false
                        this.identifiers(caveat, [])
                    },
                    identifiers(caveat, list) {
                        var { _identifiers } = this
                        var { identifier = '', type } = caveat
                        if (Array.isArray(list))
                            return _identifiers[type.value] = [...list]
                        switch(type.value) {
                            case 'equity':
                                return _identifiers[type.value]
                            default:
                                return _identifiers[type.value]
                                    .filter(s => identifier.length && s.toLowerCase().indexOf(identifier.toLowerCase()) > -1)
                        }
                    },
                    get input() {
                        var { _load } = this
                        return  _.debounce(_load.bind(this), 500)
                    },
                    async _load(caveat) {
                        var { identifier = '', type } = caveat
                        var data, list, account, securities
                        switch(type.value) {
                            case 'equity':
                                console.log('getting tickers, identifier', identifier, identifier.length)
                                if (!identifier.length)
                                    return this.identifiers(caveat, [])
                                try {
                                    data = await Stock.search(identifier)
                                    list = data
                                        .map(o => `${o.symbol} (${o.name})`)
                                    this.identifiers(caveat, list)
                                } catch(e) {
                                    console.log(e)
                                }
                                break
                            case 'cash':
                            case 'debt':
                                var categoryCode = type.value == 'cash' ? 'O' : 'DEBT'
                                var { accounts } = this
                                var { securities = [] } = caveat.account ? accounts.find(a => a.number == caveat.account.number) : {}
                                data = securities
                                    .filter(s => s.categoryCode == categoryCode)
                                list = type.value == 'cash' ? data.map(o => o.ticker) : data.map(o => o.name.split(',').shift())
                                this.identifiers(caveat, list)
                                break
                        }
                    },
                    type(caveat, type) {
                        caveat.type = type
                        caveat.identifier = ''
                        this._load(caveat)
                    },
                    get types() {
                        var { _labels, _types } = this
                        return _types
                            .map((value) => {
                                var label = _labels[value] || string.capitalise(value)
                                return { label, value }
                            })
                    },
                    schedule(caveat, schedule) {
                        caveat.schedule = schedule
                    },
                    get schedules() {
                        var { schedules } = contract
                        return schedules
                    }
                }
                var { _identifiers, _types } = select
                _types.forEach((type) => {
                    _identifiers[type] = []
                })
                this.contract = contract
                this.select = select
                console.log(this.contract)
            },
            remove(index) {
                var { caveats } = this
                caveats.splice(index, 1)
            }
        }
    }
</script>