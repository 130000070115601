<template>
    <div>
        <div class="row">
            <div class="col-2">
                <div class="form-group">
                    <label>Name</label>
                    <input class="form-control form-control-sm" placeholder="Firstname" v-model="form.data.firstname" v-on:focus="form.focus(`firstname`)" v-on:blur="form.blur(`firstname`)">
                </div>
                <div class="form-group">
                    <input class="form-control form-control-sm" placeholder="Lastname" v-model="form.data.lastname" v-on:focus="form.focus(`firstname`)" v-on:blur="form.blur(`lastname`)">
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label>Email</label>
                    <input class="form-control form-control-sm" placeholder="Client email" v-model="form.data.email" v-on:focus="form.focus(`email`)" v-on:blur="form.blur(`email`)">
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label>Role</label>
                    <select class="form-control form-control-sm" v-model="form.data.role" v-on:change="form.select('role')" ref="role">
                        <option v-for="(role, i) in form.roles" :key="i">
                            <span v-text="role.label"></span>
                        </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <template v-if="!contact.import">
                    <div class="action">
                        <button type="button" class="btn btn-success btn-block btn-sm" v-on:click="form.save()" :disabled="form.pending || !form.edit">
                            <template v-if="form.pending">
                                <i class="fa fa-spinner fa-spin"></i>
                            </template>
                            <template v-else>
                                <i class="fas fa-check-circle"></i>
                            </template>
                            <span class="label">Save</span>
                        </button>
                        <template v-if="contact._id">
                            <button class="btn btn-sm btn-block btn-dark" v-on:click="form.audit()">
                                <i class="fas fa-history"></i>
                                <span class="label">Audit</span>
                            </button>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    div.row {
        margin: 0.5em 0;
    }
    div.row p {
        text-align: right;
    }
    div.action {
        margin-top: 32px;
        width: 6em;
    }
    button {
        text-align: left;
        padding-left: 15px;
        margin-bottom: 17px;
    }
    button i {
        width: 15px;
    }
    span.label {
        padding-left: 10px;
        color: white;
    }
</style>

<script>
    import { Api, lib, Sys } from '../../factory'
    import { smartkx } from '../../npm.org'
    import { alert, events, session } from '../../service'

    export default {
        props: [`contact`],
        data() {
            return {
                form: ``
            }
        },
        created() {
            this.init()
        },
        computed: {
        },
        methods: {
            init() {
                var { string } = lib
                var state = session.get(`state`)
                var form = {
                    _edit: ``,
                    _contact(c = {}) {
                        var { _id = `` } = c
                        var { data } = this
                        var { firstname, lastname, email } = data
                        var role = data.role.toLowerCase()
                        return { _id, email, firstname, lastname, role }
                    },
                    _v(key) {
                        return this.data[key]
                    },
                    audit: () => {
                        var { contact } = this
                        events.$emit(`firm-menu`, { audit: { contact }})
                    },
                    blur(key) {
                        var v = this._v(key)
                        if (v != this._edit)
                            this.edit++
                    },
                    data: {
                        firstname: ``,
                        lastname: ``,
                        email: ``,
                        role: ``,
                    },
                    edit: 0,
                    focus(key) {
                        this._edit = this._v(key)
                    },
                    init(c) {
                        var { data } = this
                        data.firstname = c.firstname
                        data.lastname = c.lastname
                        data.email = c.email
                        data.role = string.capitalise(c.role)
                        return this
                    },
                    pending: false,
                    get roles() {
                        return smartkx.contact.roles
                            .map((value) => {
                                var label = string.capitalise(value)
                                return { label, value }
                            })
                    },
                    save: async () => {
                        var { form } = this
                        var { _id: managerId } = state.user
                        if (form.pending)
                            return
                        form.pending = true
                        var contact = form._contact(this.contact)
                        var data = { managerId, contact }
                        try {
                            var resp = await Api.post(`contact/save`, data)
                            console.log(resp.data)
                            var { message } = resp.data
                            events.$emit(`firmContacts`, { saved: true })
                            alert.message(message, 3e3)
                        } catch(e) {
                            console.log(e.message)
                            alert.error(e.message)
                        } finally {
                            form.pending = false
                        }
                    },
                    select() {
                        this.edit++
                    }
                }
                this.form = form.init(this.contact)
            }
        }
    }
</script>