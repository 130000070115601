<template>
    <div class="m-3">
        <table class="table table-sm table-borderless">
            <tbody>
                <tr>
                    <td>
                        <strong v-text="firm.name"></strong>
                    </td>
                    <td class="hide">
                        <button type="button" class="btn btn-sm btn-outline-secondary" v-on:click="hide">Hide</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
    td.household-select select {
        margin: -3px 0 0 -14px;
    }

    td.hide {
        text-align: right;
    }
    td.hide button {
        margin-top: -5px;
    }
    span {
        font-size: 14px;
    }
    /*
    table td:first-child {
        width: 10px;
    }
    */
</style>

<script>
    import { session } from '../service'

    export default {
        props: ['sidebar'],
        data() {
            return {
                state: ``
            }
        },
        created() {
            this.init()
        },
        computed: {
            firm() {
                return this.state.company
            }
        },
        methods: {
            hide() {
                this.sidebar.toggle()
            },
            init() {
                this.state = session.get(`state`)
            }
        }
    }
</script>