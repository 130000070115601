import { Tab } from '../factory'
import { moment } from '../npm'
import { smartkx } from '../npm.org'
import { contract as contractPdf } from '../pdf'
import { alert, blockchain, firebase, session } from '../service'

import sdate from '@smartkx/date'
import sha256 from 'sha-256-js' 

var { firestore } = smartkx

class Contract {

    static daysOfYear() {
        return sdate.leapYear() ? 366 : 365
    }

    static scale(type) {
        var uiScale = 1e2
        switch(type) {
            case 'fee':
            case 'fees':
            case 'math':
                return uiScale * uiScale
            default:
                return uiScale
        }
    }

    /*
    static _parse(result) {
        var [type] = Object.keys(result)
        var data = result[type]
        switch(type) {
            case 'calculate':
                return data
                    .redwuce((obj, result, idx) => {
                        switch(idx) {
                            case 0:
                                obj.accountsTotal = Contract._value(result, uiScale)
                                break
                            case 1:
                                obj.values = [...result.reduce((set, o) => set.add(Contract._value(o, uiScale)), new Set())]
                                break
                            case 2:
                                obj.splits = [...result.reduce((set, o) => set.add(Contract._value(o, uiScale)), new Set())]
                                break
                            case 3:
                                obj.feesTotal = Contract._value(result, bps)
                                break
                            case 4:
                                obj.feesSplits = [...result.reduce((set, o) => set.add(Contract._value(o, bps)), new Set())]
                                break
                            case 5:
                                obj.feesAccounts = [...result.reduce((set, o) => set.add(Contract._value(o, bps)), new Set())]
                                break
                            case 6:
                                obj.feesPeriod = Contract._value(result)
                                break
                            default:
                                throw new Error(`Invalid results length!`)
                        }
                        return obj
                    }, {})
            case 'schedule':
                return data
                    .reduce((obj, result, idx) => {
                        switch(idx) {
                            case 0:
                                obj.accounts = Contract._value(result, 1)
                                break
                            case 1:
                                obj.breaks = [...result.reduce((set, o) => set.add(Contract._value(o, uiScale)), new Set())]
                                break
                            case 2:
                                obj.rates = [...result.reduce((set, o) => set.add(Contract._value(o, uiScale)), new Set())]
                                break
                            default:
                                throw new Error(`Invalid results length!`)
                        }
                        return obj
                    }, {})
            case 'values':
                return data
                    .map(result => Contract._value(result, uiScale))
            default:
                throw new Error(`Unknown parse type, ${type}!`)
        }
    }

    static _hex({ _hex }) {
        return parseInt(_hex, 16) / scale
    }
    */

    /*
    static _schedule(result) {
        return Object.keys(result)
            .reduce((obj, key) => {
                var data = result[key]
                switch(+key) {
                    case 0:
                        obj.accounts = data
                        break
                    case 1:
                    case 2:
                        var prop = +key == 1 ? 'breaks' : 'rates'
                        obj[prop] = data
                            .map(v => v / uiScale)
                        break
                    default:
                        throw new Error(`Invalid result key, ${key}!`)
                }
                return obj
            }, {})
    }
    */

    /*
    static _type(account, type) {
        var { string } = lib
        account.type = string.capitalise(type)
        return account
    }

    static _valid(data) {
        var [type] = Object.keys(data)
        var value = data[type]
        switch(type) {
            case 'id':
                if (value.length != 20)
                    throw new Error(`Invalid contract id, ${value}!`)
                break
            default:
                throw new Error(`Invalid validation type, ${type}!`)
        }
    }
    */

    /*
    static _value(obj, scale) {
        var [v] = obj.c
        return scale ? +(v / scale).toFixed(2) : v
    }
    */

    /*
    static _values(result) {
        return result
            .map(v => v / uiScale)
    }
    */

    static accounts(contract) {
        var { accounts = { feed: [], manual: [] } } = contract
        var { feed = [], manual = [] } = accounts
        return feed
            .filter(a => a.select)
            .map(a => Contract._type(a, 'feed'))
            .concat(manual.map(a => Contract._type(a, 'manual')))
            .filter(a => a.custodian && a.name && a.number)
    }

    static billing(account, accounts) {
        var { billing: index } = account
        return index < 0 ? `This Account` : accounts[index].number
    }

    /*
    static _calc(schedule) {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(schedule)
                var child = firebase.storage
                    .ref()
                    .child(`contracts`)
                    .list()
                console.log(child)
                    //.listAll()
//                       .listAll()
//                    console.log(contracts)
//                    console.log(s)
            } catch(e) {
                reject(e)
            }
        })
    }
    */

    /*
    static calculate(contract, schedule, accounts, period, days = 365) {
        return new Promise(async (resolve, reject) => {
            try {
                if (!period || period > days)
                    throw new Error(`Invalid period, ${period}!`)
                if (!Array.isArray(accounts) || accounts.length != contract.accounts.length)
                    throw new Error(`Invalid account values!`)
                if (typeof schedule != 'object' || !schedule.active)
                    throw new Error(`Invalid schedule!`)
                var { json, address } = await Contract._calc(schedule)
                console.log({ json, address })
                var contract = await blockchain.contract(json, address)
                var result = await contract
                    .methods
                    .getSchedule(hash)
                    .call()
                var schedule = Object.assign({ hash, name }, Contract._schedule(result)) 
                resolve(schedule)
            } catch(e) {
                reject(e)
            }
        })
    }
    */

    /*
    static calculate({ schedule, values: v }) {
        return new Promise(async (resolve, reject) => {
            var breaks = schedule.breaks
                .map(b => b * uiScale)
            var rates = schedule.rates
                .map(r => r * uiScale)
            var values = v
                .map(v => v * uiScale)
            try {
                var contract = await blockchain.contract('calc')
                var period = 4
                var result = await contract
                    .methods
                    .calculate(values, breaks, rates, uiScale, period)
                    .call()
                resolve(Contract._parse({ calculate: result }))
            } catch(e) {
                reject(e)
            }
        })
    }
    */

    /*
    static caveats(contract, schedules) {
        var { caveats } = contract
        //.caveats
            //  .map((c) => {
            //    var { account = { name: '' }, identifier, schedule = { name: '' }, type } = c
                //   return { account: account.name, identifier, schedule: schedule.name, type }
            //})
        return schedules ? caveats.filter(c => c.schedule) : caveats
    }
    */

    static _date() {
        return new Date().toLocaleString()
    }

    static _ref(contract) {
        var { _id: contractId } = contract
        return `skx-${contractId}`
    }

    static _signers(contract, company, household) {
        var names = [company.name, household.name]
        var emails = [company.email, household.email]
        if (contract.linked) {
            var { name, email } = contract.linked
            names.splice(1, 0, name)
            emails.splice(1, 0, email)
        }
        return names
            .map((name, index) => {
                var email = emails[index]
                return { email, name }
            })
    }

    static _html(contract, company, household) {
        var date = Contract._date()
        var ref = Contract._ref(contract)
        var signers = Contract._signers(contract, company, household)
//            console.log({ signers })
        return contractPdf.html({ contract, date, ref, signers, company, household })
        //return { data, html, ref, signers }
        /*
        var url = 'https://v2018.api2pdf.com/wkhtmltopdf/html'
        var inlinePdf = true
            var fileName = 'test-contract.pdf'
        var data = { html, inlinePdf, fileName }
        var options = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': apiKey
            }
        }
        return Http.post({ url, data, options })
        */
    }

    /*
    static quarters(contract, schedule, values) {
        var { years } = contract
        var { accounts } = schedule
        var map = new Map()
        var year, value
        var id, quarter
        var q = 1
        for (var i = 0; i < values.length; i++) {
            value = values[i]
            if (i % (accounts * 4) == 0)
                year = years[i]
            id = `${year}${q}`
            quarter = map.get(id) || map.set(id, { id, values: [] }).get(id)
            quarter.values.push(value)
            if ((i % accounts + 1) == accounts)
                q++
        }
        return [...map.values()]
            .filter(q => q.values.reduce((t, v) => t+= v, 0) > 0)
    }
    */

    /*
    static schedule({ address }) {
        return new Promise(async (resolve, reject) => {
            try {
                var contract = await blockchain.contract('Household', address)
                var result = await contract
                    .methods
                    .getSchedule()
                    .call()
                resolve(Contract._schedule(result))
            } catch(e) {
                reject(e)
            }
        })
    }
    */

    /*
    static _schedule(r) {
        var { active, calc, doc } = r
        var breaks = r.breaks
            .map(b => +b)
        var rates = r.rates
            .map(r => +r)
        return { active, breaks, rates, calc, doc }
    }
    */

    static schedule(c, s) {
        return new Promise(async (resolve, reject) => {
            try {
                var { address, json, network } = c
                var { name } = s
                var hash = Contract.hash(c, null, s)
                var contract = await blockchain.contract(json, network, address)
                var schedule = await contract.methods.getSchedule(hash)
                resolve(Object.assign({ hash, name }, schedule))
            } catch(e) {
                reject(e)
            }
        })
    }

    static calc(schedule) {
        return new Promise(async (resolve, reject) => {
            try {
                var address = schedule.calc.toLowerCase()
                for (var [path, json] of session.get('files')) {
                    if (json.address && json.address.toLowerCase() == address)
                        return resolve({ path, json })
                }
                var files = [...await firebase.files(`contracts`)]
                    .filter(f => f.name.substr(0, 4).toLowerCase() == 'calc')
                    .map((file) => {
                        var [major, minor, point] = file.name.split('-').pop().split('.json').shift().split('.')
                        file.version = { major, minor, point }
                        return file
                    })
                    .sort((a, b) => b.version.major - a.version.major)
                    .sort((a, b) => b.version.minor - a.version.minor)
                    .sort((a, b) => b.version.point - a.version.point)
                for (var file of files) {
                    file.json = await firebase.download(file.path)
                    if (file.json.address.toLowerCase() == address) {
                        session.set(file.path, file.json)
                        return resolve(file)
                    }
                }
                // hack start
                var [file] = files
                session.set(file.path, file.json)
                resolve(file)
                // hack stop
                //throw new Error(`Unable to find calc with address, ${address}!`)
            } catch(e) {
                reject(e)
            }
        })
        
        /*
        schedule.file = await Contract.calculator()
        for (var file of files) {
            if (!file.json)
                file.json = await firebase.download(file)
            console.log(file.json)
            if (file.json.address.toLowerCase() == schedule.calc.toLowerCase()) {
                schedule.file = file
                break
            }
        }
        */

    }

    static async fees(accounts, schedule, period, file) {
        return new Promise(async (resolve, reject) => {
            try {
                var { breaks, rates, minimum } = schedule
                var { address, network } = file.json
                var settings = [parseInt(period), Contract.daysOfYear(), minimum]
                var args = [
                    accounts.map(a => a.value),
                    breaks,
                    rates,
                    settings
                ]
                var contract = await blockchain.contract(file.path, network, address)
                resolve(await contract.methods.calculate(...args))
            } catch(e) {
                reject(e)
            }
        })
    }

    /*
    static setAccountValues(values) {
        return values
            .map(v => v * uiScale)
    }
    */

    static submit(contract, company, household) {
        var date = Contract._date()
        var ref = Contract._ref(contract)
        var signers = Contract._signers(contract, company, household)
        var html = contractPdf.html({ contract, date, ref, signers, company, household })
        var headers = {
            'Content-type': 'application/json'
        }
        var options = { headers }
        var data = { html, ref, signers }
        var url = `https://us-central1-smart-kx.cloudfunctions.net/api/contract/submit`
        return Http.post({ url, data, options })
    }

    static values(c, start, end, inclusive) {
        return new Promise(async (resolve, reject) => {
            try {
                if (typeof end != 'undefined' && typeof inclusive != 'boolean')
                    throw new Error(`when using an end date, must specify inclusive true / false!`)
                var { address, json, network } = c
                console.log(address, json, network)
                var contract = await blockchain.contract(json, network, address)
                console.log({ contract })
                throw new Error(`Stop!!!!!!!!!!!!!!!!`)
                console.log(contract.address)
                var accounts = await contract.methods.getAccounts() 
                var results
                switch(typeof end) {
                    case 'undefined':
                        results = await Promise.all(accounts.map(a => contract.methods.getValue(a, start)))
                        break
                    default:
                        results = await Promise.all(accounts.map(a => contract.methods.getValues(a, start, end, inclusive)))
                }
                var values = results
                    .map((r, i) => {
                        r.account = accounts[i]
                        return r
                    })
                resolve(values)
            } catch(e) {
                reject(e)
            }
        })
    }

    /*
    static events(c, event) {
        return new Promise(async (resolve, reject) => {
            try {
                var { address, json } = c
                var contract = await blockchain.contract(json, address)
                var events = [...await contract.events(event)]
                    .map((event) => {
                        var { block, name } = event
                        var data
                        switch(name) {
                            case 'accountValue':
                                data = { ...event.data }
                                break
                            default:
                                throw new Error(`Unable to parse event, ${name}!`)
                        }
                        return { block, data, name }
                    })
                resolve(events)
            } catch(e) {
                reject(e)
            }
        })
    }
    */

    static hash(contract, account, schedule) {
        var append = typeof schedule == 'object' ? schedule.name : (typeof account == 'object' ? account.number : contract.pdf)
        if (!append.length)
            throw new Error(`Invalid arguments!`)
        var hash = sha256(`${contract._id}-${append}`)
        return `0x${hash}`
    }

    static linked({ parentId }) {
        return new Promise(async (resolve, reject) => {
            try {
                var [linked] = firebase.docs(
                    await firebase.db
                        .collection(`contracts`)
                        .where(firebase.documentId(), `==`, parentId)
                        .get()
                )
                resolve(linked)
            } catch(e) {
                reject(e)
            }
        })
    }

    static async records(contract) {
        return firestore.data(
            await firestore
                .collection(`records`)
                .where(`contractId`, `==`, contract._id)
                .get(await firestore.token(firebase))
        )
        /*
        return new Promise(async (resolve, reject) => {
            try {
                var records = firebase.docs(
                    await firebase.db
                        .collection(`records`)
                        .where(`contractId`, `==`, _id)
                        .get()
                )
                resolve(records)
            } catch(e) {
                reject(e)
            }
        })
        */
    }

    static invoice(billing) {
        var { inclusive, manager, reference, type } = billing
        var { _id: managerId } = manager
        var format = { out: `MM/DD/YYYY`, in: `MMM DD, YYYY` }
        var start = type == 'Advance'
            ? moment(billing.valuesDate, format.in).add(1, 'days')
            : moment(billing.startDate, format.in)
        var date = moment(billing.date, format.in).format(format.out)
        return {
            date,
            reference,
            period: {
                start: start.format(format.out),
                end: moment(billing.endDate, format.in).format(format.out),
                days: billing.period.split(' ').shift(),
                inclusive,
                values: type == 'Advance' ? billing.valuesDate : ''
            },
            managerId,
            type
        }
    }

    static async invoices(contract) {
        var format = {
            in: `L`,
            out: `X`
        }
        var data = firestore.data(
            await firestore
                .collection(`invoices`)
                .where(`contractId`, `==`, contract._id)
                .get(await firestore.token(firebase))
        )
        return data
            .sort((a, b) => +moment(b, format.in).format(format.out) - moment(a, format.in).format(format.out))
    }

    static sschedule(s) {
        return [`name`, `breaks`, `rates`, `minimum`]
            .reduce((o, key) => {
                switch(key) {
                    case `breaks`:
                    case `rates`:
                        o[key] = [...s[key]]
                        break
                    default:
                        o[key] = s[key]
                }
                return o
            }, {})
    }

    static async open(contract, type) {
        var { address, network } = contract
        var tab
        var pdf = (c) => c.pdf.split(` `).join(`_`) // hack, can be removed later...
        try {
            switch(type) {
                case `pdf`:
                    if (!contract.pdf && !contract.document)
                        return alert.warning(`This contract has no document preview or signed pdf!`, 3e3)
                    if (contract.pdf) {
                        Tab.open(await firebase.storageURL(pdf(contract)))
                    } else if (contract.document) {
                        tab = Tab.open()
                        tab.document.open()
                        tab.document.write(await firebase.download(contract.document))
                        tab.document.close()
                    }
                    break
                default:
                    if (!address || !network)
                        return alert.warning(`This contract has not yet been deployed!`, 3e3)
                    Tab.open(smartkx.etherscan.contractURL(address, network))
            }
        } catch(e) {
            console.log(e)
            alert.error(e.message)
        }
    }

    static status(c) {
        return new Promise(async (resolve, reject) => {
            try {
                var { address, json, network } = c
                if (!address)
                    throw new Error(`Cannot get info for a contract which is not deployed!`)
                var contract = await blockchain.contract(json, network, address)
                var version
                try {
                    version = await contract.methods.version()
                } catch(err) {
                    version = ''
                } finally {
                    if (!version)
                        return resolve({ version })
                    /*
                    var hash = [`schedules`, `accounts`]
                        .reduce((h, key, i) => {
                            h[key] = c[key].map(o => i ? smartkx.contract.hash(c, o) : smartkx.contract.hash(c, null, o))
                            return h
                        }, {})
                    */
                    var hash = {
                        schedules: c.schedules.map(s => smartkx.contract.hash(c, null, s)),
                        accounts: c.accounts.map(a => smartkx.contract.hash(c, a))
                    }
                    var schedules = [...await contract.methods.getSchedules()]
                        .filter(h => hash.schedules.find(hash => hash == h))
                        .length
                    var accounts = [...await contract.methods.getAccounts()]
                        .filter(h => hash.accounts.find(hash => hash == h))
                        .length
                    resolve({ accounts, schedules, version })
                }
            } catch(e) {
                reject(e)
            }
        })
    }
        
}

export default Contract