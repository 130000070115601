<template>
    <div>
    </div>
</template>

<style scoped>

</style>

<script>

    export default {
		data() {
			return {
			}
		},
		created() {
        },
        destroyed() {
            console.log('landing gone')
        },
		mounted() {
        },
		methods: {
		}
	}
</script>