<template>
    <div>
        <em>Securities</em>
        <template v-if="table.data.length">
            <table class="table table-sm table-borderless">
                <thead>
                    <tr>
                        <th>
                            <span>Name</span>
                        </th>
                        <th>
                            <span>Type</span>
                        </th>
                        <th>
                            <span>Ref</span>
                        </th>
                        <th>
                            <span>Price</span>
                        </th>
                        <th>
                            <span>Quantity</span>
                        </th>
                        <th>
                            <span>Value</span>
                        </th>
                    <tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in table.data" :key="index">
                        <td>
                            <span v-text="table.display(item, 'name')"></span>
                        </td>
                        <td>
                            <span v-text="table.display(item, 'type')"></span>
                        </td>
                        <td>
                            <span v-text="table.display(item, 'ref')"></span>
                        </td>
                        <td>
                            <span v-text="table.display(item, 'price')"></span>
                        </td>
                        <td>
                            <span v-text="table.display(item, 'quantity')"></span>
                        </td>
                        <td>
                            <span v-text="table.display(item, 'value')"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <template v-if="!imported">
                <hr class="my-1 mr-5">
                <table class="mt-2 table table-sm table-borderless action">
                    <tbody>
                        <tr>
                            <td>
                                <button class="btn btn-sm btn-block btn-dark" v-on:click="table.action(`audit`)">
                                    <i class="fas fa-history"></i>
                                    <span class="label">Audit</span>
                                </button>
                            </td>
                            <td>
                                <span class="desc">View the account audit logs</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </template>
        <template v-else>
            <p class="none">None found</p>
        </template>
    </div>
</template>

<style>
    p.none {
        text-align: center;
        font-weight: bold;
        opacity: 0.4;
        margin-right: 150px;
    }
    button i {
        width: 15px;
    }
    span.label {
        padding-left: 10px;
        color: white;
    }
    span.desc {
        opacity: 0.7;
    }
    table.action td:first-child {
        width: 130px;
    }
    table.action td:nth-child(2) {
        vertical-align: inherit;
        padding-left: 20px;
    }
</style>

<script>
    import { lib } from '../../factory'
    import { numeral } from '../../npm'
    import { smartkx } from '../../npm.org'
    import { events, firebase, session } from '../../service'

    var { firestore } = smartkx

    export default {
        props: ['account', 'imported'],
        data() {
            return {
                table: ``
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                var { string } = lib
                var { account } = this
                this.table = {
                    async action(type) {
                        switch(type) {
                            case `audit`:
                                var { household } = session.get(`state`)
                                var [a] = firestore.data(
                                    await firestore
                                        .collection(`accounts`)
                                        .where(`householdId`, `==`, household._id)
                                        .where(`number`, `==`, account.number)
                                        .where(`name`, `==`, account.name)
                                        .get(await firestore.token(firebase))
                                )
                                /*
                                var [a] = firebase.docs(
                                    await firebase.db
                                        .collection(`accounts`)
                                        .where(`householdId`, `==`, household._id)
                                        .where(`number`, `==`, account.number)
                                        .where(`name`, `==`, account.name)
                                        .get()
                                )
                                */
                                events.$emit(`household-menu`, { audit: { account: a }})
                                break
                            default:
                                throw new Error(`Invalid action type, ${type}!`)
                        }
                    },
                    get data() {
                        return account.securities || []
                    },
                    display(s, key) {
                        switch(key) {
                            case `price`:
                            case `value`:
                                return numeral(s[key])
                                    .format(`$0,0.00`)
                                break
                            case `quantity`:
                                return numeral(s[key])
                                    .format(`0,0`)
                            case `type`:
                                return string.capitalise(s[key])
                            default:
                                return s[key]
                        }
                    }
                }
            }
        }
    }
</script>