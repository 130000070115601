<template>
    <div class="h-100">
        <h5 class="bg-light p-3 border-bottom">Audit Logs</h5>
        <div class="mb-2" style="height: 80vh">
            <template v-if="table.loading">
                <div class="h-100">
                    <loading-component></loading-component>
                </div>
            </template>
            <template v-else>
                <scroll-area>
                    <div class="m-3">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th class="active"></th>
                                    <th>
                                        <span>Type</span>
                                    </th>
                                    <th>
                                        <span>Document</span>
                                    </th>
                                    <th>
                                        <span>Created</span>
                                    </th>
                                    <th>
                                        <span>Updated</span>
                                    </th>
                                    <th>
                                        <i class="fas fa-redo" v-on:click="table.load()"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in table.items" :key="index">
                                    <template v-if="index % 2 == 0">
                                        <td>
                                            <i class="fas toggle" :class="table.class(index)" v-on:click="table.toggle(index)"></i>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'type')"></span>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'document')"></span>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'created')"></span>
                                        </td>
                                        <td>
                                            <span v-text="table.display(item, 'updated')"></span>
                                        </td>
                                        <td></td>
                                    </template>
                                    <template v-else-if="table.log(index)">
                                        <td></td>
                                        <td colspan="5">
                                            <component :is="table.component(table.log(index))" :log="table.log(index)"></component>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </scroll-area>
            </template>
        </div>
    </div>
</template>

<style scoped>
    td.docs img:last-child {
        margin-left: 25px;
    }
    i.fas.fa-redo {
        cursor: pointer;
    }
    i.remove {
        cursor: pointer;
    }
    i.remove:hover {
        color: red;
    }
    div.col-10 {
        text-align: right;
    }
    button {
        margin: 4px 0;
    }
    i.click {
        cursor: pointer;
    }
    img.contract {
        width: 24px;
        cursor: pointer;
        margin-top: -5px;
    }
    button {
        margin-top: -5px;
    }
    button i {
        padding: 0 5px;
    }
    i.fas.toggle {
        cursor: pointer;
    }
    th.active  {
        width: 30px;
    }
    th.name {
        width: 25%;
    }
    th.summary {
        width: 55%;
    }
    i.fas.fa-caret-down {
        margin-left: -2px;
    }
</style>

<script>
    import { loading as loadingComponent, scrollArea } from '../../component'
    import { lib, Tab } from '../../factory'
    import { Household } from '../../model'
    import { axios, moment } from '../../npm'
    import { smartkx } from '../../npm.org'
    import { alert, events, firebase, session } from '../../service'

    import accountAudit from './account'
    import clientAudit from './client'
    import invoiceAudit from './invoice'
    import contactAudit from './contact'

    var { firestore } = smartkx

    export default {
        get components() {
            return {
                accountAudit,
                clientAudit,
                invoiceAudit,
                contactAudit,
                loadingComponent,
                scrollArea
            }
        },
        data() {
            return {
                table: '',
                state: ''
            }
        },
        created() {
            var { firmAudit } = this
            this.events = {}
            this.events[`firm-audit`] = firmAudit.bind(this)
            events.$watch(this.events)
            this.init()
        },
        destroyed() {
            events.$unwatch(this.events)
        },
        computed: {
        },
        methods: {
            init() {
                var { string } = lib
                var state = session.get('state')
                var table = {
                    _active: -1,
                    _data: [], 
                    _date(d) {
                        var day = {
                            now: new Date(Date.now()).getDate(),
                            record: new Date(d).getDate() 
                        }
                        return day.now == day.record ? moment(d).format(this._timeFormat) : moment(d).format(this._dateFormat)
                    },
                    _timeFormat: `HH:mm:ss`,
                    _dateFormat: `MMM D, YYYY`,
                    class(index) {
                        var fa = `fa-caret`
                        return this._active == index ? `${fa}-down` : `${fa}-right`
                    },
                    component(log) {
                        var { type } = log 
                        switch(type) {
                            case `account`:
                            case `client`:
                            case `invoice`:
                            case `contact`:
                                return `${type}-audit`
                            default:
                                throw new Error(`Invalid log type, ${type}!`)
                        }
                    },
                    display(log, key) {
                        var { string } = lib
                        switch(key) {
                            case `created`:
                            case `updated`:
                                return this._date(log[`_${key}`])
                            case `document`:
                                return log.documentId
                            default:
                                return log[key]
                        }
                    },
                    item: ``,
                    get items() {
                        return this._data
                            .reduce((list, log) => {
                                list.push(...[log, null])
                                return list
                            }, [])
                    },
                    async load() {
                        if (this.loading)
                            return
                        this.loading = true
                        /*
                        var logss = firestore.data(
                            await firestore
                                .collection(`logs`)
                                .where(`documentId`, `==`, `tQbeMr76tBTxGht2jvLZ`)
                                .get(await firestore.token(firebase))
                        )
                        console.log({ logss })
                        */
                        var data = firestore.data(
                            await firestore
                                .collection(`logs`)
                                .where(`companyId`, `==`, state.company._id)
                                .get(await firestore.token(firebase))
                        )
                        console.log({data})
                        /*
                        var logs = firebase.docs(
                            await firebase.db
                                .collection(`logs`)
                                .where(`householdId`, `==`, state.household._id)
                                .get()
                        )
                        */
                        var logs = data
                            .sort((a, b) => new Date(b._created).getTime() - new Date(a._created).getTime())
                        this._data = [...logs]
                        this.loading = false
                        if (this.item) {
                            console.log(`item`)
                            console.log(this.item)
                            var [type] = Object.keys(this.item)
                            var data = this.item[type]
                            console.log({ data })
                            var index
                            switch(type) {
                                case `account`:
                                case `client`:
                                case `invoice`:
                                case `contact`:
                                    index = this.items
                                        .findIndex(i => i != null && i.type == type && i.documentId == data._id)
                                    if (index < 0)
                                        return alert.error(`No audit logs for ${type}, ref: ${data._id}!`)
                                    this.toggle(index)
                                    break
                                default:
                                    throw new Error(`Audit item, type: ${type} not yet implemented!`)
                            }
                        }
                    },
                    loading: false,
                    log(index) {
                        var active = index - 1
                        var log = this._active == active ? this.items[active] : ''
                        return log 
                    },
                    reset() {
                        this._active = -1
                        this.item = ``
                        return this
                    },
                    toggle(index) {
                        this._active = this._active == index ? -1 : index
                    }
                }
                table.load()
                this.state = state
                this.table = table
            },
            firmAudit(e) {
                this.table.item = e
            }
        },
        watch: {
        }
    }
</script>