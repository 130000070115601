<template>
    <div class="h-100">
        <template v-if="mock">
            <mock-settings></mock-settings>
        </template>
        <template v-else>
            <div class="h-100">
                <h5 class="bg-light p-3 border-bottom">Settings</h5>
                <div class="h-100 m-3">
                    <div class="row">
                        <div class="col-8">
                            <form>
                                <div class="form-group">
                                    <label for="exampleFormControlInput1">Email address</label>
                                    <input type="email" class="form-control" id="" placeholder="demo@smartkx.io">
                                </div>
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Billing Period</label>
                                    <select class="form-control" id="exampleFormControlSelect1">
                                        <option>Annual</option>
                                        <option selected>Quarterly</option>
                                        <option>Monthly</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Default Fee Schedule</label>
                                    <select class="form-control" id="exampleFormControlSelect1">
                                        <option>Standard Fee + $4k Min</option>
                                        <option selected>Standard Fee</option>
                                        <option>0.60% Flat</option>
                                        <option>0.40% Flat</option>
                                        <option>0.80% Flat</option>
                                        <option>1.00% Flat</option>
                                    </select>
                                    <small id="emailHelp" class="form-text text-muted">When creating a new Contract, this will be the default fee schedule.</small>
                                </div>
                            </form>
                        </div>
                        <div class="col-4">
                            <div class="card">
                                <div class="card-header">Company Logo</div>
                                <div class="card-body">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="customFile">
                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>

</style>

<script>
    import json from './data.json'
    
    import mockSettings from './mock'
    import { session } from '../../service'

    export default {
        get components() {
            return {
                mockSettings
            }
        },
        data() {        
            return {
                state: '',
                json: ''
            }
        },
        created() {
            this.init()
        },
        computed: {
            mock() {
                var { mock } = this.state
                return mock
            }
        },
        methods: {
            init() {
                this.json = json
                this.state = session.get(`state`)
            }
        }
    }
</script>