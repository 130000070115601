<template>
    <div class="h-100">
        <template v-if="billing.loading">
            <div class="h-100">
                <loading-component></loading-component>
            </div>
        </template>
        <template v-else>
            <scroll-area>
                <div class="m-3">
                    <template v-if="billing.available">
                        <p class="font-italic" v-text="billing.header"></p>
                        <div class="row">
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="bill-date">Date</label>
                                    <date-picker :obj="billing" prop="date" :options="billing.dateOptions"></date-picker>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="bill-type">Type</label>
                                    <select class="form-control form-control-sm" id="date-start" v-model="billing.type" v-on:change="billing.change('type')" :disabled="billing.state > 0 || isDemo">
                                        <option v-for="(type, index) in billing.types" :key="index">
                                            <span v-text="type"></span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="period">Reference</label>
                                    <input class="form-control form-control-sm" placeholder="Reference" v-model="billing.reference" :disabled="billing.state > 0">
                                </div>
                            </div>
                            <template v-if="billing.type=='Advance'">
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="date-values">Account Values</label>
                                        <select class="form-control form-control-sm" id="date-values" v-model="billing.valuesDate" v-on:change="billing.change('values')" :disabled="billing.state > 0">
                                            <option v-for="(date, index) in billing.valuesDates" :key="index">
                                                <span v-text="date"></span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="date-end">Period End</label>
                                        <select class="form-control form-control-sm" id="date-end" v-model="billing.endDate" v-on:change="billing.change('end')" :disabled="billing.state > 0">
                                            <option v-for="(date, index) in billing.endDates" :key="index">
                                                <span v-text="date"></span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="date-start">Period Start</label>
                                        <select class="form-control form-control-sm" id="date-start" v-model="billing.startDate" v-on:change="billing.change('start')" :disabled="billing.state > 0">
                                            <option v-for="(date, index) in billing.startDates" :key="index">
                                                <span v-text="date"></span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="date-end">Period End</label>
                                        <select class="form-control form-control-sm" id="date-end" v-model="billing.endDate" v-on:change="billing.change('end')" :disabled="billing.state > 0">
                                            <option v-for="(date, index) in billing.endDates" :key="index">
                                                <span v-text="date"></span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </template>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="date-inclusive">Inclusive</label>
                                    <select class="form-control form-control-sm" id="date-inclusive" v-model="billing.inclusive" v-on:change="billing.change('inclusive')" :disabled="billing.state > 0">
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                </div>
                            </div>
                            <!--
                            <div class="col-1">
                                <div class="form-group">
                                    <label for="period">Period</label>
                                    <input class="form-control form-control-sm" :value="billing.period" readonly>
                                </div>
                            </div>
                            -->
                            <!--
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="period">Fees</label>
                                    <input class="form-control form-control-sm" :value="currency(billing.feeTotal)" readonly>
                                </div>
                            </div>
                            -->
                            <!--
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="date-end">Recurring</label>
                                    <select class="form-control form-control-sm" id="recurr" v-model="select.recurr" v-on:change="select.change('recurr')">
                                        <option v-for="(option, index) in select.recurring" :key="index">
                                            <span v-text="option"></span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                            -->
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <p class="font-italic">Current Contract</p>
                                <div id="accordion">
                                    <div v-for="(contract, index) in billing.contracts" :key="index" class="card">
                                        <div class="card-header" :id="contract._id">
                                            <table class="contract-account">
                                                <tr>
                                                    <td>
                                                        <h5 class="mb-0">
                                                            <button class="btn btn-link" data-toggle="collapse" :data-target="`#collapse-${contract._id}`" aria-expanded="true" aria-controls="collapseOne">
                                                                <span v-text="contract._id"></span>
                                                            </button>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <span v-text="billing.last(contract)"></span>
                                                    </td>
                                                    <td class="preview">
                                                        <template v-if="contract.loading">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </template>
                                                        <template v-else-if="contract.bill">
                                                            <div class="btn-group btn-group-sm" role="group">
                                                                <button :disabled="billing.disabled(contract, `preview`)" type="button" class="btn btn-primary" v-on:click="billing.preview(contract)">Preview</button>
                                                                <button :disabled="billing.disabled(contract, `save`)" type="button" class="btn btn-success px-3" v-on:click="billing.save(contract)">Save</button>
                                                            </div>
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <span>Include: </span>
                                                        <input type="checkbox" v-model="contract.bill" v-on:change="billing.change('bill', index)" :disabled="billing.state > 0">
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div :id="`collapse-${contract._id}`" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                                <table class="table table-sm table-borderless">
                                                    <tr>
                                                        <th v-for="(key, index) in accounts.keys" :key="index">
                                                            <span v-text="key.label"></span>
                                                        </th>
                                                    </tr>
                                                    <tr v-for="(account, i1) in contract.accounts" :key="i1">
                                                        <td v-for="(key, i2) in accounts.keys" :key="i2">
                                                            <span v-text="display(account, key)"></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td :colspan="accounts.keys.length">
                                                            <hr>
                                                        </td>
                                                    </tr>
                                                    <tr class="total">
                                                        <td></td>
                                                        <td>
                                                            <span>Accounts Total</span>
                                                        </td>
                                                        <td>
                                                            <span v-text="total(contract, 'accountsTotal')"></span>
                                                        </td>
                                                        <td>
                                                            <span>Fees Total</span>
                                                        </td>
                                                        <td>
                                                            <span v-text="total(contract, 'feesTotal')"></span>
                                                        </td>
                                                    </tr>
                                                    <template v-if="billing.owing(contract)">
                                                        <tr class="total">
                                                            <td colspan="3"></td>
                                                            <td>
                                                                <span>Fees Minimum</span>
                                                            </td>
                                                            <td>
                                                                <span v-text="total(contract, 'feesMinimum')"></span>
                                                            </td>
                                                        </tr>
                                                        <tr class="total">
                                                            <td colspan="3"></td>
                                                            <td>
                                                                <span>Fees Shortfall</span>
                                                            </td>
                                                            <td>
                                                                <span v-text="total(contract, 'feesShortfall')"></span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="7" class="fees-owing-note">
                                                                <span>* - fee includes a percentage of the fees shortfall</span>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="bill-message m-5">
                            <p v-for="(line, index) in billing.message" :key="index" v-text="line"></p>
                        </div>
                    </template>
                </div>
            </scroll-area>
        </template>
    </div>
</template>

<style scoped>
    div.bill-message {
        font-weight: 600;
        opacity: 0.5;
    }
    td.preview i {
        opacity: 0.5;
    }
    td.fees-owing-note {
        text-align: right;
    }
    table tr.total td:nth-child(even) {
        text-align: right;
    }
    table tr.total td:nth-child(even) span {
        margin-right: 20px;
        font-weight: bold;
    }
    table.contract-account {
        width: 100%;
    }
    table.contract-account td:first-child {
        width: 275px;
    }
    table.contract-account td:last-child {
        width: 100px;
        text-align: right;
    }
    table.contract-account td:last-child input {
        vertical-align: inherit;
        margin-left: 10px;
    }
    table.contract-account td.preview {
        text-align: right;
    }
    div.modal-body > div {
        margin: 5px 10px;
    }
    nav a.dropdown-item {
        cursor: pointer;
    }
    div.modal-content {
        min-height: 85vh;
    }
    div.modal-header {
        margin: 0 3px;
    }
    div.modal-footer table {
        width: 100%;
    }
    div.modal-footer table td:not(:nth-child(2)) {
        width: 50px;
    }
    div.modal-footer table td:nth-child(2) {
        text-align: center;
    }
    div.view {
        margin: 10px;
    }
    div.reveal {
        position: absolute;
        top: 50%;
        left: -10px;
    }
    div.modal-footer p {
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        opacity: 0.6;
    }
</style>

<script>
    import { datePicker, loading as loadingComponent, scrollArea } from '../../component'
    import { Api, Contract, Format, Interpolate, lib, Style, Sys, Tab } from '../../factory'
    import { Household } from '../../model'
    import { moment, numeral } from '../../npm'
    import { alert, events, firebase, session } from '../../service'

    var { $ } = window

    export default {
        get components() {
            return {
                datePicker,
                loadingComponent,
                scrollArea
            }
        },
        props: ['modal'],
        data() {
            return {
                accounts: '',
                accountKeys: '',
                billing: '',
                loading: '',
                state: '',
                status: ''
            }
        },
        created() {
            this.init()
        },
        mounted() {
        },
        computed: {
            isDemo() {
                return this.state.user.demo
            }
        },
        methods: {
            total(contract, key) {
                console.log(`getting total`, key)
                var format = `$0,0.00`
                var total
                switch(key) {
                    case 'accountsTotal':
                        console.log(`s`, contract.schedules)
                        total = contract.schedules
                            .reduce((t, s) => t += s.total.splits, 0)
                        return numeral(total)
                            //.divide(Contract.scale())
                            .format(format)
                    case 'feesTotal':
                        total = contract.schedules
                            .reduce((t, s) =>  t += s.total.fees, 0)
                        return numeral(total)
                            //.divide(Contract.scale('fees'))
                            .format(format)
                    case 'feesMinimum':
                    case 'feesShortfall':
                        total = contract.schedules
                            .reduce((t, s) => t += s[key], 0)
                        return numeral(total)
                            .divide(Contract.scale('fees'))
                            .format(format)
                    default:
                        throw new Error(`Invalid total key, ${key}!`)
                }
            },
            display(account, key) {
                var { string } = lib
                var v = account[key.value]
                switch(key.value) {
                    case 'owing':
                        return account[key.value] ? `*` : ''
                    case 'value':
                    case 'fee':
                        return numeral(v)
                            //.divide(Contract.scale(key.value))
                            .format(`$0,0.00`)
                    case 'name':
                        return string.truncate(v, 35)
                    default:
                        return v
                }
            },
            billedTo(contract, account) {
                var { accounts } = contract
                var { billing: index } = account
                return index == -1 ? `Self` : accounts[index].number 
            },
            async calculate() {
                var state = session.get(`state`)
                var { billing } = this
                var { startDate, endDate, householdContracts: contracts, period, type, valuesDate } = billing
                //var [start, end] = (type == 'Advance' ? [valuesDate, endDate] : [startDate, endDate])
                  //  .map(d => Format.date(d))
                var fees
                var events, values, account, hash, schedule, accounts, files, calc
                try {
                    var c = 0
                    for (var contract of contracts) {
                        c++
                        if (!contract.bill) {
                            contract.accounts
                                .forEach((a) => {
                                    a.fee = 0
                                    a.value = 0
                                })
                            contract.schedules
                                .forEach((s) => {
                                    s.fees.length = 0
                                    s.splits.length = 0
                                    s.total = { fees: 0, splits: 0 }
                                    s.feesMinimum = 0
                                    s.feesShortfall = 0
                                })
                            contract.preview.pending = false
                            contract.preview.done = false
                            contract.loading = false
                        } else {
                            contract.loading = true
                            console.log(`Calc fees?`)
                            console.log({ contract })
                            console.log({ billing })
                            var [sd, end, values] = [startDate, endDate, valuesDate]
                                .map(d => moment(d, `MMM DD, YYYY`).format(`YYYYMMDD`))
                            var inclusive = billing.inclusive.toLowerCase() == `yes`
                            var start = billing.type.toLowerCase() == `advance`
                                ? moment(values, `YYYYMMDD`).add(1, `days`).format(`YYYYMMDD`)
                                : sd
                            var period = { start, end, inclusive, values }
                            var { _id: contractId } = contract
                            var { _id: managerId } = state.user
                            var { data } = await Api.post(`contract/fees`, { contractId, managerId, period })
                            for (var s of contract.schedules) {
                                fees = data[s.name]
                                console.log({ fees })
                                //continue
                                accounts = contract.accounts
                                    .filter(a => a.scheduleName == s.name)
                                console.log({ accounts })

//                                schedule = await Contract.schedule(contract, s)
  //                              calc = await Contract.calc(schedule)
    //                            accounts = contract.accounts
      //                              .filter(a => a.scheduleName == s.name)
        //                        fees = await Contract.fees(accounts, schedule, period, calc)
                                var [accountsTotal, feesTotal, feesMinimum, feesShortfall] = fees.totals
                                s.splits = [...fees.accountsSplits]
                                s.fees = [...fees.feesSplits]
                                s.total = { fees: feesTotal, splits: accountsTotal }
                                s.feesMinimum = feesMinimum
                                s.feesShortfall = feesShortfall
                                accounts
                                    .forEach((a, i) => {
                                        a.value = fees.accountsValues[i]
                                        a.owing = fees.feesOwing[i]
                                        a.fee = fees.feesAccounts[i] + a.owing
                                    })
                            }
                            /*
                            switch(billing.type) {
                                case 'Arrears':
                                    values = await Contract.values(contract, start, end, billing.inclusive == 'Yes')
                                    contract.accounts
                                        .forEach((account) => {
                                            var { average } = values
                                                .find(v => v.account == (account.hash || Contract.hash(contract, account)))
                                            account.value = +(average / Contract.scale()).toFixed()
                                        })
                                    break
                                case 'Advance':
                                    values = await Contract.values(contract, start)
                                    contract.accounts
                                        .forEach((account) => {
                                            var { value } = values
                                                .find(v => v.account == (account.hash ? account.hash : Contract.hash(contract, account)))
                                            account.value = value
                                        })
                                    break
                                default:
                                    throw new Error(`Invalid billing type, ${billing.type}!`)
                            }
                            for (var s of contract.schedules) {
                                schedule = await Contract.schedule(contract, s)
                                calc = await Contract.calc(schedule)
                                accounts = contract.accounts
                                    .filter(a => a.scheduleName == s.name)
                                fees = await Contract.fees(accounts, schedule, period, calc)
                                var [accountsTotal, feesTotal, feesMinimum, feesShortfall] = fees.totals
                                s.splits = [...fees.accountsSplits]
                                s.fees = [...fees.feesSplits]
                                s.total = { fees: feesTotal, splits: accountsTotal }
                                s.feesMinimum = feesMinimum
                                s.feesShortfall = feesShortfall
                                accounts
                                    .forEach((a, i) => {
                                        a.owing = fees.feesOwing[i]
                                        a.fee = fees.feesAccounts[i] + a.owing
                                    })
                            }
                            */
                           contract.loading = false
                        }
                    }
                } catch(e) {
                    console.log(e)
                }
            },
            currency(v) {
                return numeral(v)
                    .format(`$0,0.00`)
            },
            init() {
                var { string } = lib
                this.status = { text: '', color: '' }
                this.accounts = {
                    _keys: { number: '', name: '', value: 'Balance', scheduleName: 'Schedule', fee: '', owing: '.', billedTo: 'Billed To' },
                    get keys() {
                        var { _keys } = this
                        return Object.keys(_keys)
                            .map((value) => {
                                var label = _keys[value] ? (_keys[value] == '.' ? '' : _keys[value]) : string.capitalise(value)
                                return { label, value }
                            })
                    }
                }
                var state = session.get('state')
                var { user: manager, company: firm } = state
                this.state = state
                var dateFormat = {
                    in: `L`,
                    out: `MMM DD, YYYY`
                }
                var billing = {
                    _minDate: ``,
                    dateOptions: {
                        format: dateFormat.out,
                        minDate: ``
                    },
                    click(type) {
                        switch(type) {
                            case `date`:
                                var el = $(`#bill-date`)
                                el.datepicker({ orientation: `bottom` })
                                break
                            default:
                                throw new Error(`Invalid click type, ${type}!`)
                        }
                    },
                    message: '',
                    get available() {
                        var { householdContracts: contracts } = this
                        var master = contracts
                            .find(c => c.type == `master`)
                        if (!master) {
                            var { households } = session.get('data') 
                            var message = contracts
                                .filter(c => c.type == `linked`)
                                .map((c) => {
                                    var { _id, linked, parentId } = c
                                    var h = households
                                        .find(h => h._id == linked.householdId)
                                    return `Contract: ${_id} is linked to Household: ${h.name}`

                                })
                            message.push(`The above contracts must be billed from the linked household(s).`)
                            this.message = [...message]
                        }
                        return  master ? true : false
                    },
                    loading: false,
                    inclusive: 'Yes',
                    type: '',
                    get types() {
                        return [`Arrears`, `Advance`]
                    },
                    manager,
                    firm,
                    state: 0,
                    _error: (e) => {
                        this.message(e, true)
                    },
                    _status: (bill, id) => {
                        if (!bill)
                            return this.message()
                        if (bill.state == 2)
                            return this.message(`Done, ref: ${id}!`)
                        var { startDate: start, endDate: end, feeTotal, reference: ref } = bill
                        var fees = this.currency(feeTotal)
                        this.message(`Saving bill, ref: ${ref}, period: ${start} - ${end}, fees: ${fees}...`)
                    },
                    disabled(c, type) {
                        switch(type) {
                            case `preview`:
                                return c.preview.pending
                            case `save`:
                                return !c.preview.done || c.saved
                            default:
                                throw new Error(`Invalid disabled type, ${type}!`)
                        }
                        var { feeTotal, reference } = this
                        return feeTotal > 0 && reference ? false : true
                    },
                    get header() {
                        var { date, reference, type, valuesDate, startDate, endDate } = this
                        var period
                        switch(type) {
                            case 'Advance':
                                var start = moment(valuesDate, dateFormat.out).add(1, 'day').format(dateFormat.out)
                                period = `${start} to ${endDate}`
                                break
                            default:
                                period = `${startDate} to ${endDate}`
                        }
                        period += ` [${this.period}]`
                        var header = [`Date: ${date}`]
                        if (endDate)
                            header.push(`Period: ${period}`)
                        if (endDate && reference)
                            header.push(`Reference: ${reference}`)
                        return header.join(', ')
                    },
                    reference: '',
                    householdContracts: [],
                    change: (type, index) => {
                        var { billing } = this
                        if (type == 'date') {
                            console.log('billing date changed?')
                            var ref = this.$refs['billDate'].value
                            console.log(ref)
                            //var [el] = $(`#bill-date`)
                            //var date = el.value
                            //datepicker('getUTCDate')
                            //.getDate()
                            //console.log(date)
                            //console.log(el)
                            return
                        }
                        if (type == 'type') {
                            switch(billing.type) {
                                case 'Arrears':
                                    billing.startDate = billing.startDates[0]
                                    break
                                default:
                                    billing.startDate = billing.startDates[billing.startDates.length - 1]
                            }
                            billing.endDate = billing.endDates[billing.endDates.length - 1]
                        }
                        if (type == 'bill') {
                            var contract = billing.householdContracts[index]
                            if (!contract.bill) {
                                contract.preview.pending = false
                                contract.preview.done = false
                                contract.saved = false
                            }
                        }
                        this.calculate()
                    },
                    contract({ _id }) {
                        return `Id: ${_id}`
                    },
                    last(contract) {
                        var { dateOptions } = this
                        var [invoice] = contract.invoices
                        var last = invoice ? moment(invoice.date, dateFormat.in).format(dateFormat.out) : `Never`
                        if (invoice) {
                            var { period } = invoice
                            var end = moment(period.end, dateFormat.in)
                            if (period.inclusive.toLowerCase() == 'yes')
                                end.add(1, 'day')
                            dateOptions.minDate = moment(end, dateFormat.in).format(dateFormat.out)
                        } else {
                            dateOptions.minDate = ``
                        }
                        return `Last Billed: ${last}`
                    },
                    document: '',
                    get contracts() {
                        var [start, end] = [this.startDate, this.endDate]
                            .map(d => Format.date(d))
                        var c = this.householdContracts
                            .filter((c) => {
                                var records = c.records
                                    .filter(r => r.date >= start && r.date <= end)
                                return records.length > 0
                            })
                        return c
                    },
                    date: moment().format(dateFormat.out),
                    dates: [],
                    startDate: '',
                    get valuesDates() {
                        return this.dates
                    },
                    valuesDate: '',
                    get startDates() {
                        return this.dates
                    },
                    endDate: '',
                    get endDates() {
                        switch(this.type) {
                            case 'Arrears':
                                var index = this.dates
                                    .findIndex(d => d == this.startDate)
                                return this.dates.slice(index)
                            default:
                                var dates = [this.startDate]
                                var date
                                while(dates.length < 180) {
                                    date = dates[dates.length - 1]
                                    dates.push(moment(date, dateFormat.out).add(1, 'day').format(dateFormat.out))
                                }
                                return dates
                        }
                    },
                    get period() {
                        var start = this.type == 'Advance'
                            ? moment(this.valuesDate, dateFormat.out).add(1, 'day')
                            : moment(this.endDate, dateFormat.out)
                        var days = +(moment(this.endDate, dateFormat.out).diff(start) / 86400e3).toFixed()
                        if (this.inclusive.toLowerCase() == 'yes')
                            days++
                        var suffix = days == 1 ? `day` : `days`
                        return `${days} ${suffix}`
                    },
                    async save(c) {
                        if (c.saved)
                            return console.log(`Already clicked save...`)
                        //c.saved = true
                        var { document: preview } = this
                        var { _id: contractId, householdId } = c
                        var invoiceData = Contract.invoice(this)
                        var accounts = c.accounts
                            .map((a) => {
                                var { number, fee, billedTo, value } = a
                                return { number, fee, billedTo, value }
                            })
                        var schedules = c.schedules
                            .map((s) => {
                                var { name, feesMinimum, feesShortfall } = s
                                var fees = s.fees
                                    .reduce((f, fee) => f += fee, 0)
                                return { name, fees, feesMinimum, feesShortfall }
                            })
                        var invoice = { contractId, householdId, accounts, schedules, ...invoiceData, preview }
                        console.log({ invoice })
                        try {
                            var resp = await Api.post(`invoice/save`, { invoice })
                            var { message } = resp.data
                            alert.message(message, 3e3)
                            await Sys.wait(1.5e3)
                            events.$emit(`newBill`, { saved: true })
                        } catch(e) {
                            console.log(e)
                            alert.error(`${e.status}: ${e.message}`)
                        }
                        /*
                        var [record] = firebase.docs(
                            await firebase.db
                                .collection(`invoices`)
                                .where(`contractId`, `==`, invoice.contractId)
                                .where(`date`, `==`, invoice.date)
                                .get()
                        )
                        if (record)
                            return alert.error(`Sorry, an invoice has already been created!!`)
                        var { id } = await firebase.db
                            .collection('invoices')
                            .add(invoice)
                        var upload = [
                            `html/invoice-${id}.html`,
                            invoiceDocument,
                            { type: `text/html` }
                        ]
                        await firebase.upload(...upload)
                        await firebase.db
                            .collection(`invoices`)
                            .doc(id)
                            .update({ document: upload[0] })
                        await Sys.wait(1500)
                        c.invoices = [...await Contract.invoices(c)]
                        alert.message(`Invoice saved, id: ${id}!`, 3000)
                        */
                    },
                    async preview(contract) {
                        if (contract.preview.pending)
                            return
                        contract.preview.pending = true
                        var invoice = Contract.invoice(this)
                        //var state = session.get(`state`)
  //                      var { functions } = config.firebase
//                        var url = `${config.api.url}/api/html/invoice`
                        var data = { contract, invoice }
                        var resp = await Api.post(`html/invoice`, data)
//                        var resp = await skivy71.http.post(url, data)
                        contract.preview.pending = false
                        var tab = Tab.open()
                        if (!tab || !tab.document)
                            throw new Error(`Failed to create new window!`)
                        this.document = resp.data.render 
                        tab.document.open()
                        tab.document.write(this.document)
                        tab.document.close()
                        contract.preview.done = true
                    },
                    owing(contract) {
                        return contract.accounts
                            .find(a => a.owing)
                    }
                }
                billing.type = firm.invoice.type ? string.capitalise(firm.invoice.type) : billing.types[0]
                if (state.user.demo)
                    billing.reference = `Demo Invoice`
                this.billing = billing
                this.load()
            },
            async load() {
                try {
                    var { billing, state } = this
                    if (billing.loading)
                        return console.log('already loading...')
                    billing.loading = true
                    var { household, company: firm } = state
                    var contracts = [...await Household.contracts(household)]
                    var query
                    for (var contract of contracts) {
                        var { _id: contractId } = contract
                        if (contract.type == `linked`)
                            contract.linked = await Contract.linked(contract)
                        contract.records = [...await Contract.records(contract)]
                            .sort((a, b) => +a.date - b.date)
                        contract.invoices = [...await Contract.invoices(contract)]
                        contract.bill = false
                        contract.saved = false
                        contract.preview = { pending: false, done: false }
                        contract.accounts
                            .forEach((a) => {
                                a.value = 0
                                a.fee = 0
                                a.billedTo = a.billing < 0 ? `Self` : contract.accounts[a.billing].number
                                a.owing = 0
                            })
                        contract.schedules
                            .forEach((s) => {
                                s.fees = [],
                                s.splits = [],
                                s.total = { fees: 0, splits: 0 }
                                s.feesMinimum = 0
                                s.feesShortfall = 0
                            })
                        contract.loading = false
                    }
                    var dates = contracts
                        .reduce((arr, c) => {
                            c.records.forEach((r) => {
                                if (arr.find(d => d == r.date))
                                    return
                                arr.push(+r.date)
                            })
                            return arr
                        }, [])
                        .sort((a, b) => a - b)
                        .map(d => Format.date(d))
                    billing.dates = [...dates]
                    billing.startDate = dates[0]
                    billing.valuesDate = dates[dates.length - 1]
                    var format = `MMM DD, YYYY`
                    if (!billing.endDate) {
                        if (firm.invoice.type == 'advance') {
                            var add
                            switch(firm.invoice.period) {
                                case 'quarter':
                                    add = [3, 'months']
                                    break
                                default:
                                    throw new Error(`Invalid firm invoice period, ${firm.invoice.period}!`)
                            }
                            billing.endDate = moment(billing.valuesDate, format).add(...add).format(format)
                        } else {
                            billing.endDate = dates[dates.length - 1]
                        }
                    } else {
                        console.log('got a billing end date?')
                    }
                    billing.householdContracts = [...contracts]
                    billing.loading = false
                    this.calculate()
                } catch(e) {
                    console.log(e)
                }
            }
        },
        watch: {
            'billing.loading': {
                handler() {
                    if (this.billing.loading)
                        return
                    var el = document.getElementsByClassName(`scrollbar`)[0]
                    if (!el || this.scrollbar)
                        return
                    this.scrollbar = new Simplebar(el)
                }
            },
            'state.household': {
                handler() {
                    this.billing.endDate = ''
                    this.load()
                }
            }
        }
    }
</script>