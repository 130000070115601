import moment from 'moment'

class Interpolate {

    static dates(list, format) {
        var difference = (a, b) => moment(b, format).diff(moment(a, format))
        var carry = (d) => +moment(d, format).add(1, 'days').format(format)
        return list
            .reduce((arr, item, i) => {
                if (arr.length) {
                    var n = 0
                    while(difference(arr[arr.length - 1], item) > 86400e3) {
                        arr.push(carry(arr[arr.length - 1]))
                        n++
                        if (n > 3)
                            throw 'oops'
                    }
                }
                arr.push(item)
                return arr
            }, [])
    }

    static values(list, format) {
        var difference = (a, b) => moment(b.date, format).diff(moment(a.date, format))
        /*
            var last = moment(a.date, format)
            var next = moment(b.date, format)
            var diff = next.diff(last)
            console.log({ diff })
            return diff
        }
        */
        var carry = (item) => {
            //console.log('carry...')
            //console.log(item.date)
            return Object.keys(item)
                .reduce((o, key) => {
                    o[key] = key == 'date' ? moment(item.date, format).add(1, 'days').format(format) : item[key]
                    o.interpolate = true
                    return o 
                }, {})
        }
        return list
            .reverse()
            .reduce((arr, item, i) => {
                /*
                if (!i) {
                    console.log('first...')
                    console.log(item)
                }
                */
                if (arr.length) {
                    var n = 0
                    while(difference(arr[arr.length - 1], item) > 86400e3) {
                        arr.push(carry(arr[arr.length - 1]))
                        n++
                        if (n > 3)
                            throw 'oops to many interpolations?'
                    }
                }
                //console.log(diff)
                arr.push(item)
                return arr
            }, [])
            .reverse()
    }

}

export default Interpolate