<template>
    <scroll-area>
        <div class="m-3">
            <div class="row">
                <div class="col">
                    <table class="table table-sm borderless">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Role</th>
                                <th>Email</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(signee, index) of contract.signees" :key="index">
                                <td>
                                    <span v-text="signee.name"></span>
                                </td>
                                <td>
                                    <span v-text="signee.role"></span>
                                </td>
                                <td>
                                    <span v-text="signee.email"></span>
                                </td>
                                <td class="order">
                                    <template v-if="index">
                                        <i class="fas fa-arrow-up" v-on:click="up(signee, index)"></i>
                                    </template>
                                    <template v-if="index < contract.signees.length - 1">
                                        <i class="fas fa-arrow-down" v-on:click="down(signee, index)"></i>
                                    </template>
                                </td>
                                <td>
                                    <i class="far fa-trash-alt remove" v-on:click="remove(signee, index)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                    <template v-if="select.ready">
                        <table class="contacts">
                            <tr>
                                <td>
                                    <select class="form-control form-control-sm" v-model="select.type">
                                        <option v-for="(type, index) in select.types" :key="index">
                                            <span v-text="type"></span>
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-control form-control-sm" v-model="select.contact">
                                        <option v-for="(contact, index) in select.contacts" :key="index">
                                            <span v-text="contact.name"></span>
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-outline-success" v-on:click="add" :disabled="disabled">
                                        <span>Add</span>
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <template v-if="isDemo">
                            <div class="demo-disclaimer ml-3 mt-3">
                                <p>
                                    <em>For demo purposes, we have deactivated the client signature requirement for the contract.</em>
                                </p>
                                <p>
                                    <em>In realtime use, the user would add the client to the contract exactly as the advisor is added.</em>
                                </p>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </scroll-area>
</template>

<style scoped>
    div.demo-disclaimer p {
        margin-bottom: 5px;
        font-size: 14px;
    }
    table.contacts td {
        padding: 0 10px;
    }
    table td.order {
        width: 100px;
    }
    table td.order i:first-child {
        margin-right: 10px;
    }
    i.remove, i.fa-arrow-up, i.fa-arrow-down {
        cursor: pointer;
    }
    i.remove:hover {
        color: red;
    }
    div.schedule table {
        margin: 10px 0 0 -5px;
    }
    hr {
        margin-top: 0;
        opacity: 0.6;
    }
    p.none {
        text-align: center;
    }
    p.note {
        margin: 10px 0 0 0;
        font-style: italic;
    }
</style>

<script>
    import { scrollArea } from '../../../component'
    import { Contract, Format, lib, Sys, Tab } from '../../../factory'
    import model from '@smartkx/firebase-model'
    import { axios, moment, numeral } from '../../../npm'
    import { smartkx } from '../../../npm.org'
    import { alert, events, firebase, session } from '../../../service'

    var { firestore } = smartkx

    export default {
        get components() {
            return {
                scrollArea
            }
        },
        data() {
            return {
                select: '',
                state: '',
            }
        },
        created() {
            this.init()
        },
        mounted() {
        },
        computed: {
            contract() {
                var { state } = this
                var { company, contracts, household } = state
                var { _id: householdId } = household
                var contract = state.contracts
                    .find(c => c.householdId == householdId)
                if (!contract)
                    throw new Error(`Unable to find contract in state, should already be there!`)
                return contract
            },
            disabled() {
                var { contract, select, state } = this
                return (contract.signees.find(s => s.label == select.contact) ? true : false) || state.user.demo
            },
            isDemo() {
                return this.state.user.demo
            }
        },
        methods: {
            add() {
                var { contract, select } = this
                var contact = select.contacts.find(c => c.name == select.contact)
                contract.signees.push(contact)
            },
            down(o, i) {
                var { contract, select } = this
                if (i >= contract.signees.length - 1)
                    return
                var [signee] = contract.signees
                    .splice(i, 1)
                contract.signees
                    .splice(i + 1, 0, signee)
            },
            init() {
                this.state = session.get(`state`)
                var { contract } = this
                if (!contract.hasOwnProperty(`signees`))
                    this.$set(contract, `signees`, [])
                this.select = {
                    _contacts: [],
                    contact: ``,
                    set contacts(v) {
                        this._contacts = [...v]
                    },
                    get contacts() {
                        return this.type ? this._contacts.filter(c => c.role == this.type) : [] 
                    },
                    type: ``,
                    get types() {
                        var roles = [`client`, `advisor`]
                        return this._contacts
                            .reduce((l, c) => {
                                if (!l.includes(c.role) && roles.includes(c.role.toLowerCase()))
                                    l.push(c.role)
                                return l
                            }, [])
                    },
                    ready: false
                }
                this.load()
            },
            async load() {
                var { string } = lib
                var { select, state } = this
                var { company } = state
                function contact(c) {
                    var { firstname, lastname, role: _role, email } = c
                    return {
                        firstname,
                        lastname,
                        _role,
                        get role() {
                            return string.capitalise(this._role)
                        },
                        email,
                        get name() {
                            var { firstname: f, lastname: l } = this
                            return `${f} ${l}`
                        }
                    }
                }
                var token = await firestore.token(firebase)
                var contacts = firestore.data(
                    await firestore
                        .collection(`contacts`)
                        .where(`companyId`, `==`, company._id)
                        .get(token)
                )
                .map(c => contact(c))

                /*
                var contacts = firebase.docs(
                    await firebase.db
                        .collection(`contacts`)
                        .where(`companyId`, `==`, company._id)
                        .get()
                )
                */
                /*
                var dummyCompanyId = new Array(20).fill(`0`).join(``)
                var dummyContacts = firestore.data(
                    await firestore
                        .collection(`contacts`)
                        .where(`companyId`, `==`, dummyCompanyId)
                        .get(token)
                )
                .map(c => contact(c))
                var wealthCompanyId = `kakTwS2rqe5jUH1KKVHY`
                var skxContacts = firestore.data(
                    await firestore
                        .collection(`contacts`)
                        .where(`companyId`, `==`, wealthCompanyId)
                        .get(token)
                )
                .map(c => contact(c))
                */
                select.contacts = [...contacts]
                //, ...dummyContacts, ...skxContacts]
//                select.contact = contacts[0].name
                select.type = select.types[0]
                select.ready = true
                if (!state.user.demo || this.contract.signees.find(c => c.name == state.user.name))
                    return
                var c = contacts
                    .find(c => c.name == state.user.name)
                this.contract.signees.push(c)
                console.log(this.contract)
            },
            remove(o, i) {
                var { contract } = this
                contract.signees.splice(i, 1)
            },
            up(o, i) {
                var { contract, select } = this
                if (!i)
                    return
                var [signee] = contract.signees
                    .splice(i, 1)
                contract.signees
                    .splice(i - 1, 0, signee)
            }
        },
        watch: {
            'select.type': {
                handler() {
                    this.$nextTick(() => {
                        if (this.select.contacts.length)
                            this.select.contact = this.select.contacts[0].name
                    })
                }
            }
        }
    }
</script>