<template>
    <div class="h-100 d-flex justify-content-center align-items-center">
        <i :class="loading.class" :style="loading.style"></i>
    </div>
</template>

<script>
    export default {
        props: {
            loading: {
                type: Object,
                default() {
                    return {
                        class: `fa fa-sync fa-spin`,
                        style: `font-size: 100px; opacity: 0.3`
                    }
                }
            }
        },
        data() {
            return {
            }
        }
    }
</script>

<style>

</style>