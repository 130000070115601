<template>
    <div>
        <div v-for="(item, index) in radio.items" :key="index">
            <span v-text="item"></span>
            <input type="radio" :checked="radio.index == index" v-on:click="radio.select(index)">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            radio: ''
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.radio = {
                index: 1,
                get items() {
                    return [1, 2, 3]
                        .map(n => `Option ${n}`)
                },
                select(i) {
                    this.index = i
                }
            }
        }
    }
}
</script>

<style>

</style>